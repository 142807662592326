export default {
    setUserList(state, data) {
        state.userList = data;
    },
    setOneUser(state, data) {
        state.userOne = data;
    },
    setUserValidation(state, data) {
        state.userValidation = data;
    }
}