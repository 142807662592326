import { client } from "../../helpers/axiosHelper";
import { Message, MessageBox } from "element-ui";
export default {
  async getNotesList(context, payload) {
    var res;
    if (payload) {
      res = await client.post("/Note/List", payload);
    } else {
      res = await client.post("/Note/List", context.state.noteFilter);
    }
    // Filter Store'da setlenip sürekli buradan gönderilicek payload almıyoruz.
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setNotesList", res.data.Data.Items);
    }
  },
 
  
  async addNotes(context, payload) {
    var res = await client.post("/Note/Add", payload);
    context.commit("setNotesValidation", res.data.ValidationErrors);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      Message.success(res.data.Message);
      context.dispatch("getNotesList");
    }
    return res.data;
  },
  async deleteOneNote(context, payload) {
    MessageBox.confirm("Are you sure for delete this note?" , {
      confirmButtonText: "Delete",
      confirmButtonClass: "danger-btn-confirm",
      cancelButtonText: "Back",
      type: "warning",
      title: "Warning"
    })
      .then(async () => {
        var res = await client.post("/Note/Delete", {Id:payload});
        if (res.data.HasError) {
          Message.error(res.data.Message);
        } else {
          Message.success(res.data.Message);
          await context.dispatch("getNotesList");
        }
      })
      .catch(() => {});
  },
};
