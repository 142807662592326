import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  productsList: [],
  product: {},
  filter: {
    Page: 1,
    PageSize: 200,
    SearchTerm: null,
    CustomerId: null,
    Title: null,
    SKU: null,
    SortBy: null,
    SortDirection: 1,
    isDesignConfirmed: true,
  },
  productsTotal: {
    PageCount: null,
    TotalCount: null,
  },
  productForm: {
    ID: null,
    ProductNo: null,
    Diameter: false,
    Description: null,
    Unit: null,
    Quantity: 0,
    DiameterValue: 0,
    Width: 0,
    Length: 0,
    Height: 0,
    Print: null,
    Quality: null,
    UnitPrice: 0,
    Histories: [],
    IsProduced: false,
    Title: null,
    SelectType: true,
    UnitPriceHistories: [],
  },
  productValidation: [],
};

export default {
  state,
  getters,
  actions,
  mutations,
};
