export default {
    getOrderList: state => state.orderList,
    geOneOrder: state => state.orderOne,
    getOrderValidation: state => state.orderValidation,
    getOrderFilter: state => state.orderFilter,
    getOrderTotalCount: state => state.orderTotalCount,
    getOrderForm: state => state.orderForm,
    getstockTotalCount: state => state.StockTotalCount,
    getstockFilter: state => state.StockFilter,


}