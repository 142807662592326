import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  potentialCustomerList: [],
  potentialCustomer: {},
  potentialCustomerFilter: {
    SearchTerm: null,
    StartDate: null,
    EndDate: null,
    CountryId: null,
    CityId: null,
    Channel: null,
    InterestedProductIds: [],
    CompanyName: null,
    LeadId: "0",
    Page: 1,
    SortBy: null,
    SortDirection: 1,
    PageSize: 200,
    AccountManagerId:null
  },
  potentialCustomerTotal: {
    PageCount: null,
    TotalCount: null,
  },
  accountNumber: null,
  potentialCustomerForm: {
    ID: null,
    FirstName: null,
    LastName: null,
    CompanyName: null,
    CityId: null,
    CountryId: 2635167,
    PostalCode: null,
    ContactNumber: null,
    Email: null,
    CompanyRegistrationNo:null,
    Industry: null,
    Address: null,
    Channel: null,
    Notes: [],
    InterestedProductIds: [],
    Calls: [],
    Samples: [],
    Documents: [],
    SalesPersonId: null,
    CountryCode: null,
    CustomNumber: null,
    AccountManagerId: null,
    Products: [],
    CurrentCompanyInformations:[],
    Priority:null
  },
  potentialCustomerValidationErrors: [],
};

export default {
  state,
  getters,
  actions,
  mutations,
};
