import { client } from "../../helpers/axiosHelper";
import { Message} from "element-ui";
export default {
  async getReportList(context, payload) {
    var res;
    if (payload) {
      res = await client.post("/Report/GetReports", payload);
    } else {
      res = await client.post("/Report/GetReports")      
    }
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setReportList", res.data.Data);
      return res.data.Data;
    }
  },
};
