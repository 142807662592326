export default {
    setProductsList(state, data) {
        state.productsList = data;
    },
    setProductOne(state, data) {
        state.product = data;
    },
    setProductValidation(state, data) {
        state.productValidation = data;
    }
}