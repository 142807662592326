<template>
  <div class="dialog-container import-excel-file">
    <div class="dialog-title">
      <div class="head-title">Import Excel File<span class="yellow-dot">.</span></div>
      <el-button class="btn close" type="danger" @click="close()"> <i class="icon-xmark-solid"></i></el-button>
    </div>
    <div class="documents">
      <div class="upload">
        <el-upload
          class="upload-demo"
          :class="{ disable: file.FileName != null ? true : false }"
          :disabled="file.FileName != null ? true : false"
          drag
          :action="$client.defaults.baseURL + 'PotentialCustomer/Import'"
          :show-file-list="false"
          :on-success="handleImportFileSuccess"
          :headers="{
            Authorization: $client.defaults.headers.common['Authorization'],
          }"
        >
          <i class="icon-cloudarrow"></i>
          <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
          <!-- <div class="el-upload__tip" slot="tip">Excel files with a size less than 500kb</div> -->
        </el-upload>

        <div class="all-files" v-if="file.Url">
          <div class="files">
            <div class="file">
              <div class="file-icon">
                <img :src="getExtension(file.FileName)" />
              </div>
              <div class="filecontent">
                <div class="file-name">{{ file.FileName }}</div>
                <div class="file-size">{{ fileSize(file.FileSize) }}</div>
              </div>
            </div>
            <div class="icon-status">
              <div class="icon-status" @click="clearPreviewPhoto"><i class="icon-check"></i><i class="icon-xmark"></i></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dialog-footer-mid">
      <div class="bot">
        <el-button class="btn" type="danger" @click="close"> <i class="icon-xmark-solid"></i> Cancel</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["excelFile"],
  name: "importExcelFile",
  data() {
    return {
      file: {
        FileName: null,
        Url: null,
        FileSize: 0,
      },
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    importFile() {
      var payload = {
        ExcelFile: {
          Url: this.project.file.Url,
          FileName: this.project.file.FileName,
          FileSize: this.project.file.FileSize,
        },
      };

      var res = this.$client.post("/PotentialCustomer/Import", payload);
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
        this.file = null;
        this.close();
        this.$store.dispatch("getPotentialCustomersList", { Page: 1, PageSize: 999999, SearchTerm: null });
      }
    },
    handleImportFileSuccess(response) {
      if (!response.HasError) {
        this.$message.success(response.Message);
        this.close();
        this.$store.dispatch("getPotentialCustomersList", { Page: 1, PageSize: 999999, SearchTerm: null });
      } else {
        this.$message.error(response.Message);
      }
    },
  },
};
</script>
