export default {
  setValidationErrors(state, data) {
    state.validationErrors = data;
  },
  setToken(state, data) {
    state.token = data;
  },
  setUser(state, data) {
    state.user = data;
  },
  setEnums(state, data) {
    state.enums = data;
  },
  setAllCountries(state, data) {
    state.allCountries = data;
  },
  // setCountries(state, data) {
  //   state.countries = data;
  // },
  // setCities(state, data) {
  //   state.cities = data;
  // },
  setIndustries(state, data) {
    state.industries = data;
  },
  setAgents(state, data) {
    state.agents = data;
  },
  setInterestProducts(state, data) {
    state.interestProducts = data;
  },
  setNotification(state, data) {
    state.notification = data;
  },
};
