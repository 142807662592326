export default {
    setSuppliersList(state, data) {
        state.SupplierList = data;
    },
    setSupplierOne(state, data) {
        state.Supplier = data;
    },
    setSupplierAccountNumber(state, data) {
        state.accountNumber = data;
    },
    setSupplierValidation(state, data) {
        state.SupplierValidationErrors = data;
    }
    
}