<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "app",

  data() {
    return {
      screenWidth: window.innerWidth,
    };
  },
  beforeCreate() {
    this.$store.dispatch("getAllCountries");
    // this.$store.dispatch("getCountries");
  },
  async created() {
    this.$OneSignal.on("subscriptionChange", function (isSubscribed) {
      console.log("The user's subscription state is now:", isSubscribed);
    });
    this.$OneSignal.on("notificationDisplay", function (event) {
      console.warn("OneSignal notification displayed:", event);
    });
    this.$OneSignal.showNativePrompt();
  
  },
};
</script>
<style lang="less">
@import url("./assets/css/base.less");
</style>
