import Vue from "vue";
import Vuex from "vuex";
import Auth from "./Auth";
import PotentialCustomer from "./PotentialCustomer";
import Customers from "./Customers";
import Users from "./Users";
import Product from "./Product";
import Quatation from "./Quatations";
import Transfers from "./Transfers";
import Warehouse from "./Warehouse";
import Supplier from "./Supplier";
import Proformas from "./Proformas";
import Project from "./Project";
import Orders from "./Orders";
import Agent from "./Agent";
import Note from "./Note";
import Report from "./Report";
import ProductSample from "./ProductSample";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        Auth,
        PotentialCustomer,
        Customers,
        Users,
        Product,
        Quatation,
        Transfers,
        Warehouse,
        Supplier,
        Proformas,
        Project,
        Orders,
        Agent,
        Note,
        ProductSample,
        Report
    },
});