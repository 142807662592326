import { client } from "../../helpers/axiosHelper";
import { Message, MessageBox } from "element-ui";
export default {

  async getPotentialCustomersList(context, payload) {
    var res;
    if (payload) {
      if(!payload.LeadId)
      {
        payload.LeadId = "0";
      }
     
      res = await client.post("/PotentialCustomer/List", payload);
    } else {
      
      res = await client.post("/PotentialCustomer/List", context.state.potentialCustomerFilter);
    }
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setPotentialCustomersList", res.data.Data.Items);
      context.state.potentialCustomerTotal.TotalCount = res.data.Data.TotalCount;
      context.state.potentialCustomerTotal.LeadId = 0;
      context.state.potentialCustomerTotal.PageCount = res.data.Data.PageCount;
    }
  },

  async addPotentialCustomer(context, payload) {
    var res = await client.post("/PotentialCustomer/AddUpdate", payload);
    context.commit("setPotentialCustomerValidation", res.data.ValidationErrors);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      Message.success(res.data.Message);
      context.dispatch("getPotentialCustomersList");
    }
    return res.data;
  },

  async getOne(context, payload) {
    // Payload String olarka Customer Id si alıcak payload = "customerId"
    // View Veya Edit yaparken burada state'de setledigimiz potentialCustomer dialogun içine gönderilicek.
    var res = await client.post("/PotentialCustomer/Get", { Id: payload });
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setPotentialCustomerOne", res.data.Data);
    }
    return res.data;
  },

  async generateAccountNumber(context) {
    var res = client.post("/PotentialCustomer/GenerateAccountNumber");
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setPotentialCustomerAccountNumber", res.data.Data);
    }
  },

  async deleteOne(context, payload) {
    MessageBox.confirm("Are you sure for delete this?", "Warning", {
      confirmButtonText: "Delete",
      confirmButtonClass: "danger-btn-confirm",
      cancelButtonText: "Back",
      type: "warning",
    })
      .then(async () => {
        var res = await client.post("/PotentialCustomer/Delete", payload);
        if (res.data.HasError) {
          Message.error(res.data.Message);
        } else {
          Message.success(res.data.Message);
          await context.dispatch("getPotentialCustomersList");
        }
      })
      .catch(() => {});
  },
};
