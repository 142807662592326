import { client } from "../../helpers/axiosHelper";
import { Message, MessageBox } from "element-ui";

export default {
  async getOrderList(context, payload) {
    var res;
    if (payload) {
      res = await client.post("/Order/List", payload);
    } else {
      res = await client.post("/Order/List", context.state.orderFilter);
    }
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setOrderList", res.data.Data.Items);
      context.state.orderTotalCount.TotalCount = res.data.Data.TotalCount;
      context.state.orderTotalCount.PageCount = res.data.Data.PageCount;
    }
  },

  async getOrder(context, payload) {
    var res = await client.post("/Order/Get", { Id: payload });
    if (res.data.HasError) {
      Message.error(res.data.Message);
    }
    return res.data;
  },
  async deleteOrder(context, payload) {
    MessageBox.confirm("Are you sure for delete this?", "Warning", {
      confirmButtonText: "Delete",
      confirmButtonClass: "danger-btn-confirm",
      cancelButtonText: "Back",
      type: "warning",
    })
      .then(async () => {
        var res = await client.post("/Order/Delete", payload);
        if (res.data.HasError) {
          Message.error(res.data.Message);
        } else {
          Message.success(res.data.Message);
          await context.dispatch("getOrderList");
        }
      })
      .catch(() => {});
  },
  async getProductsStock(context, payload) {
    var res = await client.post("/Order/GetProductStocks", payload);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.state.StockTotalCount.TotalCount = res.data.Data.TotalCount;
      context.state.StockTotalCount.PageCount = res.data.Data.PageCount;
    }
    return res;
  },

  async orderAddUpdate(context, payload) {
    var res = await client.post("/Order/AddUpdate", payload);
    context.commit("setOrderValidation", res.data.ValidationErrors);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      Message.success(res.data.Message);
      context.dispatch("getOrderList");
    }
    return res.data;
  },
};
