import { client } from "../../helpers/axiosHelper";
import { Message, MessageBox } from "element-ui";
export default {
  async getUserList(context, payload) {
    var res;
    if (payload) {
      res = await client.post("/User/List", payload);
    } else {
      res = await client.post("/User/List", context.state.filter);
    }
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setUserList", res.data.Data.Items);
      context.state.userTotalCount.TotalCount = res.data.Data.TotalCount;
      context.state.userTotalCount.PageCount = res.data.Data.PageCount;
    }
  },
  async getOneUser(context, payload) {
    var res = await client.post("/User/Get", { Id: payload });
    context.commit("setUserValidation", res.data.ValidationErrors);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    }
    return res;
  },
  async AddUpdateUser(context, payload) {
    var res = await client.post("/User/AddUpdate", payload);
    context.commit("setUserValidation", res.data.ValidationErrors);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      Message.success(res.data.Message);
      await context.dispatch("getUserList");
    }
    return res.data;
  },
  async deleteOneUser(context, payload) {
    MessageBox.confirm("Are you sure for delete this?", "Warning", {
      confirmButtonText: "Delete",
      cancelButtonText: "Back",
      confirmButtonClass:"danger-btn-confirm",
      type: "warning",
    })
      .then(async () => {
        var res = await client.post("/User/Delete", payload);
        if (res.data.HasError) {
          Message.error(res.data.Message);
        } else {
          Message.success(res.data.Message);
          await context.dispatch("getUserList");
        }
      })
      .catch(() => {});
  },
};
