<template>
  <div id="login">
    <div class="login-page">
      <div class="left">
        <div class="top-green"></div>
        <div class="top-icon">
          <img class="is-pc" src="/static/img/login-logo.svg" alt="" />
          <img class="is-mobile" width="100%" height="64" src="/static/img/login-logo-mobile.svg" alt="" />
        </div>
        <div class="bot-text">Portal<span>.</span></div>
      </div>
      <div class="right">
        <div class="inputs">
          <div class="top-text">Login<span>.</span></div>
          <div class="input-container email">
            <el-input placeholder="Email" v-model="form.Email"></el-input>
            <span class="error-validation" v-if="validation.length > 0">{{ $errorMessage("Email", validation) }}</span>
          </div>
          <div class="input-container">
            <el-input placeholder="Password" show-password v-model="form.Password"></el-input>
            <span class="error-validation" v-if="validation.length > 0">{{ $errorMessage("Password", validation) }}</span>
          </div>
          <div class="bottom">
            <div class="forget-psw">Forgot password <span>?</span></div>
            <el-button type="primary" class="login-btn" @click="signIn"><i class="icon-login"></i> Login</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {};
  },
  computed: {
    form() {
      return this.$store.getters.getLoginForm;
    },
    validation() {
      return this.$store.getters.getAuthValidationErrors;
    },
  },
  methods: {
    async signIn() {
      await this.$store.dispatch("login");
    },
  },
};
</script>

<style lang="less"></style>
