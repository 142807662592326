import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  AgentList: [],
  Agent:[],
  AgentFilter: {
    SearchTerm: null,
    Page: 1,
    PageSize: 200,
    GetDeleted: null,
    GetDeactivated: null,
  },
  agentForm: {
    ID: null,
    Title: null,
    OrderNo: null,
    AuthorizedPerson: null,
    Email: null,
    ContactNumber: null,
    Address: null,
    AgentNo: null,
    CountryId: 2635167,
    CityId: null,
    PostCode: null,
    Note: null,
    DeliveryType: null,
  },
  AgentTotal: {
    PageCount: null,
    TotalCount: null,
  },
  agentValidation: [],
};

export default {
  state,
  getters,
  actions,
  mutations,
};
