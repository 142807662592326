import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  SupplierList: [],
  Supplier: {},
  SupplierFilter: {
    SearchTerm: null,
    StartDate: null,
    EndDate: null,
    CountryId: null,
    CityId: null,
    AuthorizedPerson: null,
    Page: 1,
    PageSize: 200,
    GetDeleted: null,
    GetDeactivated: null,
  },
  SupplierTotal: {
    PageCount: null,
    TotalCount: null,
  },
  SupplierForm: {
    ID: null,
    SupplierNo: null,
    Title: null,
    CountryId: 2635167,
    CityId: null,
    PostalCode: null,
    Address: null,
    AuthorizedPerson: null,
    Email: null,
    ContactNumber: null,
    Notes: null,
  },
  SupplierValidationErrors: [],
};

export default {
  state,
  getters,
  actions,
  mutations,
};
