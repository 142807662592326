export default {
    setProformasList(state, data) {
        state.proformasList = data;
    },
    setProformatOne(state, data) {
        state.proforma = data;
    },
    setProformaValidation(state, data) {
        state.proformaValidation = data;
    }
}