import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  projectList: [],
  completedProjectList: [],
  project: {},
  projectFilter: {
    SearchTerm: null,
    StartDate: null,
    EndDate: null,
    CountryId: null,
    CityId: null,
    Stage: null,
    Status: null,
    ProductManagerId: null,
    Page: 1,
    PageSize: 200,
    SortBy: null,
    SortDirection: 1
  },
  projectTotal: {
    PageCount: null,
    TotalCount: null,
  },
  projectValidationErrors: [],
};

export default {
  state,
  getters,
  actions,
  mutations,
};
