import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  notes: [],
  noteFilter: {
    SearchTerm: null,
    PageSize: 999999,
    Page: 1,
  },
  notesValidation: [],
};

export default {
  state,
  getters,
  actions,
  mutations,
};
