import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  orderFilter: {
    Page: 1,
    PageSize: 200,
    SearchTerm: null,
    CustomerId: null,
    Status: null,
    AgentId: null,
    OrderStartDate: null,
    OrderEndDate: null,
    SortBy: null,
    SortDirection: 1,
  },
  StockFilter: {
    Page: 1,
    PageSize: 200,
    SearchTerm: null,
    CustomerId: null,
    WarehouseId:null
  },
  orderList: [],
  orderValidation: [],
  orderTotalCount: {
    PageCount: null,
    TotalCount: null,
  },
  StockTotalCount: {
    PageCount: null,
    TotalCount: null,
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
