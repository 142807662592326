import { client } from "../../helpers/axiosHelper";
import { Message, MessageBox } from "element-ui";
export default {
  async getQuotationsList(context, payload) {
    var res;
    if (payload) {
      res = await client.post("/Quotation/List", payload);
    } else {
      res = await client.post("/Quotation/List", context.state.quotationFilter);
    }
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setQuotationsList", res.data.Data.Items);
      context.state.quotationsTotal.TotalCount = res.data.Data.TotalCount;
      context.state.quotationsTotal.PageCount = res.data.Data.PageCount;
    }
  },
  async getOneQuotation(context, payload) {
    var res = await client.post("/Quotation/Get", { Id: payload });
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setQuotationtOne", res.data.Data);
      return res.data.Data;
    }
  },
  async addUpdateQuotations(context, payload) {
    var res = await client.post("/Quotation/AddUpdateNewOffer", payload);
    console.log(res.data.Data)
    context.commit("setQuotationValidation", res.data.ValidationErrors);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      Message.success(res.data.Message);
      context.dispatch("getQuotationsList");
    }

    return res.data;
  },
  async reviseQuotations(context, payload) {
    var res = await client.post("/Quotation/Revise", payload);
    context.commit("setQuotationValidation", res.data.ValidationErrors);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      Message.success(res.data.Message);
      context.dispatch("getQuotationsList");
    }
    return res.data;
  },
  async readyQuotations(context, payload) {
    var res = await client.post("/Quotation/Ready", payload);
    context.commit("setQuotationValidation", res.data.ValidationErrors);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      Message.success(res.data.Message);
      context.dispatch("getQuotationsList");
    }

    return res.data;
  },
  async deleteOneQuotation(context, payload) {
    MessageBox.confirm("Are you sure for delete this?", "Warning", {
      confirmButtonText: "Delete",
      confirmButtonClass: "danger-btn-confirm",
      cancelButtonText: "Back",
      type: "warning",
    })
      .then(async () => {
        var res = await client.post("/Quotation/Delete", payload);
        if (res.data.HasError) {
          Message.error(res.data.Message);
        } else {
          Message.success(res.data.Message);
          await context.dispatch("getQuotationsList");
        }
      })
      .catch(() => {});
  },
  async confirmQuotation(context, payload) {
    var res = await client.post("/Quotation/Confirm", payload);
    context.commit("setQuotationValidation", res.data.ValidationErrors);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      Message.success(res.data.Message);
      context.dispatch("getQuotationsList");
    }
    return res.data;
  },
};
