import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  filter: {
    SearchTerm: null,
    StartDate: null,
    EndDate: null,
    CountryId: null,
    CityId: null,
    Page: 1,
    PageSize: 200,
  },
  stockFilter: {
    SearchTerm: null,
    Page: 1,
    PageSize: 200,
    WarehouseId:null
  },
  warehouseForm: {
    WarehouseNo: null,
    AuthorizedPerson: null,
    Email: null,
    ContactNumber: null,
    CountryId: 2635167,
    CityId: null,
    PostalCode: null,
    Address: null,
    Notes: null,
    Title: null,
  },
  warehouseList: [],
  stockList: [],
  warehouse: [],
  warehouseValidation: [],
  warehouseTotalCount: {
    PageCount: null,
    TotalCount: null,
  },
  stockTotalCount: {
    PageCount: null,
    TotalCount: null,
  },
  warehouseItemForm: {
    Customer: {
      ID: null,
    },
    Box: {
      PiecesInBox: 0,
      Weight: 0,
      BoxDimensions: {
        X: 0,
        Y: 0,
        Z: 0,
      },
    },
    Warehouses: [
      {
        ID: null,
        BoxQuantity: 0,
        PalletQuantity: 0,
      },
    ],
    Product: {
      ProductNo: null,
      Diameter: false,
      DiameterValue: 0,
      Width: 0,
      Length: 0,
      Height: 0,
      Print: null,
      Type:null,
      Quality: null,
      UnitPrice: 0,
      Quantity: 0,
      IsProduced: true,
      Title: null,
      ID: null,
    },
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
