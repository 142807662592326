<template>
  <div class="dialog-container" id="proformas">
    <transition name="el-fade-in" mode="out-in">
      <el-row style="display: flex; align-items: center; justify-content: center; width: 100%" v-if="loading == 'updateScreenLoading' && proforma == null" key="screen-loading">
        <lottie-player autoplay loop mode="normal" src="/lottie/packaging-source-loading-lottie.json" style="width: 320px"></lottie-player>
      </el-row>
      <el-row v-else key="content-loading">
        <div class="dialog-title">
          <div class="head-title">{{ proforma.ProformaNo != null ? "Proforma No" : "Create New Proforma" }}<span class="yellow-dot">.</span>{{ proforma.ProformaNo != null ? "  #" + proforma.ProformaNo : "" }}</div>

          <el-button class="btn close" type="danger" @click="close()"> <i class="icon-xmark-solid"></i></el-button>
        </div>
        <div class="form">
          <el-row :gutter="24">
            <el-col :sm="12">
              
              <!-- <div class="title" v-if="proforma.ProformaNo != null">Customer<span class="yellow-dot">.</span></div> -->
              <div class="left" v-if="proforma.ProformaNo == null">
                Customer Name:

                <el-select style="width: 100%; margin-top: 15px" v-if="proforma.ProformaNo == null" @clear="proforma.CustomerId = null;" filterable v-model="proforma.CustomerId" placeholder="Select" @change="changeProforma" >
                  <el-option v-for="item in allCustomers" :key="item.ID" :label="item.CompanyName" :value="item.ID"> </el-option>
                </el-select>
                <span class="error-validation">{{ $errorMessage("CustomerId", validation) }}</span>
              </div>

              <div class="left"  style=" margin-top: 15px" v-if="proforma.ProformaNo == null">
                Company Name:

                <el-select style="width: 100%; margin-top: 15px" filterable v-model="proforma.CurrentCompanyInformation.ID" placeholder="Select" @change="changeBillingInfosOnCreateProforma"  :disabled="proforma.CustomerId == null" >
                  <el-option v-for="item in proforma.Customer.CurrentCompanyInformations" :key="item.ID" :label="item.RegisteredCompanyName" :value="item.ID"> </el-option>
                </el-select>
                <span class="error-validation">{{ $errorMessage("CurrentCompanyInformation.ID", validation) }}</span>
              </div>
              <div class="left"  style=" margin-top: 15px" v-if="proforma.ProformaNo != null">
                Company Name:

                <el-select style="width: 100%; margin-top: 15px" filterable v-model="proforma.CurrentCompanyInformation.ID" placeholder="Select" @change="changeBillingInfos" clearable>
                  <el-option v-for="item in proforma.Customer.CurrentCompanyInformations" :key="item.ID" :label="item.RegisteredCompanyName" :value="item.ID"> </el-option>
                </el-select>
                <span class="error-validation">{{ $errorMessage("CurrentCompanyInformation.ID", validation) }}</span>
              </div>
              <div class="left-card" v-if="proforma.ProformaNo != null">
                <div class="thin-text">Billing to:</div>
                <div class="company-name" v-if="proforma.CurrentCompanyInformation.RegisteredCompanyName">{{ proforma.CurrentCompanyInformation.RegisteredCompanyName }}</div>
                <div class="thin-text-2">T/A</div>
                <div class="company-name" v-if="proforma.Customer.CompanyName">{{ proforma.Customer.CompanyName }}</div>
                <div class="address" v-if="proforma.CurrentCompanyInformation.RegisteredAddress">{{ proforma.CurrentCompanyInformation.RegisteredAddress }} <br /></div>
                <div class="no" v-if="proforma.Customer">{{ proforma.Customer.ContactNumber }}</div>
              </div>
            </el-col>
            <el-col :sm="12" class="right-card" v-if="proforma.ProformaNo != null">
              <div class="inline-form">
                <div class="input-group">
                  <div class="left">Proforma Date:</div>
                  <div class="right">{{ $moment(proforma.CreatedDateTime).format("DD-MM-YYYY") }}</div>
                </div>
                <div class="input-group">
                  <div class="left">Proforma No:</div>
                  <div class="right">{{ proforma.ProformaNo }}</div>
                </div>
                <div class="input-group" v-if="proforma.Customer.AccountNo">
                  <div class="left">Customer No:</div>
                  <div class="right">
                    {{ proforma.Customer.AccountNo }}
                    <br />
                  </div>
                </div>
                <div class="input-group" v-else>
                  <div class="left">Lead ID:</div>
                  <div class="right">
                    {{ proforma.Customer.LeadId }}
                    <br />
                  </div>
                </div>
                <div v-for="(reason,index) in reasons" :key="index" :value="reason.reasonText" :label="reason.reasonText">
                  <div class="input-group" :style="reason.reasonColor">
                    <div class="left" >{{ reason.reasonType }} Reason:</div>
                    <div class="right">{{ reason.reasonText }}</div>
                  </div>
                </div>
                <!-- <div class="input-group">
              <div class="left">Order No:</div>
              <div class="right" v-if="proforma.Quotation">{{ proforma.Quotation.OfferNo }}</div>
            </div> -->
              </div>
            </el-col>
            <el-col :sm="12" class="right-card" v-if="proforma.ProformaNo == null">
              <div class="inline-form">
                <div class="input-group">
                  <div class="left">Date:</div>
                  <div class="right" v-if="proforma.CustomerId">{{ $moment(proforma.CreatedDateTime).format("DD-MM-YYYY") }}</div>
                </div>
                <div class="input-group">
                  <div class="left">Customer Name:</div>
                  <div class="right" v-if="proforma.Customer && proforma.Customer.CompanyName">{{ proforma.Customer.CompanyName }}</div>
                </div>
                <div class="input-group">
                  <div class="left">Customer ID:</div>
                  <div class="right" v-if="proforma.CustomerId">{{ cId }}</div>
                </div>
              </div>
            </el-col>
          </el-row>          
          
          <div class="line"></div>
          <div class="title tit">Product Informations<span class="yellow-dot">.</span></div>
          <template v-if="proforma.Products.length != 0">
            <div class="product-inf-component">
              <el-collapse accordion>
                <el-collapse-item v-for="(item, index) in proforma.Products" :key="index + 'product-prof'">
                  <template slot="title">
                    <div class="row-title">
                      <div>
                        ITEM {{ index + 1 }} <span class="name"> {{ item != null ? item.Title : null }} </span>
                      </div>
                      <div class="icon">
                        <div class="icon-row-trash" @click="deleteNewProduct(index)"></div>
                      </div>
                    </div>
                  </template>
                  <div class="form">
                    <el-row v-if="item.ID == null">
                      <div class="input-container-row">
                        <el-switch v-model="item.SelectType" active-text="Product Name" inactive-text="Select Products"> </el-switch>
                      </div>
                    </el-row>
                    <el-row :gutter="24">
                      <el-col :span="8" class="input-container" v-if="!item.SelectType">
                        <div class="text">Select Product</div>
                        <el-select placeholder="Select Product" filterable clearable v-if="sampleProductList" value-key="FromSKU" v-model="proforma.Products[index]" @change="createDescription(index)">
                          <el-option v-for="product in sampleProductList" :key="product.FromSKU + '-' + product.StringRepresentation" :value="product" :label="product.Description + '  - SKU: ' + product.FromSKU"></el-option>
                        </el-select>
                        <span class="error-validation">{{ $errorMessage("Products[" + index + "].Title", validation) }}</span>
                      </el-col>

                      <el-col :sm="8" class="input-container" v-if="item.SelectType">
                        <div class="text">Products Name</div>
                        <el-input v-model="item.Title" placeholder="..." @change="createDescription(index)"></el-input>
                        <span class="error-validation">{{ $errorMessage("Products[" + index + "].Title", validation) }}</span>
                      </el-col>
                      <el-col :sm="16" class="input-container">
                        <div class="text">Description</div>
                        <el-input v-model="item.Description" placeholder="..."></el-input>
                        <span class="error-validation">{{ $errorMessage("Description[" + index + "].Title", validation) }}</span>
                      </el-col>
                    </el-row>
                    <el-switch @change="resetDiameter(index)" v-model="item.Diameter" inactive-text="W-L-H" active-text="Other"></el-switch>
                    <el-row :gutter="24" v-if="!item.Diameter">
                      <el-col class="input-container" :sm="8">
                        <div class="text">Width</div>
                        <el-input v-model="item.Width" placeholder="..." @change="createDescription(index)" v-numeric>
                          <div slot="append">CM</div>
                        </el-input>
                        <span class="error-validation">{{ $errorMessage("Products[" + index + "].Width", validation) }}</span>
                      </el-col>
                      <el-col class="input-container" :sm="8">
                        <div class="text">Length</div>
                        <el-input v-model="item.Length" placeholder="..." @change="createDescription(index)" v-numeric>
                          <div slot="append">CM</div>
                        </el-input>
                        <span class="error-validation">{{ $errorMessage("Products[" + index + "].Length", validation) }}</span>
                      </el-col>
                      <el-col class="input-container" :sm="8">
                        <div class="text">Height</div>
                        <el-input v-model="item.Height" placeholder="..." @change="createDescription(index)" v-numeric>
                          <div slot="append">CM</div>
                        </el-input>
                        <span class="error-validation">{{ $errorMessage("Products[" + index + "].Height", validation) }}</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="24" v-if="item.Diameter">
                      <el-col class="input-container" :sm="8">
                        <div class="text">Other</div>
                        <el-input placeholder="..." class="input-with-select" v-model="item.DiameterValue" @change="createDescription(index)" v-numeric>
                          <el-select v-model="item.Unit" slot="append" class="append-diameter" placeholder="..." clearable @change="createDescription(index)" @clear="item.Unit == null">
                            <el-option v-for="(item, index) in getEnums.find((x) => x.Title == 'Unit').Enums" :key="item.Key + index" :label="item.DisplayName" :value="item.Key"></el-option>
                          </el-select>
                        </el-input>
                        <span class="error-validation">{{ $errorMessage("Products[" + index + "].DiameterValue", validation) }}</span>
                      </el-col>
                    </el-row>

                    <!-- ADD UPDATE EKRANI  -->
                    <template >
                      <el-row :gutter="24">
                        <el-col class="input-container" :sm="8">
                          <div class="text">Print</div>
                          <el-input v-model="item.Print" placeholder="..." class="select"> </el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Print", validation) }}</span>
                        </el-col>
                        <el-col class="input-container" :sm="8">
                          <div class="text">Alternative Print Options</div>
                          <el-select v-model="selectedPrint" @change="updateAlternativePrint(index)" placeholder="Select" class="select">
                            <el-option v-for="(alternativePrints, alternativePrintIndex) in PrintAlternatives" :key="alternativePrintIndex + '-print'" :label="alternativePrints.AlternativePrint" :value="alternativePrints.AlternativePrint"> </el-option>
                          </el-select>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Print", validation) }}</span>
                        </el-col>
                      </el-row>
                      <el-row :gutter="24">
                        <el-col class="input-container" :sm="8">
                          <div class="text">Quality</div>
                          <el-input v-model="item.Quality" placeholder="..." class="select"> </el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Quality", validation) }}</span>
                        </el-col>
                         <el-col class="input-container" :sm="8">
                          <div class="text">Type</div>
                          <el-input v-model="item.Type" placeholder="..." class="select"> </el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Type", validation) }}</span>
                        </el-col>
                      </el-row>
                      <el-row :gutter="24">
                        <el-col class="input-container" :sm="8">
                          <div class="text">Unit Price</div>
                          <el-input v-model="item.UnitPrice" placeholder="..." class="select" v-numeric> </el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].UnitPrice", validation) }}</span>
                        </el-col>
                        <el-col class="input-container" :sm="8">
                          <div class="text">Quantity</div>
                          <el-input v-model="item.Quantity" placeholder="..." class="select" v-numeric > </el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Quantity", validation) }}</span>
                        </el-col>
                      </el-row>
                      <!-- <div class="line"></div>

                      <el-row :gutter="24">
                        <el-col class="input-container-row" :sm="24">
                          <el-col :span="8"> <div class="text">Unit Price</div> </el-col>
                          <el-col :span="16">
                            <el-input v-model="item.UnitPrice" placeholder="..." v-numeric></el-input>
                            <span class="error-validation">{{ $errorMessage("Products[" + index + "].UnitPrice", validation) }}</span>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-row :gutter="24">
                        <el-col class="input-container-row" :sm="24">
                          <el-col :span="8"> <div class="text">Quantity</div> </el-col>
                          <el-col :span="16">
                            <el-input v-model="item.Quantity" placeholder="..." v-numeric></el-input>
                            <span class="error-validation">{{ $errorMessage("Products[" + index + "].Quantity", validation) }}</span>
                          </el-col>
                        </el-col>
                      </el-row> -->
                      <!-- <template>
                        <div class="title" v-if="item.UnitPriceQuantityAlternatives.length > 0">Alternative Unit Price and Quantity Informations<span class="yellow-dot">.</span></div>
                        <div v-for="(alternatives, alternativeIndex) in item.UnitPriceQuantityAlternatives" :key="alternativeIndex + 'unit-price-quantity'" class="mini-form">
                          <el-row :gutter="24">
                            <el-col :sm="24">
                              <el-col :span="24" class="input-container-row">
                                <div class="text">Alternative {{ alternativeIndex + 1 }}</div>
                                <div class="icon-row-trash" style="cursor: pointer; margin-left: 16px" @click="deleteAlternativeValues(index, alternativeIndex)"></div>
                              </el-col>
                            </el-col>
                          </el-row>

                          <el-row :gutter="24">
                            <el-col class="input-container-row" :sm="24">
                              <el-col :span="8"> <div class="text">Unit Price</div> </el-col>
                              <el-col :span="16">
                                <el-input v-model="alternatives.AlternativeUnitPrice" placeholder="..."></el-input>
                              </el-col>
                            </el-col>
                          </el-row>
                          <el-row :gutter="24">
                            <el-col class="input-container-row" :sm="24">
                              <el-col :span="8"> <div class="text">Quantity</div> </el-col>
                              <el-col :span="16">
                                <el-input v-model="alternatives.AlternativeQuantity" placeholder="..."></el-input>
                              </el-col>
                            </el-col>
                          </el-row>
                        </div>

                         <div class="line"></div>

                        <div class="product-inf-button-groups">
                          <el-button class="add-btn" type="info" @click="createNewAlternativeValues(index)"> <i class="icon-plus-solid"></i>Add New Alternative Values</el-button>
                        </div> 
                      </template> -->
                    </template>
                    <!-- CONFİRM EKRANI -->
                    <!-- <template v-if="proforma.ProformaNo != null">
                      <el-row :gutter="24">
                        <el-col class="input-container" :sm="8">
                          <div class="text">Print</div>
                          <el-input v-model="item.Print" placeholder="..." class="select"> </el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Print", validation) }}</span>
                        </el-col>
                        <el-col class="input-container" :sm="8">
                          <div class="text">Alternative Print Options</div>
                          <el-select v-model="selectedPrint" @change="updateAlternativePrint(index)" placeholder="Select" class="select">
                            <el-option v-for="(alternativePrints, alternativePrintIndex) in PrintAlternatives" :key="alternativePrintIndex + '-print-confirm'" :label="alternativePrints.AlternativePrint" :value="alternativePrints.AlternativePrint"> </el-option>
                          </el-select>
                        </el-col>
                      </el-row>

                      <el-row :gutter="24">
                        <el-col class="input-container" :sm="8">
                          <div class="text">Quality</div>
                          <el-input v-model="item.Quality" placeholder="..." class="select"> </el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Quality", validation) }}</span>
                        </el-col>
                      </el-row>
                      <el-row :gutter="24">
                        <el-col class="input-container" :sm="8">
                          <div class="text">Type</div>
                          <el-input v-model="item.Type" placeholder="..." class="select"> </el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Type", validation) }}</span>
                        </el-col>
                      </el-row>

                      <div class="line"></div>

                      <el-row :gutter="24">
                        <el-col class="input-container" :sm="8">
                          <div class="text">Unit Price</div>
                          <el-input v-model="item.UnitPrice" placeholder="..." class="select" v-numeric> </el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].UnitPrice", validation) }}</span>
                        </el-col>
                        <el-col class="input-container" :sm="8">
                          <div class="text">Quantity</div>
                          <el-input v-model="item.Quantity" placeholder="..." class="select" v-numeric> </el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Quantity", validation) }}</span>
                        </el-col>
                        <el-col class="input-container" :sm="8">
                          <div class="text">Alternative Unit Price Options</div>
                          <el-select v-model="selected" @change="updateAlternative(index)" placeholder="Select" class="select">
                            <el-option v-for="(alternatives, alternativeIndex) in item.UnitPriceQuantityAlternatives" :key="alternativeIndex + '-unit-price-confirm'" :label="'Unit Price: ' + alternatives.AlternativeUnitPrice + ' Quantity: ' + alternatives.AlternativeQuantity" :value="alternativeIndex"> </el-option>
                          </el-select>
                        </el-col>
                      </el-row>
                    </template> -->
                    <div class="line"></div>
                    <template>
                        <div v-for="(origination, originationIndex) in  proforma.Products[index].Originations" :key="originationIndex + 'origination'" class="mini-form">
                          <el-row :gutter="24">
                            <el-col :sm="24">
                              <el-col :span="24" class="input-container-row">
                                <div class="text">Origination {{ originationIndex + 1 }}</div>
                                <div class="icon-row-trash" style="cursor: pointer; margin-left: 16px" @click="deleteOrigination(index,originationIndex)"></div>
                              </el-col>
                            </el-col>
                          </el-row>
                          <el-row :gutter="24">
                            <el-col class="input-container-row" :sm="24">
                              <el-col :span="8"> <div class="text">Origination Unit Price</div> </el-col>
                              <el-col :span="16">
                                <el-input v-model="origination.UnitPrice" placeholder="..." v-numeric ></el-input>
                                <span class="error-validation">{{ $errorMessage("Products[" + index + "].Originations["+originationIndex+"].UnitPrice", validation) }}</span>
                              </el-col>
                            </el-col>
                          </el-row>
                          <el-row :gutter="24">
                            <el-col class="input-container-row" :sm="24">
                              <el-col :span="8"> <div class="text">Origination Quantity</div> </el-col>
                              <el-col :span="16">
                                <el-input v-model="origination.Quantity" placeholder="..." v-numeric></el-input>
                                <span class="error-validation">{{ $errorMessage("Products[" + index + "].Originations["+originationIndex+"].Quantity", validation) }}</span>
                              </el-col>
                            </el-col>
                          </el-row>
                          <div class="line"></div>
                        </div>
                        <div class="product-inf-button-groups">
                          <el-button class="add-btn" type="info" @click="addOrigination(index)"> <i class="icon-plus-solid"></i>Add New Origination</el-button>
                        </div>
                      </template>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </template>
          <span v-if="proforma.ProformaNo == null" class="error-validation">{{ $errorMessage("Products", validation) }}</span>
          <div class="product-inf-button-groups">
            <el-button class="add-btn" type="info" @click="createNewProduct"> <i class="icon-plus-solid"></i>Add New Item</el-button>
          </div>

          <!-- <div class="line"></div>
          <div class="title tit">Origination Cost<span class="yellow-dot">.</span></div>
          
          <template v-if="proforma.Originations.length != 0">
            <div class="product-inf-component">
              <el-collapse accordion>
                <el-collapse-item v-for="(item, index) in proforma.Originations" :key="index + 'origination'">
                  <template slot="title">
                    <div class="row-title">
                      <div>
                        Origination {{ index + 1 }} <span class="name" v-if="item.UnitPrice!=null && item.Quantity!=null">£ {{ (item.UnitPrice * item.Quantity) }} </span>
                      </div>
                      <div class="icon">
                        <div class="icon-row-trash" @click="deleteOrigination(index)"></div>
                      </div>
                    </div>
                  </template>
                  <div class="form">
                    <el-row :gutter="24">
                        <el-col class="input-container-row" :sm="24">
                          <el-col :span="8"> <div class="text">Unit Price</div> </el-col>
                          <el-col :span="16">
                            <el-input v-model="item.UnitPrice" placeholder="..." v-numeric></el-input>
                            <span class="error-validation">{{ $errorMessage("Originations[" + index + "].UnitPrice", validation) }}</span>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-row :gutter="24">
                        <el-col class="input-container-row" :sm="24">
                          <el-col :span="8"> <div class="text">Quantity</div> </el-col>
                          <el-col :span="16">
                            <el-input v-model="item.Quantity" placeholder="..." v-numeric></el-input>
                            <span class="error-validation">{{ $errorMessage("Originations[" + index + "].Quantity", validation) }}</span>
                          </el-col>
                        </el-col>
                      </el-row>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </template> -->
          
          <!-- <div class="terms-and-conditions" v-if="proforma.Origination">
            <el-row :gutter="24" >
                <el-col class="input-container-row" :sm="24">
                  <el-col :span="8" class="flex-mini-gap">
                    <div class="icon">
                      <div class="icon-row-trash" style="cursor: pointer"></div>
                    </div>
                    <div class="text">Origination</div>             
                  </el-col>
                  <el-col :span="8" class="input-container">
                    <div class="text" >Unit Price</div>
                    <el-input  v-numeric></el-input>
                    <span class="error-validation">{{ $errorMessage("Discount", validation) }}</span>
                  </el-col>
                  <el-col :span="8" class="input-container">
                    <div class="text">Quantity</div>
                    <el-input  v-numeric></el-input>
                    <span class="error-validation">{{ $errorMessage("Discount", validation) }}</span>
                  </el-col>
                </el-col>
            </el-row>
          </div> -->
          <!-- <div class="product-inf-button-groups">
            <el-button class="add-btn" type="info" @click="addOrigination()"> <i class="icon-plus-solid" ></i>Add Origination</el-button>
          </div> -->

          <div class="line"></div>
          <div class="title tit">Discount<span class="yellow-dot">.</span></div>
          <div class="terms-and-conditions" v-if="proforma.Discount!=null">
            <el-row :gutter="24" >
                <el-col class="input-container-row" :sm="24">
                  <el-col :span="8" class="flex-mini-gap">
                    <div class="icon">
                      <div class="icon-row-trash" style="cursor: pointer" @click="deleteDiscount()"></div>
                    </div>
                    <div class="text">Discount</div>             
                  </el-col>
                  <el-col :span="8" class="input-container">
                    <div class="text" >Discount Price</div>
                    <el-input v-model="proforma.Discount" v-numeric ></el-input>
                    <span class="error-validation">{{ $errorMessage("Discount", validation) }}</span>
                  </el-col>
                </el-col>
            </el-row>
          </div>
          <div class="product-inf-button-groups">
            <el-button class="add-btn" type="info" @click="addDiscount()" :disabled="proforma.Discount!=null"> <i class="icon-plus-solid" ></i>Add Discount</el-button>
          </div>

          <div class="line"></div>
          <template v-if="proforma.Products.length > 0">
            <div class="title">Payment<span class="yellow-dot">.</span></div>

            <el-row>
              <el-col :sm="8">
                <div class="right-card">
                  <div class="inline-form">
                    <div class="input-group">
                      <div class="left">Total:</div>
                      <div class="right">
                        £
                        {{
                         paymentTotal() 
                        }}
                      </div>
                    </div>
                          <!-- // (parseFloat(proforma.Products.map((x) => x.Quantity * x.UnitPrice) 
                          //   .reduce((a, b) => a + b, 0)
                          //   .toFixed(2)) +
                          // parseFloat(proforma.Originations.map((x) => x.Quantity * x.UnitPrice)
                          //   .reduce((a, b) => a + b, 0)
                          //   .toFixed(2)) -
                          // parseFloat(proforma.Discount)).toFixed(2) -->
                    <div class="input-group">
                      <div class="left">VAT (20%):</div>
                      <div class="right">£ {{ paymentVAT() }}</div>
                    </div>
                    <!-- (proforma.Products.map((x) => x.Quantity * x.UnitPrice).reduce((a, b) => a + b, 0) * 0.2).toFixed(2) -->
                    <div class="input-group">
                      <div class="left">Grand Total:</div>
                      <div class="right">£ {{ paymentGrandTotal() }}</div>
                    </div>
                    <!-- (proforma.Products.map((x) => x.Quantity * x.UnitPrice).reduce((a, b) => a + b, 0) * 0.2 + proforma.Products.map((x) => x.Quantity * x.UnitPrice).reduce((a, b) => a + b, 0)).toFixed(2) -->
                  </div>
                </div>
              </el-col>
            </el-row>
            <div class="line"></div>
          </template>
          <div class="title">Payment Instruction<span class="yellow-dot">.</span></div>
          <div class="terms-and-conditions">
            <el-row :gutter="24" v-for="(item, index) in proforma.BeforeProductionBalans" :key="index + 'before-production-items'">
              <!-- <el-col class="input-container-row" :sm="24">
            <el-col :span="8" class="flex-mini-gap">
              <el-checkbox @change="checkedListPayment(index)" :checked="checkListPayment.some((x) => x == index) ? true : false"></el-checkbox>
              <div class="text">Before Production {{ index + 1 }}</div>
            </el-col>
            <el-col :span="16">
              <el-input v-model="proforma.BeforeProductionBalans[index]" placeholder="%..."></el-input>
            </el-col>
          </el-col> -->
              <el-col class="input-container-row" :sm="24">
                <el-col :span="8" class="flex-mini-gap">
                  <div class="icon">
                    <div class="icon-row-trash" style="cursor: pointer" @click="deletePayment(index)"></div>
                  </div>
                  <div class="text">Before Production {{ index + 1 }}</div>
                </el-col>
                <el-col :span="16">
                  <el-input v-model="proforma.BeforeProductionBalans[index]" placeholder="Please firstly write before payment ...% and than write text"></el-input>
                  <span class="error-validation">{{ $errorMessage("BeforeProductionBalans[" + index + "]", validation) }}</span>
                </el-col>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col class="input-container-row" :sm="24">
                <el-col :span="8" class="flex-mini-gap">
                  <div class="text">Last Payment Info</div>
                </el-col>
                <el-col :span="16">
                  <el-input v-model="proforma.LastPaymentText" placeholder="Plase write last text like 'Balance payment before delivery'"></el-input>
                  <span class="error-validation">{{ $errorMessage("LastPaymentText", validation) }}</span>
                </el-col>
              </el-col>
            </el-row>
          </div>
          <div class="product-inf-component">
            <div class="product-inf-button-groups">
              <el-button class="add-btn" @click="addPayment()" type="info"> <i class="icon-plus-solid"></i>Add Before Production</el-button>
            </div>
          </div>

          <div class="line"></div>
          <div class="title">Terms and Conditions<span class="yellow-dot">.</span></div>
          <div class="terms-and-conditions">
            <!-- <el-row :gutter="24">
              <el-col class="input-container-row-align-flex-start" :sm="24">
                <el-col :span="8" class="flex-mini-gap">
                  <div class="icon-row-trash" style="cursor: pointer" @click="deleteTerms(index)"></div>
                  <div class="text">Delivery Time</div>
                </el-col>
                <el-col :span="4">
                  <el-input v-model="proforma.DeliveryTime"  placeholder="..." @change="updateTerm"></el-input>
                </el-col>
              </el-col> 
            </el-row>-->
            <el-row :gutter="24" v-for="(item, index) in proforma.Terms" :key="index + 'term'">
              <el-col class="input-container-row-align-flex-start" :sm="24">
                <el-col :span="8" class="flex-mini-gap">
                  <div class="icon-row-trash" style="cursor: pointer" @click="deleteTerms(index)"></div>
                  <div class="text">Row {{ index + 1 }}</div>
                </el-col>
                <el-col :span="16" v-if="!proforma.Terms[index].IsDeliveryTime" >
                  <el-input  v-model="proforma.Terms[index].Text" type="textarea" :rows="2" placeholder="Add Terms..."></el-input>
                </el-col>
                <el-col :span="12" v-if="proforma.Terms[index].IsDeliveryTime" >
                  <el-input  v-model="proforma.Terms[index].Text" type="textarea" :rows="2" placeholder="Add Terms..." disabled></el-input>
                </el-col>
                <el-col :span="4"  v-if="proforma.Terms[index].IsDeliveryTime">
                  <el-input  v-model="proforma.DeliveryTime" placeholder="..." v-numeric @input="updateTerm()" ></el-input>
                  <span class="error-validation">{{ $errorMessage("DeliveryTime", validation) }}</span>
                </el-col>
              </el-col>
            </el-row>
          </div>

          <div class="product-inf-component">
            <div class="product-inf-button-groups">
              <el-button class="add-btn" @click="addTerms()" type="info"> <i class="icon-plus-solid"></i>Add Row</el-button>
            </div>
          </div>
          <div class="line"></div>

          <div class="title">Notes<span class="yellow-dot">.</span></div>
          <el-input v-model="proforma.Note" type="textarea" :rows="5" placeholder="Add Proforma Note..."></el-input>
          <div class="line"></div>
          <div class="title">Send Payment to<span class="yellow-dot">.</span></div>
          <div class="payment-card">
            Aegean Trading Limited <br />
            Account No: 73783765<br />
            Sort Code: 60-09-16<br />
            IBAN: GB62NWBK60091673783765
          </div>
          <el-tabs type="border-card" :stretch="true" v-if="proforma.ID != null">
            <el-tab-pane v-if="proforma.RevisedProformas.length>0">
              <span slot="label"><i class="icon-receipt"></i> Proforma</span>
              <div class="offer form">
                <div class="title">Quotation<span class="yellow-dot">.</span></div>
                <el-table v-if="proforma" :data="proforma.RevisedProformas" style="width: 100%">
                  <el-table-column prop="ProformaNo" label="Proforma No" width="180"> </el-table-column>
                  <el-table-column prop="Customer.CompanyName" label="Customer"></el-table-column>
                  <el-table-column prop="date" label="Date">
                    <template slot-scope="scope">
                      <span>Created Date : {{ $moment(scope.row.CreatedDateTime).format("DD-MM-YYYY") }}</span>
                      <br />
                      <span>Last Update : {{ $moment(scope.row.UpdatedDateTime).format("DD-MM-YYYY") }}</span>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column prop="status" label="Status">
                    <template slot-scope="scope">
                      <div class="status" :class="getClassStatus(scope.row.Status)">
                        <span v-if="getEnums">{{ getEnumsDisplay("OfferStatus", scope.row.Status) }}</span>
                      </div>
                    </template>
                  </el-table-column> -->

                  <el-table-column label="Account Manager">
                    <template slot-scope="scope"> {{ scope.row.AccountManager.FullName }} </template>
                  </el-table-column>

                  <el-table-column label="Preview" width="160">
                    <template slot-scope="scope">
                      <el-button class="popover-eye" @click="seePrewiev(proforma.ID,scope.row.ProformaNo)" slot="reference">
                        <i class="el-icon-loading" style="font-size: 18px; color: blue" v-if="loading == 'exportLoading' + scope.row.ProformaNo"></i>
                        <i class="icon-eye-popover" v-else></i>
                      </el-button>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column label="Download Pdf" width="160">
                    <template slot-scope="scope">
                      <el-button class="popover-eye"  @click="exportPdf(quotationList.find((x) => x.ID == scope.row.ID))" slot="reference">
                      <i class="icon-download-2" style="cursor: pointer" @click="exportPdf(quotationList.find((x) => x.ID == scope.row.ID))"></i>
                      <i class="el-icon-loading" style="font-size: 18px; color: blue" v-if="loading == 'downloadLoading' + scope.row.ID"></i>
                      <i class="icon-download-2" v-else></i>
                      </el-button>
                      
                    </template>
                  </el-table-column> -->
                </el-table>
              </div>
            </el-tab-pane>
            <el-tab-pane>
              <span slot="label"
                ><i class="icon-arrows-rotate-solid"></i>Updates<span class="notifications">{{ proforma.Histories.length > 9 ? "9+" : proforma.Histories.length }}</span></span
              >
              <div class="updates-timeline form">
                <el-timeline>
                  <el-timeline-item :timestamp="$moment(item.Date).format('MMMM Do YYYY, h:mm:ss A')" placement="top" v-for="(item, index) in proforma.Histories" :key="index">
                    <el-card>
                      <div class="content" v-html="item.Note"></div>
                      <div class="updates-bottom">
                        <div class="bottom" v-if="item.Employee">
                          <div class="circle">{{ item.Employee.FirstName[0] + "" + item.Employee.LastName[0] }}</div>
                          <div class="user">
                            <div class="user-info">{{ item.Employee.FullName }}</div>
                            <div class="status">
                              <span> {{ getEnumsDisplay("TypeOfAuthority", item.Employee.Role) }} </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </el-timeline-item>
                </el-timeline>
              </div>
            </el-tab-pane>
          </el-tabs>
          <div class="dialog-footer footerbutton">
            <el-button class="btn" type="danger" @click="close"> <i class="icon-xmark-solid"></i> Cancel</el-button>
            <el-button class="btn" type="info" @click="exportProforma" v-if="proforma.ProformaNo != null" :loading="loading == 'viewProforma'"> <i class="icon-eye"></i>View Preview</el-button>
            <el-button class="btn" type="primary" @click="createProforma" v-if="proforma.ProformaNo == null" :loading="loading == 'createLoading'"> <i class="icon-plus-solid" v-if="loading == false"></i> Create Proforma</el-button>
            <el-button class="btn" type="info" @click="readyProforma" v-if="proforma.ProformaNo != null && [1,2].includes(proforma.Status) && user.Role==10" :icon="'el-icon-finished'" :loading="loading == 'readyLoading'">Ready</el-button>
            <el-button class="btn" type="primary" @click="reviseProforma" v-if="proforma.ProformaNo != null && [4].includes(proforma.Status)" :disabled="!canUpdate" :icon="'el-icon-edit'" :loading="loading == 'reviseLoading'">Revise</el-button>
            <el-button class="btn" type="primary" @click="saveProforma" v-if="proforma.ProformaNo != null && [1,7,2].includes(proforma.Status)" :disabled="!canUpdate" :icon="'el-icon-edit'" :loading="loading == 'saveLoading'">Save Changes</el-button>
            <el-button class="btn" type="primary" @click="confirm" v-if="proforma.ProformaNo != null && [9].includes(proforma.Status) && user.Role==10" :loading="loading == 'confirmLoading'"> <i class="icon-check-double-solid" v-if="loading == false"></i> Confirm Proforma</el-button>
          </div>
        </div>
      </el-row>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["id"],
  name: "add-or-update-prof",
  data() {
    return {
      PrintAlternatives: [
        {
          AlternativePrint: " Customer Design ",
        },
        {
          AlternativePrint: " 1 Colour ",
        },
        {
          AlternativePrint: " 2 Colour ",
        },
      ],
      cId: null,
      proforma: null,
      originalProforma:null,
      updatable:null,
      reasons:[],
      selected: null,
      selectedQuality: null,
      selectedPrint: null,
      loading: false,
      timeout: null,
    };
  },
  async created() {
    this.loading = "updateScreenLoading";
    if (this.timeout != null) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await this.$store.dispatch("getPotentialCustomersList",{Page:1,PageSize:99999})
      
      await this.checkedId();
      await this.getSampleList();
      this.loading = false;
    }, 500);
    setTimeout(async () => {
       this.originalProforma=JSON.parse(JSON.stringify(this.proforma));
        console.log(this.originalProforma)
        console.log(this.proforma)
        if (JSON.stringify(this.proforma) !== JSON.stringify(this.originalProforma)) {
          this.updatable = true;
        } else {
          this.updatable = false;
        }
    }, 1500);
    
  },
  methods: {
    checkNull(){
      this.proforma.Products.forEach(product => {
        if(product.Width===""){
          product.Width  =null;
        }
        if(product.Length===""){
          product.Length  =null;
        }
        if(product.Height===""){
          product.Height  =null;
        }
      });
    },
    updateTerm(){
    //  var text=this.proforma.Terms.find((x)=>x.IsDeliveryTime==true).Text 
    //  var number = text.match(/\d+/g)
    //  this.proforma.Terms.find((x)=>x.IsDeliveryTime==true).Text=text.replace(number,this.proforma.DeliveryTime);
    this.proforma.Terms.find((x)=>x.IsDeliveryTime==true).Text=`Production and first delivery process for this order may take up to ${this.proforma.DeliveryTime} weeks.`
  },
    paymentTotal(){
      var proTotal = 0;
      var oriTotal = 0;
      var discount = 0;
      var total =0;
      if(this.proforma.Products.length > 0){
          proTotal = this.proforma.Products.map((x) => x.Quantity * x.UnitPrice).reduce((a, b) => a + b, 0);
          this.proforma.Products.forEach((item) => {
            if(item.Originations.length > 0){
              oriTotal += item.Originations.map((x) => x.Quantity * x.UnitPrice).reduce((a, b) => a + b, 0);
            }
        });
        // console.log(this.proforma.Products)
        // console.log(proTotal)
        // console.log(oriTotal)
        total += parseFloat(proTotal);
        total += parseFloat(oriTotal);
      }
      // if(this.proforma.Originations.length > 0){
      //   oriTotal =this.proforma.Originations.map((x) => x.Quantity * x.UnitPrice).reduce((a, b) => a + b, 0);
      //   total += parseFloat(oriTotal);
      // } 
      if(this.proforma.Discount != null && this.proforma.Discount != ""){
        discount = this.proforma.Discount                   
        total -= parseFloat(discount);
      }
       return total.toFixed(2)            
    },
    paymentVAT(){
     return (this.paymentTotal() * 0.2).toFixed(2);
    },
    paymentGrandTotal(){
     return (this.paymentTotal() * 1.2).toFixed(2);
    },
    setReason(){
      if(this.proforma.Reason == null || this.proforma.Reason=="")
        return;
      this.reasons.push({reasonType:this.getEnumsDisplay("ProformaStatus",this.proforma.Status),reasonText:this.proforma.Reason,reasonColor:this.getReasonColor(this.proforma.Status)})
    },
    getReasonColor(status){
      var res;
      switch (status) {
        case 5:
          res = "color: #67c23a";
          break;
        case 6:
          res = "color: #f56c6c";
          break;
        case 8:
          res = "color: #fbbf48";
          break;
      }
      return res;
    },
    async getSampleList() {
      var cust = this.proforma?.Customer;
      var accountNo = cust == null ? null : cust.AccountNo;
      console.log(accountNo)
      await this.$store.dispatch("getProductSamplesList", { Page: 1, PageSize: 999999, SearchTerm: null, RealProducts: true, AccountNo: accountNo });
   },
    async createProforma() {

      this.checkNull()

      this.$store.commit("setProformaValidation", []);
      if (this.validation.length > 0) {
        return;
      }
      this.loading = "createLoading";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var res = await this.$store.dispatch("addProformas", {
          CustomerId: this.proforma.CustomerId,
          Products: this.proforma.Products,
          Note: this.proforma.Note,
          Terms: this.proforma.Terms.map((x)=> x.Text),
          LastPaymentText: this.proforma.LastPaymentText,
          BeforeProductionBalans: this.proforma.BeforeProductionBalans,
          CurrentCompanyInformation: this.proforma.CurrentCompanyInformation,
          // Originations:this.proforma.Originations,
          Discount:this.proforma.Discount,
          DeliveryTime:this.proforma.DeliveryTime == "" ? null : this.proforma.DeliveryTime
        });
        if (res.HasError) {
          this.$message.error(res.Message);
        } else {
          this.$message.success(res.Message);
          // await this.getSampleList();
          await this.$store.dispatch("getProformasList", { Page: 1, PageSize: 999999, SearchTerms: null });
          this.proforma = null;
          this.close();
        }
        this.loading = false;
      }, 500);
      console.log(this.proforma )
    },
    async reviseProforma() {

      this.checkNull()

      this.$store.commit("setProformaValidation", []);
      if (this.validation.length > 0) {
        return;
      }
      this.loading = "saveLoading";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var res = await this.$store.dispatch("reviseProformas", {
          ProformaId: this.proforma.ID,
          Products: this.proforma.Products,
          Note: this.proforma.Note,
          Terms: this.proforma.Terms.map((x)=> x.Text),
          LastPaymentText: this.proforma.LastPaymentText,
          BeforeProductionBalans: this.proforma.BeforeProductionBalans,
          CurrentCompanyInformation: this.proforma.CurrentCompanyInformation,
          // Originations:this.proforma.Originations,
          Discount:this.proforma.Discount,
          DeliveryTime:this.proforma.DeliveryTime== "" ? null : this.proforma.DeliveryTime
        });
        if (res.HasError) {
          this.$message.error(res.Message);
        } else {
          this.$message.success(res.Message);
          await this.getSampleList();
          await this.$store.dispatch("getProformasList", { Page: 1, PageSize: 999999, SearchTerms: null });
          // this.proforma = null;
          this.close();
        }
        this.loading = false;
      }, 500);
    },
    async saveProforma() {

      this.checkNull()

      this.$store.commit("setProformaValidation", []);
      if (this.validation.length > 0) {
        return;
      }
      this.loading = "saveLoading";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var res = await this.$store.dispatch("saveProformas", {
          ProformaId: this.proforma.ID,
          Products: this.proforma.Products,
          Note: this.proforma.Note,
          Terms: this.proforma.Terms.map((x)=> x.Text),
          LastPaymentText: this.proforma.LastPaymentText,
          BeforeProductionBalans: this.proforma.BeforeProductionBalans,
          CurrentCompanyInformation: this.proforma.CurrentCompanyInformation,
          // Originations:this.proforma.Originations,
          Discount:this.proforma.Discount,
          DeliveryTime:this.proforma.DeliveryTime== "" ? null : this.proforma.DeliveryTime
        });
       
        if (res.HasError) {
          this.$message.error(res.Message);
        } else {
          this.$message.success(res.Message);
          await this.getSampleList();
          await this.$store.dispatch("getProformasList", { Page: 1, PageSize: 999999, SearchTerms: null });
          this.proforma = null;
          this.close();
        }
        this.loading = false;
      }, 500);
    },
    updateAlternativePrint(index) {
      this.proforma.Products[index].Print = this.selectedPrint;
      this.selectedPrint = null;
    },
    async readyProforma() {

      this.checkNull()

      console.log(this.proforma.CurrentCompanyInformation)
      this.$store.commit("setProformaValidation", []);
      if (this.validation.length > 0) {
        return;
      }
      this.loading = "readyLoading";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var res = await this.$store.dispatch("readyProformas", {
          ProformaId: this.proforma.ID,
          Products: this.proforma.Products,
          Note: this.proforma.Note,
          Terms: this.proforma.Terms.map((x)=> x.Text),
          CurrentCompanyInformation: this.proforma.CurrentCompanyInformation,
          LastPaymentText: this.proforma.LastPaymentText,
          BeforeProductionBalans: this.proforma.BeforeProductionBalans,
          // Originations:this.proforma.Originations,
          Discount:this.proforma.Discount,
          DeliveryTime:this.proforma.DeliveryTime== "" ? null : this.proforma.DeliveryTime
        });
        if (res.HasError) {
          this.$message.error(res.Message);
        } else {
          this.$message.success(res.Message);
          // await this.$store.dispatch("getProductSamplesList", { Page: 1, PageSize: 999999, SearchTerm: null });
          await this.$store.dispatch("getProformasList", { Page: 1, PageSize: 999999, SearchTerms: null });
          // this.proforma = null;
           this.close();
        }
       
        this.loading = false;
      }, 500);
    },
    // changeProforma(id) {
    //   var cust = this.allCustomers.find((x) => x.ID == id);
    //   this.proforma.Customer.FirstName = cust.FirstName;
    //   this.proforma.Customer.CompanyName = cust.CompanyName;
    //   this.proforma.Products = cust.Products;
    //   this.proforma.Customer.LastName = cust.LastName;
    //   this.cId = cust.AccountNo != null ? cust.AccountNo : cust.LeadId;
    //   this.proforma.Customer.AccountNo = cust.AccountNo != null ? cust.AccountNo : null;
    //   this.getSampleList();
    // },
    async changeProforma(id) {
      var cust = this.allCustomers.find((x) => x.ID == id);
      this.proforma.Customer.FirstName = cust.FirstName;
      this.proforma.Customer.CompanyName = cust.CompanyName;
      this.proforma.Products = cust.Products;
      this.proforma.Customer.LastName = cust.LastName;
      this.cId = cust.AccountNo != null ? cust.AccountNo : cust.LeadId;
      this.proforma.Customer.AccountNo = cust.AccountNo != null ? cust.AccountNo : null;
      this.getSampleList();
      //console.log(id) 
      
      this.proforma.Customer.CurrentCompanyInformations=cust.CurrentCompanyInformations
      this.proforma.CurrentCompanyInformation.ID=null;
    },
    async changeBillingInfosOnCreateProforma(id){
      var a = this.proforma.Customer.CurrentCompanyInformations.find((x)=>x.ID==id);
      console.log(a)
      this.proforma.CurrentCompanyInformation.RegisteredCompanyName=a.RegisteredCompanyName;
      this.proforma.CurrentCompanyInformation.RegisteredAddress=a.RegisteredAddress;
      this.proforma.CurrentCompanyInformation.CompanyRegistrationNo=a.CompanyRegistrationNo;
      this.proforma.CurrentCompanyInformation.RegisteredCityId=a.RegisteredCityId;
      this.proforma.CurrentCompanyInformation.RegisteredCountryId=a.RegisteredCountryId;
      this.proforma.CurrentCompanyInformation.RegisteredPostalCode=a.RegisteredPostalCode;
      console.log(this.proforma)
    },
    async changeBillingInfos(id){
      var a =this.proforma.Customer.CurrentCompanyInformations.find((x)=>x.ID==id);
      console.log(this.proforma.Customer.CurrentCompanyInformations.find((x)=>x.ID==id))
      this.proforma.CurrentCompanyInformation.RegisteredCompanyName=a.RegisteredCompanyName;
      this.proforma.CurrentCompanyInformation.RegisteredAddress=a.RegisteredAddress;
      this.proforma.CurrentCompanyInformation.CompanyRegistrationNo=a.CompanyRegistrationNo;
      this.proforma.CurrentCompanyInformation.RegisteredCityId=a.RegisteredCityId;
      this.proforma.CurrentCompanyInformation.RegisteredCountryId=a.RegisteredCountryId;
      this.proforma.CurrentCompanyInformation.RegisteredPostalCode=a.RegisteredPostalCode;
      //this.proforma.CurrentCompanyInformation.ID=a.ID;
      console.log(this.proforma.CurrentCompanyInformation)
    },
    async checkedId() {
      if (this.id != undefined && this.id != null) {
        await this.getProforma();
        await this.setReason();
      } else {
        this.proforma = JSON.parse(JSON.stringify(this.proformaForm));
      }
    },
    async confirm() {

      this.checkNull()

      if (this.validation.length > 0) {
        return;
      }
      this.loading = "confirmLoading";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var res = await this.$store.dispatch("confirmProforma", this.proforma);
        if (!res.HasError) {
          await this.getSampleList();
          await this.$store.dispatch("getProformasList", { Page: 1, PageSize: 999999, SearchTerms: null });
          this.close();
        }
        this.loading = false;
      }, 500);
    },
    seePrewiev(id,proformaNo) {
      var token = this.$cookies.get("token");
      this.loading = "exportLoading" + proformaNo;
      // if (this.timeout != null) clearTimeout(this.timeout);
      // this.timeout = setTimeout(async () => {
      //   var token = this.$cookies.get("token")
      //   window.open(`https://pdf.imageus.dev/?url=https://portal.packagingsource.co.uk/proforma.html?id=${id}=proforma-no=${proformaNo}=idtoken-seperator=${token}`);
      //   this.loading = false;
      // }, 500);
      this.$client.get("/Proforma/ExportPdfWithSejda", { responseType: "blob", params: { id: id, token:token, proformaNo:proformaNo} }).then((response) => {
          console.log(response)
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.target="_blank";
          link.href = URL.createObjectURL(blob);
          link.click();
          URL.revokeObjectURL(link.href);
          this.loading = false;
      });
    },
    createNewAlternativeValues(index) {
      this.proforma.Products[index].UnitPriceQuantityAlternatives.push({
        AlternativeUnitPrice: 0,
        AlternativeQuantity: 0,
      });
    },
    deleteAlternativeValues(index, alternativeIndex) {
      this.proforma.Products[index].UnitPriceQuantityAlternatives.splice(alternativeIndex, 1);
    },
    chooseAlternative(index, indexAlternative) {
      this.proforma.Products[index].Quantity = this.proforma.Products[index].UnitPriceQuantityAlternatives[indexAlternative].AlternativeQuantity;
      this.proforma.Products[index].UnitPrice = this.proforma.Products[index].UnitPriceQuantityAlternatives[indexAlternative].AlternativeUnitPrice;
    },

    addTerms() {
      this.proforma.Terms.push("");
    },
    addDiscount() {
      this.proforma.Discount="";
    },
    addOrigination(index) {
      console.log(this.proforma.Products)
      this.proforma.Products[index].Originations.push({
        UnitPrice:0,
        Quantity:0,
      });
    },
    deleteOrigination(index,originationIndex) {
      this.proforma.Products[index].Originations.splice(originationIndex, 1);
      // this.proforma.Originations.splice(index, 1);
    },
    deleteDiscount() {
      this.proforma.Discount=null;
    },
    addPayment() {
      this.proforma.BeforeProductionBalans.push("");
    },
    deletePayment(index) {
      this.proforma.BeforeProductionBalans.splice(index, 1);
    },
    deleteTerms(index) {
      this.proforma.Terms.splice(index, 1);
    },

    createNewAlternativePrint(index) {
      this.proforma.Products[index].PrintAlternatives.push({
        AlternativePrint: null,
      });
    },
    deleteAlternativePrint(index, alternativePrintIndex) {
      this.proforma.Products[index].PrintAlternatives.splice(alternativePrintIndex, 1);
    },
    createNewAlternativeQuality(index) {
      this.proforma.Products[index].QualityAlternatives.push({
        AlternativeQuality: null,
      });
    },
    deleteAlternativeQuality(index, alternativeQualityIndex) {
      this.proforma.Products[index].QualityAlternatives.splice(alternativeQualityIndex, 1);
    },
    // updateAlternative(index) {
    //   this.proforma.Products[index].Quantity = this.proforma.Products[index].UnitPriceQuantityAlternatives[this.selected].AlternativeQuantity;
    //   this.proforma.Products[index].UnitPrice = this.proforma.Products[index].UnitPriceQuantityAlternatives[this.selected].AlternativeUnitPrice;
    // },
    // updateAlternativeQuality(index) {
    //   this.proforma.Products[index].Quality = this.selectedQuality;
    // },
    emptyIfNull(val) {
      return val || "";
    },
    createDescription(index) {
      var x;
      if (this.proforma.Products[index].Unit == 0) {
        x = '"';
      } else if (this.proforma.Products[index].Unit == 1) {
        x = "oz";
      } else if (this.proforma.Products[index].Unit == 2) {
        x = "";
      } else if (this.proforma.Products[index].Unit == 3) {
        x = "cc";
      } else {
        x = "";
      }
      if (x != "") {
        x = x + " ";
      }
      if (this.proforma.Products[index].Diameter) {
        this.proforma.Products[index].Description = "Bespoke " + this.emptyIfNull(this.proforma.Products[index].DiameterValue) + x + this.proforma.Products[index].Title;
      } else {
        this.proforma.Products[index].Description = "Bespoke " + this.emptyIfNull(this.proforma.Products[index].Width) + "x" + this.emptyIfNull(this.proforma.Products[index].Length) + (this.proforma.Products[index].Height ? "x" + this.proforma.Products[index].Height : "") + "cm " + this.proforma.Products[index].Title;
      }
    },
    exportProforma() {
      if(this.proforma.CurrentCompanyInformation.ID==null){
        this.validation.push({
          Key: "SeePreview",
          Value: "",
        });
        this.$message.error("You should save company detail");
      }
      
      if (this.validation.length > 0) {
        return;
      }

      this.loading = "viewProforma"
      var id = this.id;
      var token = this.$cookies.get("token");
      // window.open(`https://pdf.imageus.dev/?url=https://portal.packagingsource.co.uk/proforma.html?id=${id}=idtoken-seperator=${token}`);

      this.$client.get("/Proforma/ExportPdfWithSejda", { responseType: "blob", params: { id: id,token:token } }).then((response) => {
          console.log(response)
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.target="_blank";
          link.href = URL.createObjectURL(blob);
          link.click();
          URL.revokeObjectURL(link.href);
          this.loading = false;
      });
    },

    async getProforma() {
      var res = await this.$store.dispatch("getOneProforma", this.id);
      this.proforma = res;
      var terms=[]
      res.Terms.forEach(element => {
        var number=parseInt(element.match(/\d+/) != null ? element.match(/\d+/)[0]:null)
        if( number== this.proforma.DeliveryTime
        || element=="The above unit prices include delivery and first delivery may take up to 6-8 weeks this production." 
        || element=="The above unit prices include direct delivery and first delivery may take up to 6-8 weeks this production." 
        || element=="The above unit prices include bulk direct delivery and first delivery may take up to 6-8 weeks this production."
        || element=="Production and first delivery process for this order may take up to 6-8 weeks."
        || element=="Production and first delivery process for this order may take up to 8 weeks."){
          terms.push({Text:element,IsDeliveryTime:true})
        }else{
          terms.push({Text:element,IsDeliveryTime:false})
        }
      });
      this.proforma.Terms=terms
    },
    close(id) {
      this.$emit("close", id);
    },
    createNewProduct() {
      this.proforma.Products.push({
        ProductNo: null,
        Diameter: false,
        DiameterValue: 0,
        Description:null,
        Width: 0,
        Length: 0,
        Height: 0,
        Print: null,
        Quality: null,
        UnitPrice: 0,
        Quantity: 0,
        Title: null,
        ID: null,
        UnitPriceQuantityAlternatives: [],
        PrintAlternatives: [],
        QualityAlternatives: [],
        Originations:[]
      });
    },
    resetDiameter(i) {
      this.proforma.Products[i].DiameterValue = null;
      this.proforma.Products[i].Width = 0;
      this.proforma.Products[i].Height = 0;
      this.proforma.Products[i].Length = 0;
    },
    deleteNewProduct(index) {
      this.proforma.Products.splice(index, 1);
    },
  },
  computed: {
    canUpdate() {
      return this.updatable;
    },
    user() {
      return this.$store.getters.getUser;
    },
    allCustomers() {
      var allCustomers = this.customerList.concat(this.potentialCustomersList);
      if(this.user.Role==1)
        allCustomers= allCustomers.filter((x)=>x.AccountManagerId==this.user.ID)
        return allCustomers;
    },
    potentialCustomersList() {
       return this.$store.getters.getPotentialCustomersList;
    },
    getEnums() {
      return this.$store.getters.getEnums;
    },
    getProformasList() {
      return this.$store.getters.getProformasList;
    },
    customerList() {
      return this.$store.getters.getCustomersList;
    },
    proformaForm() {
      return this.$store.getters.getProformaForm;
    },
    sampleProductList() {
      return this.$store.getters.getProductSamplesList;
    },
    productForm() {
      return this.$store.getters.getProductsForm;
    },
    validation() {
      return this.$store.getters.getProformasValidationErrors;
    },
    getAllCountries() {
      return this.$store.getters.getAllCountries;
    },
  },
  watch: {
    // async id() {
    //   await this.checkedId();
    // },
    proforma: {
      handler(newVal) {
        if(newVal.Products.length > 0){
          this.paymentTotal()
          this.paymentVAT()
          this.paymentGrandTotal()
        }
        
        if(this.proforma.Note===""){
          this.proforma.Note=null
        }
        if (JSON.stringify(newVal) !== JSON.stringify(this.originalProforma)) {
          this.updatable = true;
        } else {
          this.updatable = false;
        }
      },
      deep: true
    }
  },
};
</script>

<style></style>
