export default {
  getLoginForm: (state) => state.loginForm,
  getAuthValidationErrors: (state) => state.validationErrors,
  getUser: (state) => state.user,
  getEnums: (state) => state.enums,
  getToken: (state) => state.token,
  getAllCountries: (state) => state.allCountries,
  // getCountries: (state) => state.countries,
  // getCities: (state) => state.cities,
  getIndustries: (state) => state.industries,
  getAgents: (state) => state.agents,
  getInterestProducts: (state) => state.interestProducts,
  getNotification: (state) => state.notification,
  getNotifyFilter: (state) => state.notifyFilter,
};
