export default {
  setAgentList(state, data) {
    state.AgentList = data;
  },
  setAgentValidation(state, data) {
    state.agentValidation = data;
  },
  setAgentOne(state, data) {
    state.Agent = data;
  },
};
