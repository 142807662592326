import { client } from "@/helpers/axiosHelper";
import router from "../../router";
import { Message, MessageBox } from "element-ui";
import cookie from 'vue-cookies'
export default {
  async login(context) {
    context.commit("setValidationErrors", []);
    var res = await client.post("/Login/SignIn", context.state.loginForm);
    context.commit("setValidationErrors", res.data.ValidationErrors);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setToken",res.data.Data);
      cookie.set("token", res.data.Data)
      router.push({ name: "ReportsStatsHome" });
    }
  },
  async getUser(context) {
    var res = await client.post("/Account/Get");
    if (res.data.HasError) {
      Message.error(res.data.Message);
      if(res.data.Data===null){
        cookie.remove("token")
        router.push("/login");
      }
    } else {
      console.log(res)
      context.commit("setUser", res.data.Data);
      context.dispatch("getEnums");
    }
  },
  async updatePlayerId(store, payload) {
    await client.post("/Account/UpdatePlayerId", { PlayerId: payload });
  },
  async getEnums(context) {
    var res = await client.get("/Definition/GetEnums");
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setEnums", res.data.Data.Items);
    }
  },
  async getAllCountries(context) {
    var res = await client.get("/Definition/Countries");
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setAllCountries", res.data.Data.Items);
    }
  },
  // async getCountries(context) {
  //   var res = await client.post("/Definition/GetCountries");
  //   if (res.data.HasError) {
  //     Message.error(res.data.Message);
  //   } else {
  //     context.commit("setCountries", res.data.Data.Items);
  //   }
  // },
  // async getCities(context,payload) {
  //   var res = await client.post("/Definition/GetCities", {CountryId:payload});
  //   if (res.data.HasError) {
  //     Message.error(res.data.Message);
  //   } else {
  //     context.commit("setCities", res.data.Data.Items);
  //   }
  // },
  async getIndustries(context) {
    var res = await client.get("/Definition/GetIndustries");
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setIndustries", res.data.Data.Items);
    }
  },
  async getAgents(context) {
    var res = await client.get("/Definition/GetAgents");
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setAgents", res.data.Data.Items);
    }
  },
  async getInterestProducts(context) {
    var res = await client.get("/Definition/InterestProducts");
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setInterestProducts", res.data.Data.Items);
    }
  },
  async getNotification(context, payload) {
    var res = await client.post("/Notification/List", payload);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setNotification", res.data.Data.Items);
    }
  },

  async removeNotification(context, payload) {
    var res = await client.post("/Notification/Remove", { NotificationId: payload });
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      Message.success(res.data.Message);
      await context.dispatch("getNotification", context.state.notifyFilter);
    }
  },
  async removeAllNotification(context) {
    MessageBox.confirm("Are you sure for delete all notifications?", {
      confirmButtonText: "Delete All",
      confirmButtonClass: "danger-btn-confirm",
      cancelButtonText: "Back",
      type: "warning",
      title: "Warning",
    })
      .then(async () => {
        var res = await client.post("/Notification/RemoveAll", {});
        if (res.data.HasError) {
          Message.error(res.data.Message);
        } else {
          Message.success(res.data.Message);
          await context.dispatch("getNotification", context.state.notifyFilter);
        }
      })
      .catch(() => {});
  },
  async markAsReadNotification(context, payload) {
    var res = await client.post("/Notification/MarkAsRead", { NotificationId: payload });
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      // Message.success(res.data.Message);
      await context.dispatch("getNotification", context.state.notifyFilter);
    }
  },
  async markAllAsReadNotification(context) {

    MessageBox.confirm("Are you sure for mark all as read notifications?", {
      confirmButtonText: "Read All",
      confirmButtonClass: "danger-btn-confirm",
      cancelButtonText: "Back",
      type: "warning",
      title: "Warning",
    })
      .then(async () => {
        var res = await client.post("/Notification/MarkAllAsRead", {});
        if (res.data.HasError) {
          Message.error(res.data.Message);
        } else {
          Message.success(res.data.Message);
          await context.dispatch("getNotification", context.state.notifyFilter);
        }
      })
      .catch(() => {});


  },
};
