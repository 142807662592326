import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../components/Layout.vue";
import store from "../store/index";
import PCList from "../views/PotentialCustomer/List.vue";
import Quatations from "../views/PotentialCustomer/Quotations.vue";
import Proformas from "../views/PotentialCustomer/Proformas.vue";
import Login from "../views/Auth/Login.vue";
import ReportStats from "../views/ReportsStats/Index.vue";
import { client } from "../helpers/axiosHelper";
import cookie from 'vue-cookies'
Vue.use(VueRouter);
function potentialCustomerGuard(to, from, next) {
  const user = store.getters.getUser;
  if (user.Role == 1 || user.Role == 10 || user.Role == 5) {
    next();
  } else {
    next({ name: "ReportsStatsHome" });
  }
}
function operationsGuard(to, from, next) {
  const user = store.getters.getUser;
  if (user.Role == 10 || user.Role == 3 || user.Role == 4 || user.Role == 5 || user.Role == 6) {
    next();
  } else {
    next({ name: "ReportsStatsHome" });
  }
}
function suppliersGuard(to, from, next) {
  const user = store.getters.getUser;
  if (user.Role == 10 || user.Role == 3 || user.Role == 5) {
    next();
  } else {
    next({ name: "ReportsStatsHome" });
  }
}
function projectsGuard(to, from, next) {
  const user = store.getters.getUser;
  if (user.Role == 10 || user.Role == 3 || user.Role == 2 || user.Role == 5 || user.Role == 6) {
    next();
  } else {
    next({ name: "ReportsStatsHome" });
  }
}
const routes = [
  {
    path: "/",
    name: "Home",
    component: Login,
  },
  {
    path: "/Components",
    name: "Components",
    component: () => import("@/views/Components.vue"),
  },
  {
    path: "/reports-stats",
    name: "ReportsStats",
    component: Layout,
    children: [
      {
        path: "/",
        name: "ReportsStatsHome",
        component: ReportStats,
      },
    ],
  },
  {
    path: "/search",
    name: "Search",
    component: Layout,
    children: [
      {
        path: "/",
        name: "SearchHome",
        component: () => import("@/views/Search.vue"),
      },
    ],
  },
  {
    path: "/potential-customers",
    name: "PotentialCustomer",
    component: Layout,
    children: [
      {
        path: "list",
        name: "PotentialCustomerHome",
        component: PCList,
      },
      {
        path: "quatations",
        name: "PotentialCustomerQuatations",
        component: Quatations,
      },
      {
        path: "proformas",
        name: "PotentialCustomerProformas",
        component: Proformas,
      },
    ],
    beforeEnter: potentialCustomerGuard,
  },
  {
    path: "/customers",
    name: "Customers",
    component: Layout,
    children: [
      {
        path: "/",
        name: "CustomersHome",
        component: () => import("@/views/Customers/Index.vue"),
      },
    ],
    beforeEnter: potentialCustomerGuard,
  },

  // {
  //   path: "/projects",
  //   name: "Projects",
  //   component: Layout,
  //   children: [
  //     {
  //       path: "/",
  //       name: "ProjectsHome",
  //       component: () => import("@/views/Projects/Index.vue"),
  //     },
  //   ],
  //   beforeEnter: projectsGuard,
  // },
  
  {
    path: "/projects",
    name: "Projects",
    component: Layout,
    children: [
      {
        path: "ongoing",
        name: "Ongoing Projects",
        component: () => import("@/views/Projects/Index.vue"),
      },
      {
        path: "Completed",
        name: "Completed Projects",
        component: () => import("@/views/Projects/CompletedProjects.vue"),
      }
    ],
    beforeEnter: projectsGuard,
  },

  {
    path: "/users",
    name: "Users",
    component: Layout,
    children: [
      {
        path: "/",
        name: "UsersHome",
        component: () => import("@/views/Users/Index.vue"),
      },
    ],
  },
  {
    path: "/orders",
    name: "Orders",
    component: Layout,
    children: [
      {
        path: "/",
        name: "OrdersHome",
        component: () => import("@/views/Orders/Index.vue"),
      },
    ],
    beforeEnter: operationsGuard,
  },
  {
    path: "/suppliers",
    name: "Suppliers",
    component: Layout,
    children: [
      {
        path: "/",
        name: "SuppliersHome",
        component: () => import("@/views/Suppliers/Index.vue"),
      },
    ],
    beforeEnter: suppliersGuard,
  },
  {
    path: "/warehouse",
    name: "Warehouse",
    component: Layout,
    children: [
      {
        path: "list",
        name: "WarehouseList",
        component: () => import("@/views/Warehouse/WarehouseList.vue"),
      },
      {
        path: "products",
        name: "Products",
        component: () => import("@/views/Warehouse/Products.vue"),
      },
    ],
    beforeEnter: operationsGuard,
  },
  {
    path: "/transportation",
    name: "transportation",
    component: Layout,
    children: [
      {
        path: "/",
        name: "TransfersHome",
        component: () => import("@/views/Transfers/Index.vue"),
      },
    ],
    beforeEnter: operationsGuard,
  },
  {
    path: "/Account",
    name: "MyAccount",
    component: Layout,
    children: [
      {
        path: "/my-account",
        name: "MyAccountHome",
        component: () => import("@/views/Account/MyAccount.vue"),
      },
      {
        path: "/change-password",
        name: "MyAccountChangePassword",
        component: () => import("@/views/Account/ChangePassword.vue"),
      },
    ],
  },
  {
    path: "/agentlist",
    name: "AgentList",
    component: Layout,
    children: [
      {
        path: "/",
        name: "AgentListHome",
        component: () => import("@/views/Warehouse/AgentList.vue"),
      },
    ],
    beforeEnter: operationsGuard,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  var token = cookie.get("token")
  if (token != null) {
    client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await store.dispatch("getUser");
    await store.dispatch("getEnums");
    await store.dispatch("getNotification", { Page: 1, PageSize: 999999, SearchTerm: null });

    if (to.name !== "Home") {
      next();
    } else {
      next({ name: "ReportsStatsHome" });
    }
  } else {
    if (to.name == "Home") {
      next();
    } else {
      next({ name: "Home" });
    }
  }
});

export default router;
