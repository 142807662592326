export default {
    setCustomersList(state, data) {
        state.customersList = data;
    },
    setPotentialCustomerOne(state, data) {
        state.customer = data;
    },
    setCustomerValidation(state, data) {
        state.customerValidation = data;
    }
}