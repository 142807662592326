import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  customersList: [],
  customer: {},
  customerFilter: {
    SearchTerm: null,
    StartDate: null,
    EndDate: null,
    CountryId: 2635167,
    CityId: null,
    Channel: null,
    AccountNumber: null,
    InterestedProductIds: [],
    CompanyName: null,
    LeadId: null,
    Page: 1,
    PageSize: 200,
    IndustryId: null,
    SortBy: null,
    SortDirection: 1,
  },
  customersTotal: {
    PageCount: null,
    TotalCount: null,
  },
  customerForm: {
    PotentialCustomerId: null,
    ID: null,
    AccountNo: null,
    FirstName: null,
    LastName: null,
    CompanyName: null,
    CityId: null,
    CountryId: 2635167,
    PostalCode: null,
    ContactNumber: null,
    Email: null,
    Address: null,
    Channel: null,
    Notes: [],
    Documents: [],
    InterestedProductIds: [],
    ProductManagerId: null,
    Products: [],
    Calls:[],
    Samples:[],
    CurrentCompanyInformations:[],
  },
  customerValidation: [],
};

export default {
  state,
  getters,
  actions,
  mutations,
};
