import { client } from "../../helpers/axiosHelper";
import { Message } from "element-ui";
export default {
    async getProjectsList(context, payload) {
        console.log("asddasd")
        var res;
        if (payload) {
            res = await client.post("/Job/List", payload);
        } else {
            res = await client.post("/Job/List", context.state.projectFilter);
        }
        console.log(res);
        if (res.data.HasError) {
            Message.error(res.data.Message);
        } else {
            context.commit("setProjectsList", res.data.Data.Items);
            context.state.projectTotal.TotalCount = res.data.Data.TotalCount;
            context.state.projectTotal.PageCount = res.data.Data.PageCount;
            context.state.projectTotal.PageCount = res.data.Data.SearchTerm;
        }
    },
    async getCompletedProjectsList(context, payload) {
        var res;
        if (payload) {
            res = await client.post("/Job/ListCompleted", payload);
        } else {
            res = await client.post("/Job/ListCompleted", context.state.projectFilter);
        }
        if (res.data.HasError) {
            Message.error(res.data.Message);
        } else {
            context.commit("setCompletedProjectsList", res.data.Data.Items);
            context.state.projectTotal.TotalCount = res.data.Data.TotalCount;
            context.state.projectTotal.PageCount = res.data.Data.PageCount;
            context.state.projectTotal.PageCount = res.data.Data.SearchTerm;
        }
    },
    async addUpdateProject(context, payload) {
        var res = await client.post("/Job/AddJob", payload);
        context.commit("setProjectValidation", res.data.ValidationErrors);
        if (res.data.HasError) {
            Message.error(res.data.Message);
        } else {
            Message.success(res.data.Message);
        }
        return res.data;
    },
    async getOneProject(context, payload) {
        var res = await client.post('/Job/Get', { Id: payload });
        console.log(res);
        if (res.data.HasError) {
            Message.error(res.data.Message)
        } else {

            context.commit('setProjectOne', res.data.Data);
            return res.data.Data;
        }
    },

    // async deleteOne(context, payload) {
    //     // silinecek potential customer idsi gönderilicek string olarak
    //     var res = client.post("/Supplier/Delete", { Id: payload });
    //     if (res.data.HasError) {
    //         Message.error(res.data.Message);
    //     } else {
    //         Message.success(res.data.Message);
    //         // TODO - Silinecek Account Listeden Kaldırılıcak mutations ile yapılacak. dispatch atılmayacak tekrar.
    //     }
    // },
};