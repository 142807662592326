<template>
  <div class="dialog-container">
    <transition name="el-fade-in" mode="out-in">
      <el-row style="display: flex; align-items: center; justify-content: center; width: 100%" v-if="loading == 'addUpdateScreen' && quatForm == null" key="screen-loading">
        <lottie-player autoplay loop mode="normal" src="/lottie/packaging-source-loading-lottie.json" style="width: 320px"></lottie-player>
      </el-row>
      <el-row v-else key="content-loading">
        <div class="dialog-title">
          <div class="head-title">{{ quatForm.ID != null ? "Offer" : "Create New Offer" }}<span class="yellow-dot">.</span>{{ quatForm.ID != null ? "  #" + quatForm.OfferNo : "" }}</div>
          <el-button class="btn close" type="danger" @click="close"> <i class="icon-xmark-solid"></i></el-button>
        </div>
        <div class="form">
          <el-row :gutter="24">
            <el-col :sm="12" class="mini-form">
              <el-col class="input-container" :sm="24">
                <div class="text">Customer Name</div>
                <el-select v-model="quatForm.CustomerId" filterable placeholder="Select" @change="changeQuat" clearable :disabled="quatForm.ID != null">
                  <el-option v-for="item in allCustomers" :key="item.ID" :label="item.CompanyName" :value="item.ID"> </el-option>
                </el-select>
                <span class="error-validation">{{ $errorMessage("CustomerId", validation) }}</span>
              </el-col>
              <el-col class="input-container" :sm="24" v-if="quatForm.CustomerId != null">
                <div class="text">Interested Products</div>
                <el-select v-model="quatForm.Customer.InterestedProductIds"  filterable remote multiple placeholder="There is no interested product" disabled>
                  <el-option v-for="product in getInterestProductsList" :key="product.ID" :value="product.ID" :label="product.Title"> </el-option>
                </el-select>
                <span class="error-validation">{{ $errorMessage("InterestedProductIds", validation) }}</span>
              </el-col>
            </el-col>
            <el-col :sm="12" class="right-card" v-if="quatForm.CustomerId != null">
              <div class="inline-form">
                <div class="input-group" v-if="quatForm.CreatedDateTime">
                  <div class="left">Date:</div>
                  <div class="right">{{ $moment(quatForm.CreatedDateTime).format("DD-MM-YYYY") }}</div>
                </div>
                <div class="input-group">
                  <div class="left">Customer Name:</div>
                  <div class="right" v-if="quatForm.CustomerId">{{ quatForm.Customer ? quatForm.Customer.CompanyName : quatForm.CompanyName }}</div>
                </div>
                <div class="input-group" v-if="quatForm.Customer != null">
                  <div class="left" v-if="quatForm.Customer.CustomerType == 0">Lead ID:</div>
                  <div class="left" v-if="quatForm.Customer.CustomerType == 1">Customer ID:</div>
                  <div class="right">{{ quatForm.Customer.CustomerType == 0 ? quatForm.Customer.LeadId : quatForm.Customer.AccountNo }}</div>
                </div>
                <div class="input-group" v-else>
                  <div class="left" v-if="isPotential == true">Lead ID:</div>
                  <div class="left" v-if="isPotential == false">Customer ID:</div>
                  <div class="right">{{ quatForm.Customer ? quatForm.Customer.AccountNo : cId }}</div>
                </div>
                <div class="input-group">
                  <div class="left">Product:</div>
                  <div class="right" v-if="quatForm.Products">
                    <template v-if="quatForm.Products.length != 0">
                      <div v-for="(item, index) in quatForm.Products" :key="index">{{ item.Description }}</div>
                    </template>
                    <template v-if="quatForm.Products.length == 0">
                      <div>-</div>
                    </template>
                  </div>
                </div>
                <div v-for="(reason,index) in reasons" :key="index" :value="reason.reasonText" :label="reason.reasonText">
                  <div class="input-group" :style="reason.reasonColor">
                    <div class="left" >{{ reason.reasonType }} Reason:</div>
                    <div class="right">{{ reason.reasonText}}</div>
                  </div>
                </div>
                
              </div>
            </el-col>
            <el-col :sm="12" class="right-card" >
              <div></div>
            </el-col>
          </el-row>
          <div class="form" style="margin-top: 0px">
            <div class="line"></div>
            <div class="title">Product Information<span class="yellow-dot">.</span></div>
            <template v-if="quatForm.Products.length != 0">
              <div class="product-inf-component">
                <el-collapse accordion>
                  <el-collapse-item v-for="(item, index) in quatForm.Products" :key="index">
                    <template slot="title">
                      <div class="row-title">
                        <div>                          
                          ITEM {{ index + 1 }} <span class="name"> {{ item.Description }} </span>
                          <span class="name" v-if="item.UnitPriceQuantityAlternatives.length > 1"> There are alternative quantities and unit prices </span>
                        </div>
                        <div class="icon">
                          <div class="icon-row-trash" @click="deleteNewProduct(index)"></div>
                        </div>
                      </div>
                    </template>
                    <div class="form">
                      <el-row v-if="item.ID == null ">
                        <div class="input-container-row">
                          <el-switch v-model="item.SelectType" active-text="Product Name" inactive-text="Select Products"> </el-switch>
                          {{ item.ID  }}
                        </div>
                      </el-row>
                      <el-row :gutter="24">
                        <el-col :span="8" class="input-container" v-if="!item.SelectType">
                          <div class="text">Select Product</div>
                          <el-select placeholder="Select Product" filterable  v-if="sampleProductList" v-model="quatForm.Products[index]" value-key="ID" @change="createDescription(index)">
                            <el-option v-for="product in sampleProductList" :key="product.ID" :value="product" :label="product.Description"></el-option>
                          </el-select>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Title", validation) }}</span>
                        </el-col>

                        <el-col :sm="8" class="input-container" v-if="item.SelectType">
                          <div class="text">Products Name</div>
                          <el-input v-model="item.Title" @change="createDescription(index)" placeholder="..." ></el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Title", validation) }}</span>
                        </el-col>
                        <el-col :sm="16" class="input-container">
                          <div class="text">Description</div>
                          <el-input  v-model="item.Description" placeholder="..."></el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Title", validation) }}</span>
                        </el-col>
                      </el-row>
                      <el-switch @change="resetDiameter(index)" v-model="item.Diameter" inactive-text="W-L-H" active-text="Other"></el-switch>
                      <el-row :gutter="24" v-if="!item.Diameter">
                        <el-col class="input-container" :sm="8">
                          <div class="text">Width</div>
                          <el-input v-model="item.Width" placeholder="..." v-numeric @change="createDescription(index)" @input="validSizes()" >
                            <div slot="append">CM</div>
                          </el-input>
                          <span class="error-validation">{{ $errorMessage("Regex", validation) }}</span>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Width", validation) }}</span>
                        </el-col>
                        <el-col class="input-container" :sm="8">
                          <div class="text">Length</div>
                          <el-input v-model="item.Length" placeholder="..." v-numeric @change="createDescription(index)" >
                            <div slot="append">CM</div>
                          </el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Length", validation) }}</span>
                        </el-col>
                        <el-col class="input-container" :sm="8">
                          <div class="text">Height</div>
                          <el-input v-model="item.Height" placeholder="..."  v-numeric @change="createDescription(index)">
                            <div slot="append">CM</div>
                          </el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Height", validation) }}</span>
                        </el-col>
                      </el-row>
                      <el-row :gutter="24" v-if="item.Diameter">
                        <el-col class="input-container" :sm="8">
                          <div class="text">Other</div>
                          <el-input placeholder="..." class="input-with-select" v-model="item.DiameterValue" v-numeric @change="createDescription(index)">
                            <el-select v-model="item.Unit" slot="append" class="append-diameter" placeholder="..." clearable @change="createDescription(index)" @clear="item.Unit == null">
                              <el-option v-for="(item, index) in getEnums.find((x) => x.Title == 'Unit').Enums" :key="item.Key + index" :label="item.DisplayName" :value="item.Key"></el-option>
                            </el-select>
                          </el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].DiameterValue", validation) }}</span>
                        </el-col>
                      </el-row>

                      <el-row :gutter="24">
                        <el-col class="input-container" :sm="8">
                          <div class="text">Print</div>
                          <el-input v-model="item.Print" placeholder="..." class="select"> </el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Print", validation) }}</span>
                        </el-col>
                        <el-col class="input-container" :sm="8">
                          <div class="text">Alternative Print Options</div>
                          <el-select v-model="selectedPrint" @change="updateAlternativePrint(index)" placeholder="Select" class="select">
                            <el-option v-for="(alternativePrints, alternativePrintIndex) in PrintAlternatives" :key="alternativePrintIndex + '-print'" :label="alternativePrints.AlternativePrint" :value="alternativePrints.AlternativePrint"> </el-option>
                          </el-select>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Print", validation) }}</span>
                        </el-col>
                      </el-row>
                      <el-row :gutter="24">
                        <el-col class="input-container" :sm="8">
                          <div class="text">Quality</div>
                          <el-input v-model="item.Quality" placeholder="..." class="select"> </el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Quality", validation) }}</span>
                        </el-col>
                      </el-row>
                      <el-row :gutter="24">
                        <el-col class="input-container" :sm="8">
                          <div class="text">Type</div>
                          <el-input v-model="item.Type" placeholder="..." class="select"> </el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Type", validation) }}</span>
                        </el-col>
                      </el-row>

                      <div class="line"></div>

                      <el-row :gutter="24">
                        <el-col class="input-container-row" :sm="24">
                          <el-col :span="8"> <div class="text" >Unit Price</div> </el-col>
                          <el-col :span="16">
                            <el-input v-model="item.UnitPrice" placeholder="..." v-numeric  @change="sortByQuantity"></el-input>
                            <span class="error-validation">{{ $errorMessage("Number", validation) }}</span>
                            <span class="error-validation">{{ $errorMessage("Products[" + index + "].UnitPrice", validation) }}</span>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-row :gutter="24">
                        <el-col class="input-container-row" :sm="24">
                          <el-col :span="8"> <div class="text">Quantity</div> </el-col>
                          <el-col :span="16">
                            <el-input v-model="item.Quantity" placeholder="..."  v-numeric @change="sortByQuantity">
                            </el-input>
                            <span class="error-validation">{{ $errorMessage("Products[" + index + "].Quantity", validation) }}</span>
                          </el-col>
                        </el-col>
                      </el-row>
                      <template>
                        <!-- <div class="title" v-if="item.UnitPriceQuantityAlternatives.length > 1">Alternative Unit Price and Quantity Informations<span class="yellow-dot">.</span></div> -->
                        <div v-for="(alternatives, alternativeIndex) in item.UnitPriceQuantityAlternatives.slice(1)" :key="alternativeIndex + 'unit-price-quantity'" class="mini-form">
                          <el-row :gutter="24">
                            <el-col :sm="24">
                              <el-col :span="24" class="input-container-row">
                                <div class="text">Alternative {{ alternativeIndex + 1 }}</div>
                                <div class="icon-row-trash" style="cursor: pointer; margin-left: 16px" @click="deleteAlternativeValues(index, alternatives)"></div>
                              </el-col>
                            </el-col>
                          </el-row>

                          <el-row :gutter="24">
                            <el-col class="input-container-row" :sm="24">
                              <el-col :span="8"> <div class="text">Unit Price</div> </el-col>
                              <el-col :span="16">
                                <el-input v-model="alternatives.AlternativeUnitPrice" placeholder="..." v-numeric @change="sortByQuantity" ></el-input>
                                <span class="error-validation">{{ $errorMessage("Products[" + index + "].UnitPriceQuantityAlternatives["+alternativeIndex+"].AlternativeUnitPrice", validation) }}</span>
                              </el-col>
                            </el-col>
                          </el-row>
                          <el-row :gutter="24">
                            <el-col class="input-container-row" :sm="24">
                              <el-col :span="8"> <div class="text">Quantity</div> </el-col>
                              <el-col :span="16">
                                <el-input v-model="alternatives.AlternativeQuantity" placeholder="..." v-numeric  @change="sortByQuantity"></el-input>
                                <span class="error-validation">{{ $errorMessage("Products[" + index + "].UnitPriceQuantityAlternatives["+alternativeIndex+"].AlternativeQuantity", validation) }}</span>
                              </el-col>
                            </el-col>
                          </el-row>
                          <div class="line"></div>
                        </div>
                        <div class="product-inf-button-groups">
                          <el-button class="add-btn" type="info" @click="createNewAlternativeValues(index)"> <i class="icon-plus-solid"></i>Add New Values</el-button>
                        </div>
                      </template>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </template>
            <span class="error-validation">{{ $errorMessage("Products", validation) }}</span>

            <div class="product-inf-button-groups">
              <el-button class="add-btn" type="info" data-parse-on-submit-button="my-button" @click="createNewProduct"> <i class="icon-plus-solid"></i>Add New Item</el-button>
            </div>

            <div class="line"></div>
            <div class="title">Priority<span class="yellow-dot">.</span></div>
            <el-row :gutter="24">
              <el-col class="input-container" :sm="8">
                <div class="text">Priority</div>
                <el-select v-model="quatForm.Priority"  placeholder="Select">
                  <el-option v-for="(item, index) in PriorityAlternatives" :key="index" :value="item.Priority" :label="item.Priority" >
                    <i :class="item.Icon"></i> {{ item.Priority }}
                  </el-option>
                </el-select>
                <span class="error-validation">{{ $errorMessage("Priority", validation) }}</span>
              </el-col>
            </el-row>
            <div class="line"></div>

            <div class="title">Payment Instruction<span class="yellow-dot">.</span></div>
            <div class="terms-and-conditions">
              <el-row :gutter="24" v-for="(item, index) in quatForm.BeforeProductionBalans" :key="index + 'before-production-items'">
                <el-col class="input-container-row" :sm="24">
                  <el-col :span="8" class="flex-mini-gap">
                    <div class="icon">
                      <div class="icon-row-trash" style="cursor: pointer" @click="deletePayment(index)"></div>
                    </div>
                    <div class="text">Before Production {{ index + 1 }}</div>
                  </el-col>
                  <el-col :span="16">
                    <el-input v-model="quatForm.BeforeProductionBalans[index]" placeholder="Please firstly write before payment ...% and than write text"></el-input>
                    <span class="error-validation">{{ $errorMessage("BeforeProductionBalans[" + index + "]", validation) }}</span>
                  </el-col>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col class="input-container-row" :sm="24">
                  <el-col :span="8" class="flex-mini-gap">
                    <div class="text">Last Payment Info</div>
                  </el-col>
                  <el-col :span="16">
                    <el-input v-model="quatForm.LastPaymentText" placeholder="Plase write last text like 'Balance payment before delivery'"></el-input>
                    <span class="error-validation">{{ $errorMessage("LastPaymentText", validation) }}</span>
                  </el-col>
                </el-col>
              </el-row>
            </div>
            <div class="product-inf-component">
              <div class="product-inf-button-groups">
                <el-button class="add-btn" @click="addPayment()" type="info"> <i class="icon-plus-solid"></i>Add Before Production</el-button>
              </div>
            </div>

            <div class="line"></div>
            <div class="title">Terms and Conditions<span class="yellow-dot">.</span></div>

            <div class="terms-and-conditions">
              <el-row :gutter="24" v-for="(item, index) in quatForm.Terms" :key="index + 'term'">
                <el-col class="input-container-row-align-flex-start" :sm="24">
                  <el-col :span="8" class="flex-mini-gap">
                    <div class="icon-row-trash" style="cursor: pointer" @click="deleteTerms(index)"></div>
                    <div class="text">Row {{ index + 1 }}</div>
                  </el-col>
                  <el-col :span="16">
                    <el-input v-model="quatForm.Terms[index]" type="textarea" :rows="2" placeholder="Add Terms..."></el-input>
                  </el-col>
                </el-col>
              </el-row>
            </div>

            <div class="product-inf-component">
              <div class="product-inf-button-groups">
                <el-button class="add-btn" @click="addTerms()" type="info"> <i class="icon-plus-solid"></i>Add Row</el-button>
              </div>
            </div>
          </div>

          <div class="line"></div>

          <div class="title">Notes<span class="yellow-dot">.</span></div>
          <el-input v-model="quatForm.Note" type="textarea" :rows="5" placeholder="Add Quotations Note..."></el-input>
          
          <el-tabs type="border-card" :stretch="true"  v-if="quatForm.ID != null"> 
            <el-tab-pane v-if="quatForm.RevisedQuatations.length>0">
              <span slot="label"><i class="icon-receipt"></i> Offer</span>
              <div class="offer form">
                <div class="title">Quotation<span class="yellow-dot">.</span></div>
                <el-table v-if="quatForm" :data="quatForm.RevisedQuatations" style="width: 100%">
                  <el-table-column prop="OfferNo" label="Offer No" width="180"> </el-table-column>
                  <el-table-column prop="Customer.CompanyName" label="Customer"></el-table-column>
                  <el-table-column prop="date" label="Date">
                    <template slot-scope="scope">
                      <span>Created Date : {{ $moment(scope.row.CreatedDateTime).format("DD-MM-YYYY") }}</span>
                      <br />
                      <span>Last Update : {{ $moment(scope.row.UpdatedDateTime).format("DD-MM-YYYY") }}</span>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column prop="status" label="Status">
                    <template slot-scope="scope">
                      <div class="status" :class="getClassStatus(scope.row.Status)">
                        <span v-if="getEnums">{{ getEnumsDisplay("OfferStatus", scope.row.Status) }}</span>
                      </div>
                    </template>
                  </el-table-column> -->

                  <el-table-column label="Account Manager">
                    <template slot-scope="scope"> {{ scope.row.AccountManager.FullName }} </template>
                  </el-table-column>

                  <el-table-column label="Preview" width="160">
                    <template slot-scope="scope">
                      <el-button class="popover-eye" @click="seePrewiev(quatForm.ID,scope.row.OfferNo)" slot="reference">
                        <i class="el-icon-loading" style="font-size: 18px; color: blue" v-if="loading == 'exportLoading' + scope.row.OfferNo"></i>
                        <i class="icon-eye-popover" v-else></i>
                      </el-button>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column label="Download Pdf" width="160">
                    <template slot-scope="scope">
                      <el-button class="popover-eye"  @click="exportPdf(quotationList.find((x) => x.ID == scope.row.ID))" slot="reference">
                      <i class="icon-download-2" style="cursor: pointer" @click="exportPdf(quotationList.find((x) => x.ID == scope.row.ID))"></i>
                      <i class="el-icon-loading" style="font-size: 18px; color: blue" v-if="loading == 'downloadLoading' + scope.row.ID"></i>
                      <i class="icon-download-2" v-else></i>
                      </el-button>
                      
                    </template>
                  </el-table-column> -->
                </el-table>
              </div>
            </el-tab-pane>
            <el-tab-pane>
              <span slot="label"
                ><i class="icon-arrows-rotate-solid"></i>Updates<span class="notifications">{{ quatForm.Histories.length > 9 ? "9+" : quatForm.Histories.length }}</span></span
              >
              <div class="updates-timeline form">
                <el-timeline>
                  <el-timeline-item :timestamp="$moment(item.Date).format('MMMM Do YYYY, h:mm:ss A')" placement="top" v-for="(item, index) in quatForm.Histories" :key="index">
                    <el-card>
                      <div class="content" v-html="item.Note"></div>
                      <div class="updates-bottom">
                        <div class="bottom" v-if="item.Employee">
                          <div class="circle">{{ item.Employee.FirstName[0] + "" + item.Employee.LastName[0] }}</div>
                          <div class="user">
                            <div class="user-info">{{ item.Employee.FullName }}</div>
                            <div class="status">
                              <span> {{ getEnumsDisplay("TypeOfAuthority", item.Employee.Role) }} </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </el-timeline-item>
                </el-timeline>
              </div>
            </el-tab-pane>
          </el-tabs>
          
          <!-- <el-tabs type="border-card" :stretch="true" v-if="quatForm.ID != null">
            <el-tab-pane>
              <span slot="label"
                ><i class="icon-arrows-rotate-solid"></i>Updates<span class="notifications">{{ quatForm.Histories.length > 9 ? "9+" : quatForm.Histories.length }}</span></span
              >
              <div class="updates-timeline form">
                <el-timeline>
                  <el-timeline-item :timestamp="$moment(item.Date).format('MMMM Do YYYY, h:mm:ss A')" placement="top" v-for="(item, index) in quatForm.Histories" :key="index">
                    <el-card>
                      <div class="content" v-html="item.Note"></div>
                      <div class="updates-bottom">
                        <div class="bottom" v-if="item.Employee">
                          <div class="circle">{{ item.Employee.FirstName[0] + "" + item.Employee.LastName[0] }}</div>
                          <div class="user">
                            <div class="user-info">{{ item.Employee.FullName }}</div>
                            <div class="status">
                              <span> {{ getEnumsDisplay("TypeOfAuthority", item.Employee.Role) }} </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </el-timeline-item>
                </el-timeline>
              </div>
            </el-tab-pane>
          </el-tabs> -->

          <div class="dialog-footer footerbutton">
            <el-button class="btn" type="danger" @click="close"> <i class="icon-xmark-solid"></i> Cancel</el-button>
            <el-button class="btn" type="info" v-if="quatForm.ID != null" @click="exportQuotation" :loading="loading == 'viewQuat'"> <i class="icon-eye"></i> View Preview</el-button>
            <el-button class="btn" type="info" v-if="quatForm.ID != null && ([1,2].includes(quatForm.Status)) && user.Role==10" :loading="loading == 'readyLoading'" @click="readyChange" :icon="'el-icon-finished'"> Ready </el-button>
            <el-button class="btn" type="primary" v-if="[5].includes(quatForm.Status)" @click="reviseQuat" :disabled="!canUpdate" :loading="loading == 'reviseLoading'" :icon="'el-icon-edit'">Revise</el-button>
            <el-button class="btn" type="primary" v-if="![5,6,7,8].includes(quatForm.Status)" @click="createQuat" :disabled="!canUpdate" :loading="loading == 'saveLoading'" :icon="quatForm.ID != null ? 'el-icon-edit' : 'el-icon-plus'">{{ quatForm.ID != null ? "Save Changes" : "Add New Quotation" }}</el-button>
          </div>
        </div>
      </el-row>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["id"],
  name: "add-or-update-quotation",
  data() {
    return {
      loading: false,
      timeout: null,
      checkList: [],
      cId: null,
      quatForm: null,
      isPotential: null,
      createQuotationDialogVisible: false,
      selectedPrint: null,
      unitPriceQuantityAlternatives:null,
      updatable:false,
      originalQuatForm:null,
      reasons:[],
      PrintAlternatives: [
        {
          AlternativePrint: " Customer Design ",
        },
        {
          AlternativePrint: " 1 Colour ",
        },
        {
          AlternativePrint: " 2 Colour ",
        },
      ],
      PriorityAlternatives: [
        {
          Priority: "High",
          Icon: "priority-reddot"
        },
        {
          Priority: "Mid",
          Icon: "priority-bluedot"
        },
        {
          Priority: "Low",
          Icon: "priority-greendot"
        },
      ],
    };
  },
  async created() {
    await this.checkedId();
    
    await Promise.all([this.$store.dispatch("getCustomersList", { PageSize: 9999999, Page: 1, SearchTerm: null }), this.$store.dispatch("getPotentialCustomersList", { PageSize: 9999999, Page: 1, SearchTerm: null }), this.$store.dispatch("getProductSamplesList", { PageSize: 9999999, Page: 1, SearchTerm: null }),this.$store.dispatch("getInterestProducts")]);
    await this.sortByQuantity();  

    await this.setReason();
    
    setTimeout(async () => {
       this.originalQuatForm=JSON.parse(JSON.stringify(this.quatForm));
        console.log(this.originalQuatForm)
        console.log(this.quatForm)
        if (JSON.stringify(this.quatForm) !== JSON.stringify(this.originalQuatForm)) {
          this.updatable = true;
        } else {
          this.updatable = false;
        }
    }, 1500);
    //   this.quatform.Products.UnitPrice = this.quatform.Products.UnitPrice.toLocaleString("en", { useGrouping: false, minimumFractionDigits: 2, maximumFractionDigits: 5 });
    //   this.quatform.Products.UnitPriceQuantityAlternatives.AlternativeUnitPrice = this.quatform.Products.UnitPriceQuantityAlternatives.AlternativeUnitPrice.toLocaleString("en", { useGrouping: false, minimumFractionDigits: 2, maximumFractionDigits: 5 });
  },
  methods: {
    checkNull(){
      this.quatForm.Products.forEach(product => {
        if(product.Width===""){
          product.Width  =null;
        }
        if(product.Length===""){
          product.Length  =null;
        }
        if(product.Height===""){
          product.Height  =null;
        }
      });
    },
    setReason(){
      if(this.quatForm.Reason==null || this.quatForm.Reason=="")
        return;
        this.quatForm.Reason.split("|").forEach((item) => {
          if(item!=""){
            this.reasons.push({reasonType:this.getEnumsDisplay("OfferStatus",this.quatForm.Status),reasonText:item,reasonColor:this.getReasonColor(this.quatForm.Status)})
          }
        });
        console.log(this.reasons)
    },
    getReasonColor(status){
      var res;
      switch (status) {
        case 6:
          res = "color: #67c23a";
          break;
        case 7:
          res = "color: #f56c6c";
          break;
        case 8:
          res = "color: #fbbf48";
          break;
      }
      return res;
    },
    validSizes(event){
        const value = event;
        console.log(value)
        // //eslint-disable-next-line
        // const regex = new RegExp("^[1-9][0-9]*(\.[0-9]+)?$");
        // this.validation.filter((el)=> { return el.Key != "Regex"; } )
        // if (!regex.test(value)) {
        //   this.validation.push({
        //     Key: "Regex",
        //     Value: "You can enter value like 10.5 or 10 not acceptable 010 or 10.",
        //   });
        // }
     },
    sortByQuantity(){
      if(this.quatForm==null){
        return;
      }
      console.log("change çalıştı")
      console.log(this.quatForm.Products)
      this.quatForm.Products.forEach((item) => {
          item.UnitPriceQuantityAlternatives[0].AlternativeUnitPrice=item.UnitPrice
          item.UnitPriceQuantityAlternatives[0].AlternativeQuantity=item.Quantity
          var values = item.UnitPriceQuantityAlternatives.sort((a,b)=>b.AlternativeQuantity-a.AlternativeQuantity)
          item.UnitPrice=values[0].AlternativeUnitPrice;
          item.Quantity=values[0].AlternativeQuantity;
          item.UnitPriceQuantityAlternatives=values;
      });
    },
    emptyIfNull(val){
      return val || ""
    },
    createDescription(index) {
      var x;
      if (this.quatForm.Products[index].Unit == 0) {
        x = '"';
      } else if (this.quatForm.Products[index].Unit == 1) {
        x = "oz";
      } else if (this.quatForm.Products[index].Unit == 2) {
        x = "";
      } else if (this.quatForm.Products[index].Unit == 3) {
        x = "cc";
      } else {
        x = "";
      }
      if(x != ""){
          x = x + " "; 
      }
      if (this.quatForm.Products[index].Diameter ) {
        this.quatForm.Products[index].Description = "Bespoke " + this.emptyIfNull(this.quatForm.Products[index].DiameterValue) + x + this.quatForm.Products[index].Title;
      } else {
        this.quatForm.Products[index].Description = "Bespoke " + this.emptyIfNull(this.quatForm.Products[index].Width) + "x" + this.emptyIfNull(this.quatForm.Products[index].Length) +(this.quatForm.Products[index].Height ? "x" + this.quatForm.Products[index].Height: "") + "cm " + this.quatForm.Products[index].Title;
      }
    },

    close(id) {
      this.$emit("close", id);
    },
    checkedList(index) {
      var indexOf = this.checkList.findIndex((item) => item == index);
      if (indexOf != -1) {
        this.checkList.splice(indexOf, 1);
      } else {
        this.checkList.push(index);
      }
    },
    deleteCheckedItems() {
      this.$nextTick(() => {
        this.checkList.forEach((item) => {
          this.quatForm.Terms.splice(item, 1);
        });
      });
      this.$nextTick(() => {
        this.checkList = [];
      });
    },
    addTerms() {
      this.quatForm.Terms.push("");
    },
    deleteTerms(index) {
      this.quatForm.Terms.splice(index, 1);
    },
    async setForm() {
      this.quatForm = await this.$store.dispatch("getOneQuotation", this.id);
    },
    async readyChange() {
      
      this.checkNull();
      
      this.$store.commit("setQuotationValidation", []);

      if (this.validation.length > 0) {
        return;
      }
      console.log(this.quatForm)
      this.loading = "readyLoading";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var res = await this.$store.dispatch("readyQuotations", this.quatForm);
        if (!res.HasError) {
          this.quatForm = {
            ID: null,
            CustomerId: null,
            AccountManagerId: null,
            Products: [],
            FirstName: null,
            Terms: [],
            Note: null,
            LastName: null,
            CreatedDateTime: null,
          };
          (await this.cId) == null;
          await this.$store.dispatch("getProductSamplesList", { Page: 1, PageSize: 999999, SearchTerm: null });

          this.close();
        }
        this.loading = false;
      }, 500);
    },
    async reviseQuat(){

      this.checkNull();

      this.$store.commit("setQuotationValidation", []);

      if (this.validation.length > 0) {
        return;
      }
      this.loading = "reviseLoading";
      console.log(this.quatForm)
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var res = await this.$store.dispatch("reviseQuotations", this.quatForm);
        if (!res.HasError) {
          this.quatForm = {
            ID: null,
            CustomerId: null,
            AccountManagerId: null,
            Products: [],
            FirstName: null,
            Terms: [],
            Note: null,
            LastName: null,
            CreatedDateTime: null,
          };
          (await this.cId) == null;
          await this.$store.dispatch("getProductSamplesList", { Page: 1, PageSize: 999999, SearchTerm: null });
          //this.$forceUpdate();
          this.close();
        }
        this.loading = false;
      }, 500);
    },
    async createQuat() {

      this.checkNull();

      this.$store.commit("setQuotationValidation", []);
      console.log(this.validation)
      if (this.validation.length > 0) {
        return;
      }
      console.log(this.quatForm)
      this.loading = "saveLoading";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var res = await this.$store.dispatch("addUpdateQuotations", this.quatForm);
        if (!res.HasError) {
          this.quatForm = {
            ID: null,
            CustomerId: null,
            AccountManagerId: null,
            Products: [],
            FirstName: null,
            Terms: [],
            Note: null,
            LastName: null,
            CreatedDateTime: null,
          };
          (await this.cId) == null;
          await this.$store.dispatch("getProductSamplesList", { Page: 1, PageSize: 999999, SearchTerm: null });
          //this.$forceUpdate();
          this.close();
        }
        this.loading = false;
      }, 500);
    },

    changeQuat(id) {
      var cust = this.allCustomers.find((x) => x.ID == id);
      
      if (cust.CustomerType == 1) {
        this.isPotential = false;
      } else {
        this.isPotential = true;
      }
      this.quatForm.FirstName = cust.FirstName;
      this.quatForm.LastName = cust.LastName;
      this.quatForm.Products = this.quotationForm.Products;
      this.quatForm.Terms = this.quotationForm.Terms;
      this.quatForm.CompanyName=cust.CompanyName;
      this.cId = cust.AccountNo != null ? cust.AccountNo : cust.LeadId;
      this.quatForm.Customer=cust; 
    },

    async checkedId() {
      this.loading = "addUpdateScreen";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        if (this.id) {
          await this.getQuotation();
        } else {
          this.quatForm = JSON.parse(JSON.stringify(this.quotationForm));
        }
        this.loading = false;
      }, 500);
    },
    async getQuotation() {
      var res = await this.$store.dispatch("getOneQuotation", this.id);
      this.quatForm = res;
    },
    exportQuotation() {
      this.loading="viewQuat";
      var id = this.id;
      var token = this.$cookies.get("token")
      // window.open(`https://pdf.imageus.dev/?url=https://portal.packagingsource.co.uk/quotation.html?id=${id}=idtoken-seperator=${token}&pageSize=1754px%7C1240px`);
      this.$client.get("/Quotation/ExportPdfWithSejda", { responseType: "blob", params: { id: id,token:token } }).then((response) => {
          console.log(response)
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.target="_blank";
          link.href = URL.createObjectURL(blob);
          link.click();
          URL.revokeObjectURL(link.href);
          this.loading = false;
      });
    },
    resetDiameter(i) {
      this.quatForm.Products[i].DiameterValue = null;
      this.quatForm.Products[i].Unit = 2;
      this.quatForm.Products[i].Width = 0;
      this.quatForm.Products[i].Height = 0;
      this.quatForm.Products[i].Length = 0;
    },
    createNewProduct() {
      this.quatForm.Products.push({
        Diameter: false,
        DiameterValue: 0,
        Width: null,
        Height: 0,
        Length: 0,
        Print: null,
        Quality: null,
        UnitPrice: 0,
        Quantity: 0,
        Title: null,
        Description: null,
        ID: null,
        SelectType: false,
        UnitPriceQuantityAlternatives: [{
          AlternativeUnitPrice: 0,
          AlternativeQuantity: 0,
        }],
        Type: null,
      });
    },
    deleteNewProduct(index) {
      this.quatForm.Products.splice(index, 1);
    },
    createNewAlternativeValues(index) {
      if (this.quatForm.Products[index].UnitPriceQuantityAlternatives.length == 0) {
        this.quatForm.Products[index].UnitPriceQuantityAlternatives.push({
          AlternativeUnitPrice: 0,
          AlternativeQuantity: 0,
        });
      }
      this.quatForm.Products[index].UnitPriceQuantityAlternatives.push({
        AlternativeUnitPrice: 0,
        AlternativeQuantity: 0,
      });
    },
    deleteAlternativeValues(index, alternatives) {
      var alternativeIndex = this.quatForm.Products[index].UnitPriceQuantityAlternatives.indexOf(alternatives);
      this.quatForm.Products[index].UnitPriceQuantityAlternatives.splice(alternativeIndex, 1);
    },
    updateAlternativePrint(index) {
      this.quatForm.Products[index].Print = this.selectedPrint;
      this.selectedPrint = null;
    },
    addPayment() {
      this.quatForm.BeforeProductionBalans.push("");
    },
    deletePayment(index) {
      this.quatForm.BeforeProductionBalans.splice(index, 1);
    },
    seePrewiev(id,offerNo) {
      this.loading = "exportLoading" +offerNo;
      var token = this.$cookies.get("token")
      // if (this.timeout != null) clearTimeout(this.timeout);
      // this.timeout = setTimeout(async () => {
      //   var token = this.$cookies.get("token")
      //   window.open(`https://pdf.imageus.dev/?url=https://portal.packagingsource.co.uk/quotation.html?id=${id}=offer-no=${offerNo}=idtoken-seperator=${token}&pageSize=1754px%7C1240px`);
      //   this.loading = false;
      // }, 500);

      this.$client.get("/Quotation/ExportPdfWithSejda", { responseType: "blob", params: { id: id,token:token, quatNo:offerNo } }).then((response) => {
          console.log(response)
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.target="_blank";
          link.href = URL.createObjectURL(blob);
          link.click();
          URL.revokeObjectURL(link.href);
          this.loading = false;
      });
    },
    getClassStatus(status) {
      var res;
      switch (status) {
        case 0:
          res = "success";
          break;
        case 1:
          res = "warning";
          break;
        case 2:
          res = "info";
          break;
        case 3:
          res = "danger";
          break;
        case 4:
          res = "info";
          break;
        case 5:
          res = "info";
          break;
        case 6:
          res = "success";
          break;
        case 7:
          res = "danger";
          break;
      }
      return res;
    },
  },
  computed: {
    canUpdate() {
      return this.updatable;
    },
    getInterestProductsList() {
      return this.$store.getters.getInterestProducts;
    },
    user() {
      return this.$store.getters.getUser;
    },
    allCustomers() {
      var allCustomers = this.customerList.concat(this.potentialCustomersList);
      if(this.user.Role==1)
      allCustomers=  allCustomers.filter((x)=>x.AccountManagerId==this.user.ID)
      return allCustomers;
    },
    productForm() {
      return this.$store.getters.getProductsForm;
    },
    sampleProductList() {
      return this.$store.getters.getProductSamplesList;
    },
    customerList() {
      return this.$store.getters.getCustomersList;
    },
    getEnums() {
      return this.$store.getters.getEnums;
    },
    validation() {
      return this.$store.getters.getQuotationsValidationErrors;
    },
    getQuotationsList() {
      return this.$store.getters.getQuotationsList;
    },
    quotationForm() {
      return this.$store.getters.getQuotationsForm;
    },
    potentialCustomersList() {
      return this.$store.getters.getPotentialCustomersList;
    },
    form() {
      return this.$store.getters.getPotentialCustomersForm;
    },
  },
  watch: {
    quatForm: {
      handler(newVal) {
        if(this.quatForm.Note===""){
          this.quatForm.Note=null
        }
        if (JSON.stringify(newVal) !== JSON.stringify(this.originalQuatForm)) {
          this.updatable = true;
        } else {
          this.updatable = false;
        }
      },
      deep: true
    }
  },
};
</script>
