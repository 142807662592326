export default {
    setProjectsList(state, data) {
        state.projectList = data;
    },
    setCompletedProjectsList(state, data) {
        state.completedProjectList = data;
    },
    setProjectOne(state, data) {
        state.project = data;
    },
    setProjectValidation(state, data) {
        state.projectValidationErrors = data;
    }

}