export default {
    setWarehouseList(state, data) {
        state.warehouseList = data;
    },
    setStockList(state,data){
        state.stockList=data;
    },
    setWarehouseOne(state, data) {
        state.warehouse = data;
    },
    setWarehouseValidation(state, data) {
        state.warehouseValidation = data;
    }
}