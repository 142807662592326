export default {
    getWarehouseList: state => state.warehouseList,
    getWarehousesValidation: state => state.warehouseValidation,
    getWarehousesFilter: state => state.filter,
    getStockFilter: state => state.stockFilter,
    getWarehousesTotalCount: state => state.warehouseTotalCount,
    getStockTotalCount: state => state.stockTotalCount,
    getWarehousesForm: state => state.warehouseForm,
    getWarehouseItemForm: state => state.warehouseItemForm,
    getStockList: state => state.stockList
}