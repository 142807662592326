import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  quotationsList: [],
  quotation: {},
  quotationFilter: {
    SearchTerm: null,
    Page: 1,
    PageSize: 200,
    StartDate: null,
    EndDate: null,
    CustomerId: null,
    Status: null,
    SortBy: null,
    SortDirection: 1,
    AccountManagerId:null
  },
  quotationsTotal: {
    PageCount: null,
    TotalCount: null,
  },
  quotationForm: {
    ID: null,
    ProductNo: null,
    Diameter: true,
    Products: [],
    Width: 0,
    Length: 0,
    Height: 0,
    Print: 0,
    Quality: 0,
    UnitPrice: 0,
    IsProduced: false,
    FirstName: null,
    LastName: null,
    Title: null,
    Note: null,
    Terms: [
      "Above prices include origination costs.",
      "Above prices include free storage services in the UK.",
      "Above prices include free deliveries to your door(UK Postcode).",
      "Local deliveries may take 2-3 days after receiving the order(UK Postcode).",
      "The lead time for the first productions may take 8-12 weeks after design confirmation.",
      "The lead time for continuing productions may take 6 weeks.",
      "Production tolerance 15%± should be accepted by the customer.",
      // "Payment terms : 50% upfront proforma payment, balance payment before delivery",
    ],
    BeforeProductionBalans: ["50% upfront proforma payment"],
    LastPaymentText: "Balance payment before delivery",
    SelectType: true,
    Priority:null
  },
  quotationValidation: [],
};

export default {
  state,
  getters,
  actions,
  mutations,
};
