import { client } from "../../helpers/axiosHelper";
import { Message, MessageBox } from "element-ui";
export default {
  async getSuppliersList(context, payload) {
    var res;
    if (payload) {
      res = await client.post("/Supplier/List", payload);
    } else {
      res = await client.post("/Supplier/List", context.state.SupplierFilter);
    }
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      console.log(res.data.Data.Items)
      context.commit("setSuppliersList", res.data.Data.Items);
      context.state.SupplierTotal.TotalCount = res.data.Data.TotalCount;
      context.state.SupplierTotal.PageCount = res.data.Data.PageCount;
      context.state.SupplierTotal.PageCount = res.data.Data.SearchTerm;
    }
  },
  async addUpdateSupplier(context, payload) {
    var res = await client.post("/Supplier/AddUpdate", payload);
    context.commit("setSupplierValidation", res.data.ValidationErrors);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      Message.success(res.data.Message);
      context.dispatch("getSuppliersList");
    }
    return res.data;
  },
  async getOneSupplier(context, payload) {
    var res = await client.post("/Supplier/Get", { Id: payload });
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setSupplierOne", res.data.Data);
      return res.data.Data;
    }
  },

  async deleteOneSupplier(context, payload) {
    MessageBox.confirm("Are you sure for delete this?", "Warning", {
      confirmButtonText: "Delete",
      confirmButtonClass: "danger-btn-confirm",
      cancelButtonText: "Back",
      type: "warning",
    })
      .then(async () => {
        var res = await client.post("/Supplier/Delete", payload);
        if (res.data.HasError) {
          Message.error(res.data.Message);
        } else {
          Message.success(res.data.Message);
          await context.dispatch("getSuppliersList");
        }
      })
      .catch(() => {});
  },
};
