<template>
  <div id="potential-customer-quotations">
    <portal to="filter">
      <el-header class="filter-header">
        <div class="title">Quotation List</div>
        <div class="actions-buttons">
          <el-button type="secondary" class="btn" :class="{ 'active-filter': filterActive ? true : false }" @click="toggle"><i class="icon-filter-filter"></i> Filters</el-button>
          <!-- <el-button type="info" class="btn" @click="costCalculatorDialogVisible = true"><i class="icon-calculator-solid"></i> Cost Calculator</el-button> -->
          <el-button type="primary" class="btn" @click="setItem(null)">
            <i class="el-icon-loading" style="font-size: 18px; color: white" v-if="loading == 'openAddDialogLoading'"></i>
            <i class="icon-filter-add" v-else></i>Create Quotation</el-button
          >
        </div>
      </el-header>
    </portal>
    <div class="filter-container" v-if="filter">
      <div class="header">
        <div class="title">Filters</div>
        <el-button type="danger" class="btn" @click="clearFilter">
          <i class="icon-filter-close"></i>
          Clear Filter
        </el-button>
      </div>
      <div class="all-inputs">
        <el-row :gutter="36">
          <el-col :sm="8">
            <el-input placeholder="Search" prefix-icon="el-icon-search" v-model="filter.SearchTerm" clearable></el-input>
          </el-col>
        </el-row>
        <el-row :gutter="36">
          <el-col class="input-container" :sm="8">
            <div class="text">Date</div>
            <el-date-picker format="dd-MM-yyyy" prefix-icon="icon-calendarplus" v-model="dateRange" type="daterange" range-separator="To" start-placeholder="Start date" end-placeholder="End date" clearable @clear="dateRange = []"> </el-date-picker>
          </el-col>
          <el-col class="input-container" :sm="8">
            <div class="text">Customer</div>
            <el-select v-model="filter.CustomerId" placeholder="Select" clearable filterable>
              <el-option v-for="item in getAllCustomers" :key="item.value" :label="item.CompanyName" :value="item.ID"> </el-option>
            </el-select>
          </el-col>
          <el-col class="input-container" :sm="8">
            <div class="text">Status</div>
            <el-select v-model="filter.Status" placeholder="Select" clearable @clear="filter.Status = null">
              <el-option v-for="(item, index) in getEnums.find((x) => x.Title == 'OfferStatus').Enums" :key="item.Key + index" :label="item.DisplayName" :value="item.Key"> </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row :gutter="36" v-if="user.Role==10">
          <el-col class="input-container" :sm="8">
              <div class="text">Account Manager</div>
              <el-select v-model="filter.AccountManagerId" placeholder="Select">
                <el-option v-for="(item, index) in getUserList" :key="index" :value="item.ID" :label="item.FirstName + ' ' + item.LastName"> </el-option>
              </el-select>
            </el-col>
        </el-row>
      </div>
    </div>
    <div class="table-container">
      <div class="view-info">
        <p>You are viewing page {{ filter.Page }} out of a total of {{ totalCount.PageCount }} pages. (Total Records: {{ totalCount.TotalCount }})</p>
        <el-button class="refresh-btn" @click="arrowsRotate"> <i class="icon-arrows-rotate-solid"></i></el-button>
      </div>
      <el-table v-if="quotationList" :data="quotationList" style="width: 100%" v-on:sort-change="sortHandler">
        <el-table-column prop="OfferNo" label="Offer No" width="180" sortable="custom"> </el-table-column>
        <el-table-column prop="Customer.CompanyName" label="Customer">
          <template slot-scope="scope">
            <div class="customer" @click="openPotensialCustomerDialog(scope.row.Customer.ID)">{{ scope.row.Customer != null ? scope.row.Customer.CompanyName : "" }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="Date" sortable="custom">
          <template slot-scope="scope">
            <span>Created Date : {{ $moment(scope.row.CreatedDateTime).format("DD-MM-YYYY") }}</span>
            <br />
            <span>Last Update : {{ $moment(scope.row.UpdatedDateTime).format("DD-MM-YYYY") }}</span>
            <br />
            <span>It's been {{ $moment().diff($moment(scope.row.CreatedDateTime), "days") + 1 }} {{ $moment().diff($moment(scope.row.CreatedDateTime), "days") + 1 == 1 ? "day" : "days" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Account Manager">
          <template slot-scope="scope"> {{ scope.row.AccountManager.FirstName }} {{ scope.row.AccountManager.LastName }} </template>
        </el-table-column>
        <el-table-column prop="status" label="Status">
          <template slot-scope="scope">
            <div class="status" :class="getClassStatus(scope.row.Status)">
              <span v-if="scope.row.Status==6"> {{ scope.row.Products.filter((x)=>x.Checked==true).length }} {{ getEnumsDisplay("OfferStatus", scope.row.Status) }} out of {{ scope.row.Products.length }}</span>
              <span v-else>{{ getEnumsDisplay("OfferStatus", scope.row.Status) }}</span>
           </div>
          </template>
        </el-table-column>
        <el-table-column label="Priority">
          <template slot-scope="scope">
            <span v-if="scope.row.Priority != null">
              <i :class="PriorityAlternatives.find((x) => x.Priority == scope.row.Priority).Icon"></i> {{ scope.row.Priority }}</span>
            <span v-else> No priority yet </span>
          </template>
        </el-table-column>
        <el-table-column label="Preview" width="160">
          <template slot-scope="scope">
            <el-button class="popover-eye" @click="seePrewiev(scope.row.ID,scope.row.OfferNo)" slot="reference">
              <i class="el-icon-loading" style="font-size: 18px; color: blue" v-if="loading == 'exportLoading' + scope.row.ID"></i>
              <i class="icon-eye-popover" v-else></i>
            </el-button>
          </template>
          <iframe ref="myIframe" style="display: none;"></iframe>
        </el-table-column>
        <el-table-column label="Download Pdf" width="160">
          <template slot-scope="scope">
            <el-button class="popover-eye"  @click="exportPdf(quotationList.find((x) => x.ID == scope.row.ID),scope.row.OfferNo)" slot="reference">
             <!-- <i class="icon-download-2" style="cursor: pointer" @click="exportPdf(quotationList.find((x) => x.ID == scope.row.ID))"></i> -->
             <i class="el-icon-loading" style="font-size: 18px; color: blue" v-if="loading == 'downloadLoading' + scope.row.ID"></i>
             <i class="icon-download-2" v-else></i>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="Actions" width="160">
          <template slot-scope="scope">
            <el-dropdown>
              <el-button type="primary">Actions<i class="el-icon-arrow-down el-icon--right"></i></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <span class="view-edit" @click="setItem(scope.row.ID)"><i class="icon-pen-to-square-solid"></i> View & Edit</span>
                </el-dropdown-item>
                <!-- Daha iyi yazılabilir  && quotationList.find((x) => x.ID == scope.row.ID).Products.length!=scope.row.Products.filter((x)=>x.Checked).length-->
                <el-dropdown-item v-if="[5,6].includes(quotationList.find((x) => x.ID == scope.row.ID).Status)">
                  <span class="confirm-quatation" @click="openCreateQuotationDialog(scope.row)"><i class="icon-check-double-solid"></i>Confirm Quatation</span>
                </el-dropdown-item>
                <el-dropdown-item v-if="[4].includes(quotationList.find((x) => x.ID == scope.row.ID).Status)">
                  <span class="view-edit transit" @click="changeStatusResponseAwaiting(scope.row.ID)"><i class="icon-response"></i>Response Awaiting</span>
                </el-dropdown-item>
                <!-- <el-dropdown-item v-if="quotationList.find((x) => x.ID == scope.row.ID).Status == 1">
                  <span class="view-edit win" @click="changeStatusWin(scope.row.ID)"><i class="icon-win"></i>Win</span>
                </el-dropdown-item> -->
                <el-dropdown-item v-if="[1, 2, 3, 4, 5].includes(quotationList.find((x) => x.ID == scope.row.ID).Status)">
                  <span class="view-edit void" @click="changeStatusVoid(scope.row.ID)"><i class="icon-lost"></i>Void</span>
                </el-dropdown-item>
                <el-dropdown-item v-if="[ 2, 3, 4, 5].includes(quotationList.find((x) => x.ID == scope.row.ID).Status)">
                  <span class="view-edit stuck" @click="changeStatusLost(scope.row.ID)"><i class="icon-lost"></i>Lost</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div class="table-bottom">
        <div class="info">
          <span>Show</span>
          <el-select v-model="filter.PageSize">
            <el-option label="12" :value="12"></el-option>
            <el-option label="24" :value="24"></el-option>
            <el-option label="36" :value="36"></el-option>
            <el-option label="100" :value="100"></el-option>
            <el-option label="200" :value="200"></el-option>
          </el-select>
          <span>entries</span>
        </div>
        <el-pagination layout="prev, pager, next" :current-page.sync="filter.Page" :page-size="filter.PageSize" :total="totalCount.TotalCount"> </el-pagination>
      </div>
    </div>

    <el-dialog custom-class="create-new-offer" top="5vh" :close-on-click-modal="false" :destroy-on-close="true" :visible.sync="createQuotationDialogVisible" :show-close="false">
      <addOrUpdateQuotation :id="setId" @close="closeCreateQuotationDialog()" v-if="createQuotationDialogVisible"></addOrUpdateQuotation>
    </el-dialog>

    <el-dialog custom-class="confirm-quatation" top="5vh" :close-on-click-modal="false" :destroy-on-close="true" :visible.sync="confirmQuatationDialogVisible" :show-close="false">
      <confirmQuatation :id="setQuotationId" @close="closeConfirmQuatationDialog()" v-if="confirmQuatationDialogVisible"></confirmQuatation>
    </el-dialog>

    <el-dialog custom-class="cost-calculator" top="5vh" :close-on-click-modal="false" :destroy-on-close="true" :visible.sync="costCalculatorDialogVisible" :show-close="false">
      <costCalculator :form="form" @close="closeCostCalculatorDialog()" v-if="costCalculatorDialogVisible"></costCalculator>
    </el-dialog>
    
    <el-dialog custom-class="add-potential-customer" top="5vh" :close-on-click-modal="false" :destroy-on-close="true" :visible.sync="potentialDialogVisible" :show-close="false">
      <addOrUpdatePotentitalCustomers :id="setId" @close="closeAddUpdatePotentitalCustomers()" v-if="potentialDialogVisible" />
    </el-dialog>
  </div>
</template>

<script>
import addOrUpdateQuotation from "../../components/addOrUpdateQuotation.vue";
import costCalculator from "../../components/costCalculator.vue";
import confirmQuatation from "../../components/confirmQuatation.vue";
import addOrUpdatePotentitalCustomers from "../../components/addOrUpdatePotentitalCustomers.vue";
import Vue from "vue";
import VueRouter from "vue-router";
// import CryptoJS from "crypto-js";

Vue.use(VueRouter);
export default {
  components: {
    addOrUpdateQuotation,
    costCalculator,
    confirmQuatation,
    addOrUpdatePotentitalCustomers
  },
  data() {
    return {
      loading: false,
      timeout: null,
      setQuotationId: null,
      setId: null,
      filterActive: false,
      createQuotationDialogVisible: false,
      costCalculatorDialogVisible: false,
      confirmQuatationDialogVisible: false,
      potentialDialogVisible: false,
      value: "",
      dateRange: null,
      form: {
        ID: null,
        CustomerId: null,
        AccountManagerId: null,
        Products: [],
      },
      requestQueue: [], // İstek sırasını tutan dizi
      PriorityAlternatives: [
        {
          Priority: "High",
          Icon: "priority-reddot"
        },
        {
          Priority: "Mid",
          Icon: "priority-bluedot"
        },
        {
          Priority: "Low",
          Icon: "priority-greendot"
        },
      ],
    };
  },
  beforeMount() {
    this.$store.dispatch("getQuotationsList");
    this.$store.dispatch("getCustomersList", { Page: 1, PageSize: 999999, SearchTerm: null });
    this.$store.dispatch("getUserList", { Page: 1, PageSize: 999999, SearchTerm: null });
  },
  mounted() {
    this.jQuery(".filter-container").hide();
  },
  methods: {
    // exportPdf(id) {
    //   this.$client.get("/Quotation/ExportPdf", { responseType: "blob", params: { id: id } }).then((response) => {
    //     const blob = new Blob([response.data], { type: "application/pdf" });
    //     const link = document.createElement("a");
    //     link.href = URL.createObjectURL(blob);
    //     link.download = response.headers["content-disposition"].split("filename=")[1].split(";")[0];
    //     link.click();
    //     URL.revokeObjectURL(link.href);
    //   });
    // },
    exportPdf(quatation) {
      this.loading = "downloadLoading" + quatation.ID;
      // if (this.timeout != null) clearTimeout(this.timeout);
      // this.timeout = setTimeout(async () => {
        var id=quatation.ID
        var fileName="PSQ"+quatation.OfferNo+ " - " +quatation.Customer.CompanyName
        var token = this.$cookies.get("token")
        this.$client.get("/Quotation/ExportPdfWithSejda", { responseType: "blob", params: { id: id,token:token } }).then((response) => {
          console.log(response)
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
          this.loading = false;
        });
      //   this.timeout=false;
      // }, 500);
    },
    async sortHandler(params) {
      // params objesi: {columns, prop, order}
      console.log(params);
      this.filter.SortBy = params.prop;
      this.filter.SortDirection = params.order == "descending" ? 1 : 0;
    },
    arrowsRotate() {
      this.$store.dispatch("getQuotationsList");
    },
    seePrewiev(id) {
      var token = this.$cookies.get("token")
      // const iframe = this.$refs.myIframe;
      // iframe.src = `https://portal.packagingsource.co.uk/quotation.html?id=${id}=offer-no==idtoken-seperator=${token}`;
      // iframe.onload = () => {
      //   console.log('Iframe loaded');
      // };
      this.loading = "exportLoading" + id;
      if (this.timeout != null) clearTimeout(this.timeout);
      // this.timeout = setTimeout(async () => {
      //   var token = this.$cookies.get("token")
      //   window.open(`https://pdf.imageus.dev/?url=https://portal.packagingsource.co.uk/quotation.html?id=${id}=offer-no==idtoken-seperator=${token}&pageSize=1754px%7C1240px`);
      //   this.loading = false;
      // }, 500);
      this.$client.get("/Quotation/ExportPdfWithSejda", { responseType: "blob", params: { id: id,token:token } }).then((response) => {
          console.log(response)
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.target="_blank";
          link.href = URL.createObjectURL(blob);
          link.click();
          URL.revokeObjectURL(link.href);
          this.loading = false;
      });
    },
    clearFilter() {
      this.filter.SearchTerm = null;
      this.dateRange = null;
      this.filter.CustomerId = null;
      this.filter.Status = null;
      this.filter.AccountManagerId=null;
    },
    toggle() {
      this.filterActive = !this.filterActive;
      this.jQuery(".filter-container").slideToggle();
    },

    setItem(item) {
      if (item != null) {
        this.loading = "openAddDialogLoading" + item;
      } else {
        this.loading = "openAddDialogLoading";
      }
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        this.setId = item;
        this.$store.commit("setQuotationValidation", []);
        this.createQuotationDialogVisible = true;
        this.loading = false;
      }, 500);
    },
    async deleteFile(Id) {
      await this.$store.dispatch("deleteOneQuotation", { Id });
      await this.$store.dispatch("getQuotationsList");
    },
    openPotensialCustomerDialog(id) {
      if (id != null) {
        this.loading = "openPotensialCustomerDialog" + id;
      } else {
        this.loading = "openPotensialCustomerDialog";
      }
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        this.$store.commit("setPotentialCustomerValidation", []);
        this.setId = id;
        this.potentialDialogVisible = true;
        this.loading = false;
      }, 500);
    },
    closeAddUpdatePotentitalCustomers(id) {
      this.setId = id;
      this.potentialDialogVisible = false;
    },
    closeCreateQuotationDialog(id) {
      this.setId = id;
      this.createQuotationDialogVisible = false;
    },
    closeCostCalculatorDialog() {
      this.costCalculatorDialogVisible = false;
    },
    closeConfirmQuatationDialog() {
      this.confirmQuatationDialogVisible = false;
    },
    openCreateQuotationDialog(form) {
      this.setQuotationId = form.ID;
      this.confirmQuatationDialogVisible = true;
    },
    async changeStatusResponseAwaiting(id) {
      var payload = {
        QuatationId: id,
        Status: 5,
      };

      this.$confirm("Are you sure you want to change the status to response awaiting ?", "Warning", {
        confirmButtonText: "Yes",
        confirmButtonClass: "danger-btn-confirm",
        cancelButtonText: "No",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$client.post("/Quotation/ChangeStatus", payload);
          if (res.HasError) {
            await this.$message.error(res.data.Message);
          } else {
            await this.$message.success(res.data.Message);
            await this.$store.dispatch("getQuotationsList");
          }
        })
        .catch(() => {});
    },
    // async changeStatusWin(id) {
    //   var payload = {
    //     QuatationId: id,
    //     Status: 6,
    //   };

    //   this.$confirm("Are you sure you want to change the status to win ?", "Warning", {
    //     confirmButtonText: "Yes",
    //     confirmButtonClass: "danger-btn-confirm",
    //     cancelButtonText: "No",
    //     type: "warning",
    //   })
    //     .then(async () => {
    //       var res = await this.$client.post("/Quotation/ChangeStatus", payload);
    //       if (res.HasError) {
    //         this.$message.error(res.data.Message);
    //       } else {
    //         this.$message.success(res.data.Message);
    //         this.$store.dispatch("getQuotationsList");
    //       }
    //     })
    //     .catch(() => {});
    // },
    /* eslint-disable no-useless-escape */
    async changeStatusVoid(id) {
      var payload = {
        QuatationId: id,
        Status: 8,
      };

      this.$prompt("Are you sure you want to change the status to void ? <br/> <span style='color:#f56c6c;font-weight: 700;'> Please Enter a Reason. <span/>", "Warning", {
        dangerouslyUseHTMLString: true,
        inputType:"textarea",
        inputPattern:/[^\s\\]/,
        inputErrorMessage:"Please fill this field.",
        confirmButtonText: "Yes",
        confirmButtonClass: "danger-btn-confirm",
        cancelButtonText: "No",
        type: "warning",
        
      })
        .then(async (reason) => {
          payload.Reason=reason.value;
          var res = await this.$client.post("/Quotation/ChangeStatus", payload);
          if (res.HasError) {
            this.$message.error(res.data.Message);
          } else {
            this.$message.success(res.data.Message);
            this.$store.dispatch("getQuotationsList");
          }
        })
        .catch(() => {});
    },
    async changeStatusLost(id) {
      var payload = {
        QuatationId: id,
        Status: 7,
      };

      this.$prompt("Are you sure you want to change the status to lost ? <br/> <span style='color:#f56c6c;font-weight: 700;'> Please Enter a Reason. <span/>", "Warning", {
        dangerouslyUseHTMLString: true,
        inputType:"textarea",
        inputPattern:/[^\s\\]/,
        inputErrorMessage:"Please fill this field.",
        confirmButtonText: "Yes",
        confirmButtonClass: "danger-btn-confirm",
        cancelButtonText: "No",
        type: "warning",
      })
        .then(async (reason) => {
          payload.Reason=reason.value;
          var res = await this.$client.post("/Quotation/ChangeStatus", payload);
          if (res.HasError) {
            this.$message.error(res.data.Message);
          } else {
            this.$message.success(res.data.Message);
            this.$store.dispatch("getQuotationsList");
          }
        })
        .catch(() => {});
    },
    getClassStatus(status) {
      var res;
      switch (status) {
        case 0:
          res = "success";
          break;
        case 1:
          res = "warning";
          break;
        case 2:
          res = "info";
          break;
        case 3:
          res = "danger";
          break;
        case 4:
          res = "info";
          break;
        case 5:
          res = "info";
          break;
        case 6:
          res = "success";
          break;
        case 7:
          res = "danger";
          break;
          case 8:
          res = "warning";
          break;
      }
      return res;
    },
    processRequest(newValue) {
      this.requestQueue.push(newValue); // Yeni isteği sıraya ekler

      // Henüz işlem yapılmıyorsa işlemi başlatır
      if (this.requestQueue.length === 1) {
        this.executeRequests();
      }
    },
    executeRequests() {
      // İşlem yapılacak asenkron işlevinizi burada tanımlayın
      // Örneğin, bir API çağrısı yapabilirsiniz
      this.filterSearch().then(()=>{
        this.processNextRequest();
      })
    },
    processNextRequest() {
      this.requestQueue.shift(); 

      if (this.requestQueue.length > 0) {
        this.executeRequests();
      }
    },
    async filterSearch(){
      console.log(this.requestQueue)
      await this.$store.dispatch("getQuotationsList");
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    quotationList() {
      return this.$store.getters.getQuotationsList;
    },
    customerList() {
      return this.$store.getters.getCustomersList;
    },
    totalCount() {
      return this.$store.getters.getQuotationsTotal;
    },
    filter() {
      return this.$store.getters.getQuotationsFilter;
    },
    getEnums() {
      return this.$store.getters.getEnums;
    },
    getAllCustomers() {
      return this.$store.getters.getCustomersList;
    },
    getUserList() {
      return this.$store.getters.getUserList.filter((x) => x.Role == 1 || x.Role == 10);
    },
    validation() {
      return this.$store.getters.getQuotationsValidationErrors;
    },
  },
  watch: {
    dateRange: {
      deep: true,
      handler() {
        if (this.dateRange != null && this.dateRange.length > 0) {
          this.filter.StartDate = this.dateRange[0];
          this.filter.EndDate = this.dateRange[1];
        } else {
          this.filter.StartDate = null;
          this.filter.EndDate = null;
          this.dateRange = null;
        }
      },
    },
    filter: {
      deep: true,
      immediate: true, // İlk değeri alırken tetiklenmesini sağlar
      handler: function(newValue) {
        console.log("handler çalıştı")
        this.processRequest(newValue);
      }
    }
    // filter: {
    //   deep: true,
    //   handler:function () {
    //     this.$store.dispatch("getQuotationsList");
    //   },
    // },
  },
};
</script>

<style></style>
