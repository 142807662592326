<template>
  <div class="dialog-container">
    <transition name="el-fade-in" mode="out-in">
      <el-row style="display: flex; align-items: center; justify-content: center; width: 100%" v-if="loading == 'addUpdateScreen' && form == null" key="screen-loading">
        <lottie-player autoplay loop mode="normal" src="/lottie/packaging-source-loading-lottie.json" style="width: 320px"></lottie-player>
      </el-row>
      <el-row v-else-if="form" key="content-loading">
        <div class="dialog-title">
          <div class="head-title">{{ form.ID != null ? "Potential Customer" : "New Potential Customer" }}<span class="yellow-dot">.</span>{{ form.ID != null ? "  #" + form.LeadId : "  #" + leadId }}</div>
          <el-button class="btn close" type="danger" @click="close()"> <i class="icon-xmark-solid"></i></el-button>
        </div>
        <div class="form">
          <el-row :gutter="24">
            <el-col class="input-container" :sm="8">
              <div class="text">Company Name</div>
              <el-input v-model="form.CompanyName" placeholder="..."></el-input>
              <span class="error-validation">{{ $errorMessage("CompanyName", validation) }}</span>
            </el-col>
            <el-col class="input-container" :sm="8">
              <div class="text">First Name</div>
              <el-input v-model="form.FirstName" placeholder="..."></el-input>
              <span class="error-validation">{{ $errorMessage("FirstName", validation) }}</span>
            </el-col>
            <el-col class="input-container" :sm="8">
              <div class="text">Last Name</div>
              <el-input v-model="form.LastName" placeholder="..."></el-input>
              <span class="error-validation">{{ $errorMessage("LastName", validation) }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col class="input-container" :sm="8">
              <div class="text">Contact Number</div>
              <el-input v-model="form.ContactNumber" placeholder="07776668899" @change="checkEmailAndContactNumber()"></el-input>
              <span class="error-validation">{{ $errorMessage("ContactNumber", validation) }}</span>
              <span class="property-warning" v-if="contactWarning">This contact number is used by another customer.</span>
            </el-col>
            <el-col class="input-container email" :sm="8">
              <div class="text">Email</div>
              <el-input v-model="form.Email" placeholder="..." @change="checkEmailAndContactNumber()"></el-input>
              <span class="error-validation">{{ $errorMessage("Email", validation) }}</span>
              <span class="property-warning"  v-if="emailWarning">This e-mail address is used by another customer.</span>
            </el-col>
            <el-col class="input-container" :sm="8">
              <div class="text">Account Manager</div>
              <el-select v-model="form.AccountManagerId" :disabled="user.Role==1" placeholder="Select">
                <el-option v-for="(item, index) in userList.filter((x) => (x.Role == 10 || x.Role == 1) && x.IsDeactivated != true)" :key="index" :value="item.ID" :label="item.FirstName + ' ' + item.LastName"> </el-option>
              </el-select>
              <!-- <el-select v-model="form.AccountManagerId" v-else placeholder="Select">
                <el-option v-for="(item, index) in userList.filter((x) => x.ID==user.ID)" :key="index" :value="item.ID" :label="item.FirstName + ' ' + item.LastName" disabled> </el-option>
              </el-select> -->
              <span class="error-validation">{{ $errorMessage("AccountManager", validation) }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col class="input-container" :sm="8">
              <div class="text">Industry</div>
              <el-select v-model="form.IndustryId" placeholder="Select" v-if="getIndustries">
                <el-option v-for="(item, index) in getIndustries" :key="index" :value="item.ID" :label="item.Title"> </el-option>
              </el-select>
              <span class="error-validation">{{ $errorMessage("IndustryId", validation) }}</span>
            </el-col>
            <el-col class="input-container" :sm="8">
              <div class="text">Channel</div>
              <el-select v-model="form.Channel" placeholder="Select" v-if="getEnums">
                <el-option v-for="(item, index) in getEnums.find((x) => x.Title == 'CustomerChannel').Enums" :key="item.Key + index" :label="item.DisplayName" :value="item.Key"> </el-option>
              </el-select>
              <span class="error-validation">{{ $errorMessage("Channel", validation) }}</span>
            </el-col>
            <el-col class="input-container" :sm="8">
              <div class="text">Priority</div>
              <el-select v-model="form.Priority"  placeholder="Select">
                <el-option v-for="(item, index) in PriorityAlternatives" :key="index" :value="item.Priority" :label="item.Priority" >
                  <i :class="item.Icon"></i> {{ item.Priority }}
                </el-option>
              </el-select>
              <span class="error-validation">{{ $errorMessage("Priority", validation) }}</span>
            </el-col>
          </el-row>
          <div class="line"></div>
          <div class="title">Address<span class="yellow-dot">.</span></div>
          <el-row :gutter="24">
            <el-col class="input-container" :sm="24">
              <div class="text">Address</div>
              <el-input class="text-area" v-model="form.Address" type="textarea" :rows="5" placeholder="..."></el-input>
              <span class="error-validation">{{ $errorMessage("Address", validation) }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col class="input-container" :sm="8">
              <div class="text">Country</div>
              <el-select
                v-model="form.CountryId"
                filterable
                v-if="getAllCountries"
                @clear="
                  form.CountryId = null;
                  form.CityId = null;
                "
                clearable
              >
                <el-option v-for="(item, index) in getAllCountries" :key="item.country_id + index" :label="item.country" :value="item.country_id"> </el-option>
              </el-select>
              <span class="error-validation">{{ $errorMessage("CountryId", validation) }}</span>
            </el-col>
            <el-col class="input-container" :sm="8">
              <div class="text">City</div>
              <el-select v-model="form.CityId" filterable placeholder="Select" @clear="form.CityId = null" clearable :disabled="form.CountryId == null" v-if="getAllCountries">
                <template v-if="getAllCountries.some((x) => x.country_id == form.CountryId)">
                  <el-option v-for="item in getAllCountries.find((x) => x.country_id == form.CountryId).cities" :key="item.city_id" :label="item.name" :value="item.city_id"> </el-option>
                </template>
              </el-select>
              <span class="error-validation">{{ $errorMessage("CityId", validation) }}</span>
            </el-col>
            <el-col class="input-container" :sm="8">
              <div class="text">Post Code</div>
              <el-input class="postcode" v-model="form.PostalCode" placeholder="..."></el-input>
              <span class="error-validation">{{ $errorMessage("PostalCode", validation) }}</span>
            </el-col>
          </el-row>

          <div class="line"></div>

          <el-row :gutter="24" v-if="getInterestProductsList.length > 0">
            <el-col class="input-container" :sm="8">
              <div class="title" style="margin-bottom: 16px">Interested Products<span class="yellow-dot">.</span></div>
              <div class="text">Select Interested Products</div>
              <el-select v-model="form.InterestedProductIds" filterable multiple placeholder="Select Multi">
                <el-option v-for="product in getInterestProductsList" :key="product.ID" :value="product.ID" :label="product.Title"> </el-option>
              </el-select>
              <span class="error-validation">{{ $errorMessage("InterestedProductIds", validation) }}</span>
            </el-col>
          </el-row>

          <div class="line"></div>
          
          <template v-if="form.CurrentCompanyInformations.length > 0">
            <div class="product-inf-component">
              <div class="top">
                <div class="title">Companies<span class="yellow-dot">.</span></div>
              </div>
              <el-collapse accordion>
                <el-collapse-item v-for="(item, index) in form.CurrentCompanyInformations" :key="'companies' + index">
                  <template slot="title">
                    <div class="row-title">
                      <div>
                        Company Detail {{ index + 1 }} <span class="name" v-if="item"> {{ item.RegisteredCompanyName }} </span>
                      </div>
                      <div class="icon">
                        <div class="icon-row-trash" @click="deleteNewCurrentCompanyInformation(index)"></div>
                      </div>
                    </div>
                  </template>
                  <template>
                    <div class="form">
                      <el-row :gutter="24">
                        <el-col class="input-container" :sm="8">
                          <div class="text">CRO</div>
                          <el-input v-model="item.CompanyRegistrationNo" placeholder="..." v-numeric></el-input>
                          <span class="error-validation">{{ $errorMessage("CurrentCompanyInformations["+index+"].CompanyRegistrationNo", validation) }}</span>
                        </el-col>
                        <el-col class="input-container" :sm="8">
                          <div class="text">Registered Company Name</div>
                          <el-input v-model="item.RegisteredCompanyName" placeholder="..."></el-input>
                          <span class="error-validation">{{ $errorMessage("CurrentCompanyInformations["+index+"].RegisteredCompanyName", validation) }}</span>
                        </el-col>
                      </el-row>
                      <el-row :gutter="24">
                        <el-col class="input-container" :sm="24">
                          <div class="text">Registered Address</div>
                          <el-input class="customer-note text-area" v-model="item.RegisteredAddress" type="textarea" :rows="2" placeholder="..."></el-input>
                          <span class="error-validation">{{ $errorMessage("CurrentCompanyInformations["+index+"].RegisteredAddress", validation) }}</span>
                        </el-col>
                      </el-row>
                      <el-row :gutter="24">
                      <el-col class="input-container" :sm="8">
                        <div class="text">Country</div>
                        <el-select
                          v-model="item.RegisteredCountryId"
                          filterable
                          v-if="getAllCountries"
                          @clear="
                            item.RegisteredCountryId = null;
                            item.RegisteredCityId = null;
                          "
                          clearable
                        >
                          <el-option v-for="(item, index) in getAllCountries" :key="item.country_id + index" :label="item.country" :value="item.country_id"> </el-option>
                        </el-select>
                        <span class="error-validation">{{ $errorMessage("CurrentCompanyInformations["+index+"].RegisteredCountryId", validation) }}</span>
                      </el-col>
                      <el-col class="input-container" :sm="8">
                        <div class="text">City</div>
                        <el-select v-model="item.RegisteredCityId" filterable placeholder="Select" @clear="item.RegisteredCityId = null" clearable :disabled="item.RegisteredCountryId == null" v-if="getAllCountries">
                          <template v-if="getAllCountries.some((x) => x.country_id ==item.RegisteredCountryId)">
                            <el-option v-for="item in getAllCountries.find((x) => x.country_id == item.RegisteredCountryId).cities" :key="item.city_id" :label="item.name" :value="item.city_id"> </el-option>
                          </template>
                        </el-select>
                        <span class="error-validation">{{ $errorMessage("CurrentCompanyInformations["+index+"].RegisteredCityId", validation) }}</span>
                      </el-col>
                      <el-col class="input-container" :sm="8">
                        <div class="text">Post Code</div>
                        <el-input class="postcode" v-model="item.RegisteredPostalCode" placeholder="..."></el-input>
                        <span class="error-validation">{{ $errorMessage("CurrentCompanyInformations["+index+"].RegisteredPostalCode", validation) }}</span>
                      </el-col>
                    </el-row>
                    </div>
                  </template>
                </el-collapse-item>
              </el-collapse>
            </div>
          </template>
          <div class="product-inf-button-groups">
            <el-button class="add-btn" type="info" @click="createNewCurrentCompanyInformation"> <i class="icon-plus-solid"></i> Add Company Details</el-button>
          </div>

          <div class="line"></div>
          
          <template v-if="form.Notes.length > 0">
            <div class="product-inf-component">
              <div class="top">
                <div class="title">Notes<span class="yellow-dot">.</span></div>
              </div>
              <el-collapse accordion v-if="form.Notes">
                <el-collapse-item v-for="(item, index) in form.Notes" :key="'sample' + index">
                  <template slot="title">
                    <div class="row-title">
                      <div>
                        Customer Note {{ index + 1 }} <span class="name" v-if="item.Note && item.Note.length > 0"> {{ item.Note.slice(0, 100) + (item.Note.length > 100 ? "..." : "") }} </span>
                      </div>
                      <div class="icon">
                        <div class="icon-row-trash" @click="deleteNewCustomerNote(index)"></div>
                      </div>
                    </div>
                  </template>
                  <template>
                    <div class="form">
                      <el-row :gutter="24">
                        <el-col class="input-container" :sm="12">
                          <div class="text">Date</div>
                          <el-date-picker format="dd-MM-yyyy" prefix-icon="icon-calendarplus" v-model="item.CreateDate" type="date" placeholder="Pick a day" :default-value="new Date()"> </el-date-picker>
                          <span class="error-validation">{{ $errorMessage("Notes[" + index + "].CreateDate", validation) }}</span>
                        </el-col>
                      </el-row>
                      <el-row :gutter="24">
                        <el-col class="input-container" :sm="24">
                          <div class="text">Note</div>
                          <el-input class="customer-note text-area" v-model="item.Note" type="textarea" :rows="5" placeholder="Add Customer Note..."></el-input>
                          <span class="error-validation">{{ $errorMessage("Notes[" + index + "].Note", validation) }}</span>
                        </el-col>
                      </el-row>

                      <el-row :gutter="24">
                        <el-col :span="24">
                          <div class="upload input-container">
                            <div class="text">Upload Files</div>
                            <el-upload
                              class="upload-demo"
                              drag
                              :action="$client.defaults.baseURL + 'General/UploadFile?uploadPath=Customer'"
                              :show-file-list="false"
                              :on-progress="
                                () => {
                                  activeIndex = index;
                                }
                              "
                              :on-success="handleSuccess"
                              :headers="{
                                Authorization: $client.defaults.headers.common['Authorization'],
                              }"
                              multiple
                            >
                              <i class="icon-cloudarrow"></i>
                              <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                              <!-- <div class="el-upload__tip" slot="tip">Files with a size less than 5mb</div> -->
                            </el-upload>
                            <!-- <span class="error-validation">{{ $errorMessage("Notes[" + index + "].Files", validation) }}</span> -->
                            <div class="all-files">
                              <div class="files" v-for="(file, documentIndex) in item.Files" :key="documentIndex">
                                <div class="file">
                                  <div class="file-icon">
                                    <img :src="getExtension(file.Filename)" />
                                  </div>
                                  <div class="filecontent">
                                    <div class="file-name">{{ file.Filename }}</div>
                                    <div class="file-size">{{ fileSize(file.FileSize) }}</div>
                                  </div>
                                </div>
                                <div class="icon-status">
                                  <div class="icon-status">
                                    <a :href="file.Url" target="_blank"><i class="icon-download"></i></a>
                                  </div>
                                  <div class="icon-status"><i class="icon-check"></i><i class="icon-xmark" @click="deleteNoteDocument(index,documentIndex)"></i></div>
                                </div>
                               </div>
                            </div>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </template>
                </el-collapse-item>
              </el-collapse>
            </div>
          </template>
          <div class="product-inf-button-groups">
            <el-button class="add-btn" type="info" @click="createNewCustomerNotes"> <i class="icon-plus-solid"></i> Add Customer Note</el-button>
          </div>
          <div class="line"></div>

          <template v-if="form.Calls.length > 0">
            <div class="product-inf-component">
              <div class="top">
                <div class="title">Calls<span class="yellow-dot">.</span></div>
              </div>
              <el-collapse accordion>
                <el-collapse-item v-for="(item, index) in form.Calls" :key="'calls' + index">
                  <template slot="title">
                    <div class="row-title">
                      <div>
                        Call {{ index + 1 }} <span class="name" v-if="item.Title && item.Title.length > 0"> {{ item.Title.slice(0, 50) + (item.Title.length > 50 ? "..." : "") }} </span>
                      </div>
                      <div class="icon">
                        <div class="icon-row-trash" @click="deleteNewCall(index)"></div>
                      </div>
                    </div>
                  </template>

                  <div class="call component">
                    <div class="line"></div>

                    <el-row :gutter="24">
                      <el-col class="input-container" :sm="12">
                        <div class="text">Call Date</div>
                        <el-date-picker format="dd-MM-yyyy" :default-value="new Date()" prefix-icon="icon-calendarplus" v-model="item.CallDate" type="date" :key="'sample-date-' + index" placeholder="Pick a day"> </el-date-picker>
                        <span class="error-validation">{{ $errorMessage("Calls[" + index + "].CallDate", validation) }}</span>
                      </el-col>
                    </el-row>
                    <!-- <el-row :gutter="24">
                      <el-col class="input-container" :sm="12">
                        <div class="text">Description</div>
                        <el-input v-model="item.Title" placeholder="..."></el-input>
                        <span class="error-validation">{{ $errorMessage("Calls[" + index + "].Title", validation) }}</span>
                      </el-col>
                    </el-row> -->
                    <el-row :gutter="24">
                      <el-col class="input-container" :sm="24">
                        <div class="text">Note</div>
                        <el-input class="customer-note text-area" v-model="item.Note" type="textarea" :rows="5" placeholder="Call Note..."></el-input>
                        <span class="error-validation">{{ $errorMessage("Calls[" + index + "].Note", validation) }}</span>
                      </el-col>
                    </el-row>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </template>
          <div class="product-inf-button-groups">
            <el-button class="add-btn" type="info" @click="createNewCall"><i class="icon-plus-solid"></i> Add Call </el-button>
          </div>
          <div class="line"></div>

          <template v-if="form.Samples.length > 0">
            <div class="product-inf-component">
              <div class="top">
                <div class="title">Product Sample<span class="yellow-dot">.</span></div>
              </div>
              <el-collapse accordion>
                <el-collapse-item v-for="(item, index) in form.Samples" :key="'samples' + index">
                  <template slot="title">
                    <div class="row-title">
                      <div>
                        Product Sample {{ index + 1 }} <span class="name" v-for="(id, index) in item.SampleIds" :key="id + index">{{ getInterestProductsList.find((x) => x.ID == id).Title }}</span>
                      </div>
                      <div class="icon">
                        <div class="icon-row-trash" @click="deleteNewProductSample(item)"></div>
                      </div>
                    </div>
                  </template>
                  <template>
                    <div class="form">
                      <el-row :gutter="24">
                        <el-col class="input-container" :sm="12">
                          <div class="text">Date</div>
                          <el-date-picker format="dd-MM-yyyy" :default-value="new Date()" prefix-icon="icon-calendarplus" v-model="item.SentDate" type="date" placeholder="Pick a day"> </el-date-picker>
                          <span class="error-validation">{{ $errorMessage("Samples[" + index + "].SentDate", validation) }}</span>
                        </el-col>
                      </el-row>

                      <el-row :gutter="24">
                        <el-col class="input-container" :sm="12">
                          <div class="text">Select Products</div>
                          <el-select v-model="item.SampleIds" filterable multiple placeholder="Select Products" v-if="getInterestProductsList" @clear="item.SampleIds == null" clearable>
                            <el-option v-for="product in getInterestProductsList" :key="product.ID" :value="product.ID" :label="product.Title"> </el-option>
                          </el-select>
                          <span class="error-validation">{{ $errorMessage("Samples[" + index + "].SampleIds", validation) }}</span>
                        </el-col>
                      </el-row>
                      <el-row :gutter="24">
                        <el-col class="input-container" :sm="24">
                          <div class="text">Note</div>
                          <el-input class="customer-note text-area" v-model="item.Note" type="textarea" :rows="5" placeholder="Add Product Sample Note..."></el-input>
                          <span class="error-validation">{{ $errorMessage("Samples[" + index + "].Note", validation) }}</span>
                        </el-col>
                      </el-row>
                    </div>
                  </template>
                </el-collapse-item>
              </el-collapse>
            </div>
          </template>
          <div class="product-inf-button-groups">
            <el-button class="add-btn" type="info" @click="createNewProductSample"> <i class="icon-plus-solid"></i> Add Product Sample</el-button>
          </div>

          <!-- <div class="dialog-title" style="border: none; padding: 0">
            <div class="head-title">Product Informations<span class="yellow-dot">.</span></div>
          </div>

          <template v-if="form.Products && form.Products.length > 0">
            <div class="product-inf-component">
              <el-collapse accordion>
                <el-collapse-item v-for="(item, index) in form.Products" :key="index">
                  <template slot="title">
                    <div class="row-title">
                      <div>
                        ITEM {{ index + 1 }} <span class="name"> {{ item.Title }} </span>
                      </div>
                      <div class="icon">
                        <div class="icon-row-trash" @click="deleteNewProduct(index)"></div>
                      </div>
                    </div>
                  </template>
                  <template>
                    <div class="form">
                      <el-row>
                        <div class="input-container-row">
                          <el-switch v-model="item.SelectType" active-text="Product Name" inactive-text="Select Products"> </el-switch>
                        </div>
                      </el-row>

                      <el-row :gutter="24">
                        <el-col :span="8" class="input-container" v-if="!item.SelectType">
                          <div class="text">Select Product</div>
                          <el-select @change="createDescription(index)" placeholder="Select Product" v-if="sampleProductList" v-model="form.Products[index]" value-key="ID">
                            <el-option v-for="product in sampleProductList" :key="product.ID" :value="product" :label="product.Description"></el-option>
                          </el-select>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].ID", validation) }}</span>
                        </el-col>
                        <el-col :sm="8" class="input-container" v-if="item.SelectType">
                          <div class="text">Products Name</div>
                          <el-input @change="createDescription(index)" v-model="item.Title" placeholder="..."></el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Title", validation) }}</span>
                        </el-col>
                        <el-col :sm="16" class="input-container">
                          <div class="text">Description</div>
                          <el-input v-model="item.Description" placeholder="..."></el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Title", validation) }}</span>
                        </el-col>
                      </el-row>
                      <el-alert title="info alert" type="info" description="more text description" show-icon> </el-alert>
                      <el-switch @change="resetDiameter(index)" v-model="item.Diameter" active-text="Other" inactive-text="W-L-H"></el-switch>
                      <el-row :gutter="24" v-if="!item.Diameter">
                        <el-col class="input-container" :sm="8">
                          <div class="text">Width</div>
                          <el-input @change="createDescription(index)" v-model="item.Width" placeholder="...">
                            <div slot="append">CM</div>
                          </el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Width", validation) }}</span>
                        </el-col>
                        <el-col class="input-container" :sm="8">
                          <div class="text">Length</div>
                          <el-input @change="createDescription(index)" v-model="item.Length" placeholder="...">
                            <div slot="append">CM</div>
                          </el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Length", validation) }}</span>
                        </el-col>
                        <el-col class="input-container" :sm="8">
                          <div class="text">Height</div>
                          <el-input @change="createDescription(index)" v-model="item.Height" placeholder="...">
                            <div slot="append">CM</div>
                          </el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Height", validation) }}</span>
                        </el-col>
                      </el-row>
                      <el-row :gutter="24" v-if="item.Diameter">
                        <el-col class="input-container" :sm="8">
                          <div class="text">Other</div>
                          <el-input @change="createDescription(index)" placeholder="..." class="input-with-select" v-model="item.DiameterValue">
                            <el-select @change="createDescription(index)" v-model="item.Unit" slot="append" class="append-diameter" placeholder="..." clearable @clear="item.Unit == null">
                              <el-option v-for="(item, index) in getEnums.find((x) => x.Title == 'Unit').Enums" :key="item.Key + index" :label="item.DisplayName" :value="item.Key"></el-option>
                            </el-select>
                          </el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].DiameterValue", validation) }}</span>
                        </el-col>
                      </el-row>

                      <el-row :gutter="24">
                        <el-col class="input-container" :sm="8">
                          <div class="text">Print</div>
                          <el-input v-model="item.Print" placeholder="..." class="select"> </el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Print", validation) }}</span>
                        </el-col>
                        <el-col class="input-container" :sm="8">
                          <div class="text">Alternative Print Options</div>
                          <el-select v-model="selectedPrint" @change="updateAlternativePrint(index)" placeholder="Select" class="select">
                            <el-option v-for="(alternativePrints, alternativePrintIndex) in PrintAlternatives" :key="alternativePrintIndex + '-print'" :label="alternativePrints.AlternativePrint" :value="alternativePrints.AlternativePrint"> </el-option>
                          </el-select>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Print", validation) }}</span>
                        </el-col>
                      </el-row>
                      <el-row :gutter="24">
                        <el-col class="input-container" :sm="8">
                          <div class="text">Quality</div>
                          <el-input v-model="item.Quality" placeholder="..." class="select"> </el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Quality", validation) }}</span>
                        </el-col>
                      </el-row>
                      <el-row :gutter="24">
                        <el-col class="input-container" :sm="8">
                          <div class="text">Type</div>
                          <el-input v-model="item.Type" placeholder="..." class="select"> </el-input>
                          <span class="error-validation">{{ $errorMessage("Products[" + index + "].Type", validation) }}</span>
                        </el-col>
                      </el-row>

                      <div class="line"></div>

                      <el-row :gutter="24">
                        <el-col class="input-container-row" :sm="24">
                          <el-col :span="8"> <div class="text">Unit Price</div> </el-col>
                          <el-col :span="16">
                            <el-input v-model="item.UnitPrice" placeholder="..."></el-input>
                            <span class="error-validation">{{ $errorMessage("Products[" + index + "].UnitPrice", validation) }}</span>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-row :gutter="24">
                        <el-col class="input-container-row" :sm="24">
                          <el-col :span="8"> <div class="text">Quantity</div> </el-col>
                          <el-col :span="16">
                            <el-input v-model="item.Quantity" placeholder="..."></el-input>
                            <span class="error-validation">{{ $errorMessage("Products[" + index + "].Quantity", validation) }}</span>
                          </el-col>
                        </el-col>
                      </el-row>
                      <template>
                        <div class="title" v-if="item.UnitPriceQuantityAlternatives.length > 0">Alternative Unit Price and Quantity Informations<span class="yellow-dot">.</span></div>
                        <div v-for="(alternatives, alternativeIndex) in item.UnitPriceQuantityAlternatives.slice(1)" :key="alternativeIndex + 'unit-price-quantity'" class="mini-form">
                          <el-row :gutter="24">
                            <el-col :sm="24">
                              <el-col :span="24" class="input-container-row">
                                <div class="text">Alternative {{ alternativeIndex + 1 }}</div>
                                <div class="icon-row-trash" style="cursor: pointer; margin-left: 16px" @click="deleteAlternativeValues(index, alternativeIndex)"></div>
                              </el-col>
                            </el-col>
                          </el-row>

                          <el-row :gutter="24">
                            <el-col class="input-container-row" :sm="24">
                              <el-col :span="8"> <div class="text">Unit Price</div> </el-col>
                              <el-col :span="16">
                                <el-input v-model="alternatives.AlternativeUnitPrice" placeholder="..."></el-input>
                              </el-col>
                            </el-col>
                          </el-row>
                          <el-row :gutter="24">
                            <el-col class="input-container-row" :sm="24">
                              <el-col :span="8"> <div class="text">Quantity</div> </el-col>
                              <el-col :span="16">
                                <el-input v-model="alternatives.AlternativeQuantity" placeholder="..."></el-input>
                              </el-col>
                            </el-col>
                          </el-row>
                          <div class="line"></div>
                        </div>
                        <div class="product-inf-button-groups">
                          <el-button class="add-btn" type="info" @click="createNewAlternativeValues(index)"> <i class="icon-plus-solid"></i>Add New Alternative Values</el-button>
                        </div>
                      </template>
                    </div>
                  </template>
                </el-collapse-item>
              </el-collapse>
            </div>
          </template>

          <div class="product-inf-button-groups">
            <el-button class="add-btn" type="info" @click="createNewProduct"> <i class="icon-plus-solid"></i>Add New Item</el-button>
          </div> -->
          <div class="line"></div>

          <el-tabs type="border-card" :stretch="true" v-if="form.ID != null">
            <el-tab-pane>
              <span slot="label"
                ><i class="icon-arrows-rotate-solid"></i>Updates<span class="notifications">{{ form.Histories.length > 9 ? "9+" : form.Histories.length }}</span></span
              >

              <div class="updates-timeline form">
                <el-timeline>
                  <el-timeline-item :timestamp="$moment(item.Date).format('MMMM Do YYYY, h:mm:ss A')" placement="top" v-for="(item, index) in form.Histories" :key="index">
                    <el-card>
                      <div class="content">{{ item.Note }}</div>
                      <div class="updates-bottom">
                        <div class="bottom">
                          <div class="circle">{{ item.Employee.FirstName[0] + "" + item.Employee.LastName[0] }}</div>
                          <div class="user">
                            <div class="user-info">{{ item.Employee.FullName }}</div>
                            <div class="status">
                              <span>{{ getEnumsDisplay("TypeOfAuthority", item.Employee.Role) }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </el-timeline-item>
                </el-timeline>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="dialog-footer footerbutton">
          <el-button class="btn" type="danger" @click="close()"> <i class="icon-xmark-solid"></i> Cancel</el-button>
          <el-button type="primary" @click="addUpdate" :loading="loading == 'saveLoading'" :icon="form.ID != null ? 'el-icon-edit' : 'el-icon-plus'">{{ form.ID != null ? "Save Changes" : "Add Potential Customer" }}</el-button>
        </div>
      </el-row>
    </transition>
  </div>
</template>

<script>

export default {
  props: ["id"],
  name: "potential-customer-add-update",
  data() {
    return {
      PrintAlternatives: [
        {
          AlternativePrint: " Customer Design ",
        },
        {
          AlternativePrint: " Color 1 ",
        },
        {
          AlternativePrint: " Color 2 ",
        },
      ],
      PriorityAlternatives: [
        {
          Priority: "High",
          Icon: "priority-reddot"
        },
        {
          Priority: "Mid",
          Icon: "priority-bluedot"
        },
        {
          Priority: "Low",
          Icon: "priority-greendot"
        },
      ],
      selectedPrint: null,
      form: null,
      activeIndex: null,
      leadId: null,
      loading: false,
      timeout: null,
      emailWarning:false,
      contactWarning:false,
    };
  },
  async created() {
    await Promise.all([this.$store.dispatch("getIndustries"), this.$store.dispatch("getInterestProducts"), this.$store.dispatch("getUserList", { Page: 1, PageSize: 999999, SearchTerm: null }),this.$store.dispatch("getProductSamplesList", { Page: 1, PageSize: 999999, SearchTerm: null })]);
    await this.checkedId();
  },
  
  methods: {
    checkEmailAndContactNumber(){
      var allCustomers = this.customerList.concat(this.potentialCustomersList);
      if(allCustomers.some((x)=>x.Email==this.form.Email) && this.form.Email!=null && this.form.Email!=""){
        this.emailWarning=true;
      }else{
        this.emailWarning=false;
      }
      if(allCustomers.some((x)=>x.ContactNumber==this.form.ContactNumber && this.form.ContactNumber!=null && this.form.ContactNumber!="")){
        this.contactWarning=true;
      }else{
        this.contactWarning=false;
      }
    },
    emptyIfNull(val) {
      return val || "";
    },
    createDescription(index) {
      var x;
      if (this.form.Products[index].Unit == 0) {
        x = '"';
      } else if (this.form.Products[index].Unit == 1) {
        x = "oz";
      } else if (this.form.Products[index].Unit == 2) {
        x = "";
      } else if (this.form.Products[index].Unit == 3) {
        x = "cc";
      } else {
        x = "";
      }
      if (x != "") {
        x = " " + x + " ";
      }
      if (this.form.Products[index].Diameter) {
        this.form.Products[index].Description = "Bespoke " + this.emptyIfNull(this.form.Products[index].DiameterValue) + x + this.form.Products[index].Title;
      } else {
        this.form.Products[index].Description = "Bespoke " + this.emptyIfNull(this.form.Products[index].Width) + "x" + this.emptyIfNull(this.form.Products[index].Length) + (this.form.Products[index].Height ? "x" + this.form.Products[index].Height : "") + "cm " + this.form.Products[index].Title;
      }
    },
    handleSuccess(response) {
      var obj = {
        Filename: response.FileName,
        Url: response.Url,
        FileSize: response.FileSize,
      };
      this.form.Notes[this.activeIndex].Files.push(obj);
    },
    async getLeadId() {
      var res = await this.$client.post("/PotentialCustomer/GenerateLeadId");
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.leadId = res.data.Data;
      }
    },
    // deleteNewProduct(index) {
    //   setTimeout(() => {
    //     this.form.Documents.splice(index, 1);
    //   }, 100);
    // },
    deleteNewProduct(index) {
      this.form.Products.splice(index, 1);
    },
    createNewAlternativeValues(index) {
      if (this.form.Products[index].UnitPriceQuantityAlternatives.length == 0) {
        this.form.Products[index].UnitPriceQuantityAlternatives.push({
          AlternativeUnitPrice: 0,
          AlternativeQuantity: 0,
        });
      }
      this.form.Products[index].UnitPriceQuantityAlternatives.push({
        AlternativeUnitPrice: 0,
        AlternativeQuantity: 0,
      });
    },
    close(id) {
      this.$emit("close", id);
    },
    async addUpdate() {
      this.$store.commit("setPotentialCustomerValidation", []);
      // if (this.form.Channel == null) {
      //   this.validation.push({
      //     Key: "Channel",
      //     Value: "Please fill this Channel field",
      //   });
      //   this.$message.error("Please check this wrong field");
      // }
      if (this.validation.length > 0) {
        
        return;
      }
     
      // if(this.user.Role==1){
      //   this.form.AccountManagerId=this.user.ID;
      // }
      this.loading = "saveLoading";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var res = await this.$store.dispatch("addPotentialCustomer", this.form);
        if (!res.HasError) {
          this.form = JSON.parse(JSON.stringify(this.potentialCustomerForm));
          await this.getLeadId();
          // await this.$store.dispatch("getPotentialCustomersList", { Page: 1, PageSize: 999999, SearchTerm: null });
          this.close(res.Data);
        }
        this.loading = false;
      }, 500);
    },
    createNewCustomerNotes() {
      this.form.Notes.push({
        Note: null,
        Files:[],
        CreateDate: new Date(),
      });
    },
    deleteNoteDocument(index,documentIndex) {
      this.form.Notes[index].Files.splice(documentIndex, 1);
    },
    createNewCall() {
      this.form.Calls.push({
        CallDate: new Date(),
        Title: null,
        Note: null,
      });
    },
    updateAlternativePrint(index) {
      this.form.Products[index].Print = this.selectedPrint;

      this.selectedPrint = null;
    },
    createNewDocument() {
      this.form.Documents.push({
        DocumentDate: null,
        Title: null,
        Files: [],
      });
    },
    deleteNewCustomerNote(index) {
      setTimeout(() => {
        this.form.Notes.splice(index, 1);
      }, 100);
    },
    deleteNewCall(index) {
      this.form.Calls.splice(index, 1);
    },
    createNewProductSample() {
      this.form.Samples.push({
        SentDate: new Date(),
        SampleIds: [],
        Note: null,
      });
    },
    createNewCurrentCompanyInformation() {
      this.form.CurrentCompanyInformations.push({
        CompanyRegistrationNo:null,
        RegisteredAddress:null,
        RegisteredCompanyName:null,
        RegisteredCountryId:2635167,
        RegisteredCityId:null,
        RegisteredPostalCode:null
      });
    },
    deleteNewCurrentCompanyInformation(index) {
      this.form.CurrentCompanyInformations.splice(index, 1);
    },
    createNewProduct() {
      this.form.Products.push({
        ID: null,
        ProductNo: null,
        Diameter: false,
        Width: 0,
        Length: 0,
        Height: 0,
        DiameterValue: 0,
        Unit: null,
        Print: null,
        Quality: null,
        IsProduced: false,
        Title: null,
        SelectType: true,
        UnitPriceQuantityAlternatives: [],
        QualityAlternatives: [],
      });
    },
    async getPotentialCustomer() {
      var res = await this.$store.dispatch("getOne", this.id);
      if (res.HasError) {
        this.close();
      } else {
        if (res.Data != null) {
          this.form = res.Data;
        } else {
          this.close();
        }
      }
    },
    deleteNewProductSample(index) {
      this.form.Samples.splice(index, 1);
    },
    async checkedId() {
      this.loading = "addUpdateScreen";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        await this.$store.commit("setPotentialCustomerValidation", []);

        if (this.id) {
          await this.getPotentialCustomer();
        } else {
          this.form = JSON.parse(JSON.stringify(this.potentialCustomerForm));
          this.form.AccountManagerId=this.user.ID
        }
        if (this.form.ID == null && this.form) {
          await this.getLeadId();
        }
        this.loading = false;
      }, 500);
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    getInterestProductsList() {
      return this.$store.getters.getInterestProducts;
    },
    sampleProductList() {
      return this.$store.getters.getProductSamplesList;
    },
    getAllCountries() {
      return this.$store.getters.getAllCountries;
    },
    getEnums() {
      return this.$store.getters.getEnums;
    },
    userList() {
      return this.$store.getters.getUserList;
    },
    validation() {
      return this.$store.getters.getPotentialCustomersValidationErrors;
    },
    potentialCustomerForm() {
      return this.$store.getters.getPotentialCustomersForm;
    },
    getIndustries() {
      return this.$store.getters.getIndustries;
    },
    potentialCustomersList() {
      return this.$store.getters.getPotentialCustomersList;
    },
    customerList() {
      return this.$store.getters.getCustomersList;
    },
  },
};
</script>

<style></style>
