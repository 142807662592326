import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  productSamplesList: [],
  productSample: {},
  filter: {
    Page: 1,
    PageSize: 9999999,
    SearchTerm: null,
    RealProducts:false,
    AccountNo: null
   
  },
  productSamplesTotal: {
    PageCount: null,
    TotalCount: null,
  },
  productSampleForm: {
    ID: null,
    productNo: null,
    Diameter: false,
    DiameterValue: 0,
    Width: 0,
    Length: 0,
    Height: 0,
    Print: null,
    Quality: null,
    UnitPrice: 0,
    Histories: [],
    IsProduced: false,
    Title: null,
    SelectType: true,
    UnitPriceHistories: [],
    
  },
  productSampleValidation: [],
};

export default {
  state,
  getters,
  actions,
  mutations,
};
