export default {
    setOrderList(state, data) {
        state.orderList = data;
    },
    setOneOrder(state, data) {
        state.orderOne = data;
    },
    setOrderValidation(state, data) {
        state.orderValidation = data;
    }
}