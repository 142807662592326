import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  reportList:{
    leadCount: {
      CountTotal: null,
      CountLast30: null,
      CountLast7: null,
      CountToday: null
    },
    
    quotationsCount:{
      CountTotal: null,
      CountLast30: null,
      CountLast7: null,
      CountToday: null
    },
    proformaCount:{
      ProformaWonCount: null,
      ProformaLostCount: null,
      CountTotal: null,
      CountLast30: null,
      CountLast7: null,
      CountToday: null
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};