import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import OneSignalVue from "onesignal-vue";
// import "element-ui/lib/theme-chalk/index.css";
import "./assets/PsElementTheme/theme/index.css";
import locale from "element-ui/lib/locale/lang/en";
import PortalVue from "portal-vue";
import jQuery from "jquery";
import moment from "moment";
Vue.config.productionTip = false;
import VueMask from "v-mask";
import  "@lottiefiles/lottie-player";
import VueCookies from 'vue-cookies';


Vue.use(VueCookies)
Vue.use(VueMask);
import Trend from "vuetrend"
Vue.use(Trend)
Vue.use(ElementUI, { locale });
Vue.use(PortalVue);
moment.locale("en");
Vue.use(OneSignalVue);
Vue.prototype.$moment = moment;
Vue.prototype.jQuery = jQuery;
Vue.prototype.getEnumsDisplay = function (category, key) {
  if(this.$store.getters.getEnums.find((x) => x.Title == category).Enums.find((x) => x.Key == key)==undefined){
    console.log(this.$store.getters.getEnums.find((x) => x.Title == category))
    console.log(category)
    console.log(key)
  }
  return this.$store.getters.getEnums.find((x) => x.Title == category).Enums.find((x) => x.Key == key).DisplayName;
};
import countryJson from "./helpers/countryCode.json";
Vue.prototype.countryCodes = countryJson;
Vue.prototype.fileSize = function (bytes) {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(2) + " GB";
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(2) + " MB";
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(2) + " KB";
  } else if (bytes > 1) {
    bytes = bytes + " bytes";
  } else if (bytes == 1) {
    bytes = bytes + " byte";
  } else {
    bytes = "0 bytes";
  }
  return bytes;
};

Vue.prototype.DatePicker = { 
  datePicker: { 
    pickerOptions: { 
      firstDayOfWeek: 1 // sets Monday as the first day of the week
    } 
  } 
};
Vue.prototype.getExtension = function (extension) {
  var extensions = /[^.]+$/.exec(extension);
  var returned = null;
  
  if (extensions[0] == "pdf") {
    returned = "/static/file/file-pdf-solid.svg";
  } else if (extensions[0] == "docx" || extensions[0] == "doc") {
    returned = "/static/file/file-word-solid.svg";
  } else if (extensions[0] == "xlsx" || extensions[0] == "xls") {
    returned = "/static/file/file-excel-solid.svg";
  } else if (extensions[0] == "jpg" || extensions[0] == "jpeg" || extensions[0] == "png") {
    returned = "/static/file/file-image-solid.svg";
  } else {
    returned = "/static/file/Unknown.svg";
  }
  return returned;
};

Vue.prototype.$errorMessage = (key, ValidationErrors) => {
  if (ValidationErrors.some((k) => k.Key == key)) {
    return ValidationErrors.find((k) => k.Key == key).Value;
  } else {
    return null;
  }
};

Vue.directive('numeric', {
  bind: function (el) {
    // el.addEventListener('input', function (event) {
    //   event.target.value = event.target.value.replace(/[^.\d]/g, '');
    //   const inputEvent = new Event("input", { bubbles: true });
    //   if(inputEvent !=null){
    //     el.removeEventListener('input');
    //     event.target.dispatchEvent(inputEvent);
    //   }
    // });
    el.addEventListener('input', inputHandler);
    function inputHandler(event) {
      event.target.value = event.target.value.replace(/[^.\d]/g, '');
      const inputEvent = new Event("input", { bubbles: true });
      if(inputEvent !=null){
        el.removeEventListener('input', inputHandler);
        event.target.dispatchEvent(inputEvent);
        el.addEventListener('input', inputHandler);
      }
    }
  }
});


// Vue.directive("formatter", {
//   bind: function(el) {
//     const input = el.querySelector("input");
//     input.addEventListener("input", function(event) {
//       let value = event.target.value;
//       value = value.replace(/[^0-9]/g, ""); // Remove any non-numeric characters
//       value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas to the number
//       event.target.value = value;
      
//     });
//   },
// });

new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeMount() {
    this.$OneSignal.init({
      appId: "280495e2-0a82-4845-bff5-177258250916",
      serviceWorkerParam: { scope: "/push/onesignal/" },
      serviceWorkerPath: "push/onesignal/OneSignalSDKWorker.js",
      serviceWorkerUpdaterPath: "push/onesignal/OneSignalSDKUpdaterWorker.js",
    });
   
  },
}).$mount("#app");
