<template>
  <div class="dialog-container">
    <div class="dialog-title">
      <div class="head-title">Cost Calculator<span class="yellow-dot">.</span></div>
      <el-button class="btn close" type="danger" @click="close"> <i class="icon-xmark-solid"></i></el-button>
    </div>
    <el-row :gutter="24" class="row-element">
      <el-col :md="15" :sm="24" class="left">
        <div class="form">
          <el-row>
            <el-col :sm="24" class="input-container">
              <div class="text">Customer Name</div>
              <el-select v-model="value" placeholder="Select">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-col>
          </el-row>
          <div class="line"></div>
          <div class="title">Product Informations<span class="yellow-dot">.</span></div>
          <div class="product-inf-component">
            <el-collapse accordion>
              <el-collapse-item v-for="(item, index) in addProductList" :key="index">
                <template slot="title">
                  <div class="row-title">
                    <div>
                      ITEM {{ index + 1 }} <span class="name"> {{ item.Title }} </span>
                    </div>
                    <div class="icon">
                      <div class="icon-row-trash" @click="deleteNewProduct(index)"></div>
                    </div>
                  </div>
                </template>
                <div class="form">
                  <el-switch v-model="item.SelectType" active-text="Custom Product" inactive-text="Select Products"> </el-switch>
                  <el-row :gutter="24">
                    <el-col :sm="12">
                      <div class="item-title">Products Name</div>
                      <el-select placeholder="Select">
                        <el-option> </el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                  <!-- <el-alert title="info alert" type="info" description="more text description" show-icon> </el-alert> -->
                  <el-row :gutter="24" v-if="!item.SelectType">
                    <el-col :span="8" class="input-container">
                      <el-select placeholder="Select Product" v-if="productList" v-model="form.Products[index]" value-key="ID">
                        <el-option v-for="product in productList" :key="product.ID" :value="product" :label="product.Title"></el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                  <el-switch v-model="item.Diameter" active-text="W-L-H" inactive-text="Diameter" v-if="item.SelectType"></el-switch>
                  <el-row :gutter="24" v-if="item.Diameter && item.SelectType">
                    <el-col class="input-container" :sm="12">
                      <div class="text">Width</div>
                      <el-input v-model="item.Width" placeholder="...">
                        <div slot="append">CM</div>
                      </el-input>
                    </el-col>
                    <el-col class="input-container" :sm="12">
                      <div class="text">Length</div>
                      <el-input v-model="item.Length" placeholder="...">
                        <div slot="append">CM</div>
                      </el-input>
                    </el-col>
                  </el-row>
                  <el-row :gutter="24" v-if="item.Diameter && item.SelectType">
                    <el-col class="input-container" :sm="12">
                      <div class="text">Height</div>
                      <el-input v-model="item.Height" placeholder="...">
                        <div slot="append">CM</div>
                      </el-input>
                    </el-col>
                  </el-row>
                  <el-row :gutter="24" v-if="!item.Diameter && item.SelectType">
                    <el-col class="input-container" :sm="12">
                      <div class="text">Diameter</div>
                      <el-input v-model="item.Width" placeholder="...">
                        <div slot="append">Ø</div>
                      </el-input>
                    </el-col>
                  </el-row>
                  <el-row :gutter="24" v-if="item.SelectType">
                    <el-col class="input-container" :sm="12">
                      <div class="text">Print</div>
                      <el-input v-model="item.Print" placeholder="Select" class="select"> </el-input>
                    </el-col>
                    <el-col class="input-container" :sm="12">
                      <div class="text">Quality</div>
                      <el-input v-model="item.Quality" placeholder="Select Type" class="select"> </el-input>
                    </el-col>
                  </el-row>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div class="product-inf-button-groups">
            <el-button class="add-btn" type="info" @click="createNewProduct"> <i class="icon-plus-solid"></i>Add New Item</el-button>
          </div>

          <div class="line"></div>
          <div class="title">Product & Transport<span class="yellow-dot">.</span></div>
          <div class="transport-container">
            <div class="transport-title">PRODUCTION</div>
            <div class="inline-input">
              <el-col :span="12"><div class="transport-text">Unit Price</div></el-col>
              <el-col :span="12">
                <el-input v-model="form.test" placeholder="..."></el-input>
              </el-col>
            </div>
            <div class="inline-input">
              <el-col :span="12"><div class="transport-text">Quantity</div></el-col>
              <el-col :span="12">
                <el-input v-model="form.test" placeholder="..."></el-input>
              </el-col>
            </div>
            <div class="inline-input">
              <el-col :span="12"><div class="transport-text">Origination</div></el-col>
              <el-col :span="12">
                <el-input v-model="form.test" placeholder="..."></el-input>
              </el-col>
            </div>
          </div>
          <div class="transport-container">
            <div class="transport-title">INTERNATIONAL TRANSPORT</div>
            <div class="inline-input">
              <el-col :span="12"><div class="transport-text">Cost of Container</div></el-col>
              <el-col :span="12">
                <el-input v-model="form.test" placeholder="..."></el-input>
              </el-col>
            </div>
            <div class="inline-input">
              <el-col :span="12"><div class="transport-text">No of Pallets</div></el-col>
              <el-col :span="12">
                <el-input v-model="form.test" placeholder="..."></el-input>
              </el-col>
            </div>
          </div>
          <div class="transport-container">
            <div class="transport-title">SUMMARY BEFORE UK</div>
            <div class="inline-input">
              <el-col :span="12"><div class="transport-text">Profil Rate</div></el-col>
              <el-col :span="12">
                <el-input v-model="form.test" placeholder="..."></el-input>
              </el-col>
            </div>
          </div>
          <div class="line"></div>
          <div class="title">Storage<span class="yellow-dot">.</span></div>
          <div class="transport-container">
            <div class="transport-title">PICK PACK CALCULATOR</div>
            <div class="inline-input">
              <el-col :span="12"><div class="transport-text">Rate of Pick Pack</div></el-col>
              <el-col :span="12">
                <el-input v-model="form.test" placeholder="..."></el-input>
              </el-col>
            </div>
          </div>
          <div class="transport-container">
            <div class="transport-title">STORAGE AND DELIVERY</div>
            <div class="inline-input">
              <el-col :span="12"><div class="transport-text">Week of Stay</div></el-col>
              <el-col :span="12">
                <el-input v-model="form.test" placeholder="..."></el-input>
              </el-col>
            </div>
            <div class="inline-input">
              <el-col :span="12"><div class="transport-text">Boxes on Pallet</div></el-col>
              <el-col :span="12">
                <el-input v-model="form.test" placeholder="..."></el-input>
              </el-col>
            </div>
            <div class="inline-input">
              <el-col :span="12"><div class="transport-text">DPD Distribution</div></el-col>
              <el-col :span="12">
                <el-input v-model="form.test" placeholder="..."></el-input>
              </el-col>
            </div>
            <div class="inline-input">
              <el-col :span="12"><div class="transport-text">Pallet Delivery</div></el-col>
              <el-col :span="12">
                <el-input v-model="form.test" placeholder="..."></el-input>
              </el-col>
            </div>
          </div>
          <div class="line"></div>
          <div class="title">Total Cost<span class="yellow-dot">.</span></div>
          <div class="transport-container" style="margin-bottom: 24px">
            <div class="transport-title">PRICE</div>
            <div class="inline-input">
              <el-col :span="12"><div class="transport-text">The Profitbility of the Project</div></el-col>
              <el-col :span="12">
                <el-input v-model="form.test" placeholder="..."></el-input>
              </el-col>
            </div>
          </div>
        </div>
      </el-col>
      <div class="vertical-line"></div>
      <el-col :md="9" :sm="24" class="right form">
        <div class="right-card">
          <div class="inline-form">
            <div class="input-group">
              <div class="left">Date:</div>
              <div class="right">08/06/2022</div>
            </div>
            <div class="input-group">
              <div class="left">Customer Name:</div>
              <div class="right">...</div>
            </div>
            <div class="input-group">
              <div class="left">Product:</div>
              <div class="right">
                ... <br />
                ...
              </div>
            </div>
          </div>
        </div>
        <div class="all-cost">
          <el-collapse accordion>
            <el-collapse-item name="1" class="cost production-and-transport">
              <template slot="title">
                <div class="title">Production & Transport Cost<span class="yellow-dot">.</span></div>
              </template>
              <div class="cost-container">
                <div class="title">PRODUCTION</div>
                <div class="inline-form input-text">
                  <div class="">Price</div>
                  <div class="">0</div>
                </div>
                <div class="inline-form input-text">
                  <div class="">Quantity</div>
                  <div class="">0</div>
                </div>
                <div class="inline-form input-text">
                  <div class="">Origination</div>
                  <div class="">0</div>
                </div>
                <div class="inline-form totalcost-text">
                  <el-col :span="15">
                    <div class="text">Total Production Cost</div>
                  </el-col>
                  <el-col :span="1"></el-col>
                  <div class="cost">£10.999 ,99</div>
                </div>
                <div class="title">INTERNATIONAL TRANSPORT</div>
                <div class="inline-form input-text">
                  <div class="">Cost of Container</div>
                  <div class="">0</div>
                </div>
                <div class="inline-form static-text">
                  <div class="">Deferment & Custom Clearence</div>
                  <div class="">0</div>
                </div>
                <div class="inline-form static-text">
                  <div class="">Total Pallets in a Container</div>
                  <div class="">0</div>
                </div>
                <div class="inline-form input-text">
                  <div class="">No of Pallets</div>
                  <div class="">0</div>
                </div>
                <div class="inline-form static-text">
                  <div class="">Cost Per Pallets</div>
                  <div class="">0</div>
                </div>
                <div class="inline-form totalcost-text">
                  <el-col :span="15">
                    <div class="text">Cost of Transportation</div>
                  </el-col>
                  <el-col :span="1"></el-col>
                  <div class="cost">£9999</div>
                </div>
                <div class="title">SUMMARY BEFORE UK</div>
                <div class="inline-form static-text">
                  <el-col :span="12">
                    <div class="">Cost of Operation Before UK Operation</div>
                  </el-col>
                  <el-col :span="5">
                    <div class="">Total</div>
                  </el-col>
                  <el-col :span="6">
                    <div class="bold">£999.54</div>
                  </el-col>
                </div>
                <div class="inline-form static-text">
                  <el-col :span="12">
                    <div class="">Cost of Operation Before UK Operation</div>
                  </el-col>
                  <el-col :span="5">
                    <div class="">per Unit</div>
                  </el-col>
                  <el-col :span="6">
                    <div class="bold">£999,54</div>
                  </el-col>
                </div>
                <div class="inline-form input-text">
                  <el-col :span="12">
                    <div class="">Profil Rate</div>
                  </el-col>
                  <el-col :span="5">
                    <div class="">%</div>
                  </el-col>
                  <el-col :span="6">
                    <div class="bold">54</div>
                  </el-col>
                </div>
                <div class="inline-form totalcost-text">
                  <el-col :span="10">
                    <div class="text">Profit Before UK Operation</div>
                  </el-col>
                  <el-col :span="5">
                    <div class="grey">Total</div>
                  </el-col>
                  <div class="cost">£999.999,54</div>
                </div>
                <div class="inline-form totalcost-text">
                  <el-col :span="10">
                    <div class="text">Profit Before UK Operation</div>
                  </el-col>
                  <el-col :span="5">
                    <div class="grey">per Unit</div>
                  </el-col>
                  <div class="cost">£9999</div>
                </div>
                <div class="inline-form totalcost-text">
                  <el-col :span="10">
                    <div class="text">Price Before UK Operations</div>
                  </el-col>
                  <el-col :span="5">
                    <div class="grey">per Unit</div>
                  </el-col>
                  <div class="cost">£9999</div>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item name="2" class="cost pick-pack">
              <template slot="title">
                <div class="title">Storage Cost<span class="yellow-dot">.</span></div>
              </template>
              <div class="cost-container">
                <div class="title">PICK PACK CALCULATOR</div>
                <div class="inline-form static-text">
                  <div class="">Boxes on Pallet</div>
                  <div class="">0</div>
                </div>
                <div class="inline-form static-text">
                  <div class="">No of Pallet</div>
                  <div class="">0</div>
                </div>
                <div class="inline-form input-text">
                  <div class="">Rate of Pick Pack</div>
                  <div class="">0</div>
                </div>
                <div class="inline-form totalcost-text">
                  <el-col :span="10">
                    <div class="text">Pick Pack Cost</div>
                  </el-col>
                  <div class="cost pickpack">£9999</div>
                </div>

                <div class="title">STORAGE AND DELIVERY</div>
                <div class="inline-form input-text">
                  <div class="">Week of Stay</div>
                  <div class="">0</div>
                </div>
                <div class="inline-form input-text">
                  <div class="">Boxes on Pallet</div>
                  <div class="">0</div>
                </div>
                <div class="inline-form static-text">
                  <div class="">Storage Entry Cost</div>
                  <div class="">0</div>
                </div>
                <div class="inline-form static-text">
                  <div class="">Storage Dispatch Cost</div>
                  <div class="">0</div>
                </div>
                <div class="inline-form static-text">
                  <div class="">Total Storage Cost</div>
                  <div class="">0</div>
                </div>
                <div class="inline-form static-text">
                  <div class="">Pick & Pack Cost</div>
                  <div class="">0</div>
                </div>
                <div class="inline-form static-text">
                  <div class="">Extras (Palletising & etc.)</div>
                  <div class="">0</div>
                </div>
                <div class="inline-form input-text">
                  <div class="">DPD Distribution</div>
                  <div class="">0</div>
                </div>
                <div class="inline-form input-text">
                  <div class="">Pallet Delivery</div>
                  <div class="">0</div>
                </div>
                <div class="inline-form totalcost-text">
                  <div class="text">Total Storage</div>

                  <div class="grey">£9999</div>
                </div>
                <div class="inline-form totalcost-text">
                  <div class="text">Total Delivery</div>
                  <div class="grey">£9999</div>
                </div>
                <div class="inline-form totalcost-text">
                  <el-col :span="10">
                    <div class="text">UK Operation Cost</div>
                  </el-col>
                  <el-col :span="5">
                    <div class="grey">Total</div>
                  </el-col>
                  <div class="cost">£9999</div>
                </div>
                <div class="inline-form totalcost-text">
                  <el-col :span="10">
                    <div class="text">UK Operation Cost</div>
                  </el-col>
                  <el-col :span="5">
                    <div class="grey">per Unit</div>
                  </el-col>
                  <div class="cost">£9999</div>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item name="3" class="cost total">
              <template slot="title">
                <div class="title">Total Cost<span class="yellow-dot">.</span></div>
              </template>
              <div class="cost-container">
                <div class="title">PRICE</div>
                <div class="inline-form static-text">
                  <div class="">Total Cost of the Project</div>
                  <div class="">0</div>
                </div>
                <div class="inline-form static-text">
                  <div class="">The Profitbility of the Project</div>
                  <div class="">0</div>
                </div>
                <div class="inline-form static-text">
                  <div class="">Total Sales</div>
                  <div class="">0</div>
                </div>
                <div class="inline-form totalcost-text">
                  <el-col :span="10">
                    <div class="text">The Profit</div>
                  </el-col>
                  <el-col :span="5">
                    <div class="grey">Total</div>
                  </el-col>
                  <div class="cost">£9999</div>
                </div>
                <div class="inline-form totalcost-text">
                  <el-col :span="10">
                    <div class="text">The Price</div>
                  </el-col>
                  <el-col :span="5">
                    <div class="grey">per Unit</div>
                  </el-col>
                  <div class="cost">£9999</div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-col>
    </el-row>
    <br />
    <div class="line"></div>
    <el-row> </el-row>
    <div class="dialog-footer">
      <el-button class="btn" type="danger" @click="close"> <i class="icon-xmark-solid"></i> Cancel</el-button>
      <el-button class="btn" type="primary" @click="develop"> <i class="icon-floppy-disk-solid"></i> Save Draft</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["form"],
  name: "cost-calculator",

  data() {
    return {
      createQuotationDialogVisible: false,
      addProductList: [],
      value: "",
      itemSwitch: false,

      options: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
        },
        {
          value: "Option3",
          label: "Option3",
        },
        {
          value: "Option4",
          label: "Option4",
        },
        {
          value: "Option5",
          label: "Option5",
        },
      ],
    };
  },
  methods: {
    productSelect(e, index) {
      if (e != null) {
        this.form.Products[index].ID = e.ID;
        this.form.Products[index].Title = e.Title;
      } else {
        this.form.Products[index].ID = null;
        this.form.Products[index].Title = null;
      }
    },
    develop() {
      this.$message.info("Bu özellik geliştirme aşamasındadır...");
      return;
    },
    close() {
      this.$emit("close");
    },
    createNewProduct() {
      this.addProductList.push({
        productsName: null,
        quantity: null,
        Diameter: false,
        Width: 0,
        Length: 0,
        Height: 0,
        Print: 0,
        Quality: 0,
        UnitPrice: 0,
        SelectType: true,
      });
    },
    deleteNewProduct(index) {
      this.addProductList.splice(index, 1);
    },
  },
  computed: {
    getEnums() {
      return this.$store.getters.getEnums;
    },
  },
};
</script>
