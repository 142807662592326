import { client } from "../../helpers/axiosHelper";
import { Message } from "element-ui";
export default {
  async getTransferList(context, payload) {
    var res;
    if (payload) {
      res = await client.post("/Transfer/List", payload);
    } else {
      res = await client.post("/Transfer/List", context.state.filter);
    }
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setTransferList", res.data.Data.Items);
      context.state.transferTotalCount.TotalCount = res.data.Data.TotalCount;
      context.state.transferTotalCount.PageCount = res.data.Data.PageCount;
    }
  },
  async getOneTransfer(context, payload) {
    var res = await client.post("/Transfer/Get", { Id: payload });
    if (res.data.HasError) {
      Message.error(res.data.Message);
    }
    return res.data;
  },

};
