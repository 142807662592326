<template>
  <div class="dialog-container">
    <transition name="el-fade-in" mode="out-in">
      <el-row style="display: flex; align-items: center; justify-content: center; width: 100%" v-if="loading == 'addUpdateScreen'" key="screen-loading">
        <lottie-player autoplay loop mode="normal" src="/lottie/packaging-source-loading-lottie.json" style="width: 320px"></lottie-player>
      </el-row>
      <el-row v-else key="content-loading">
        <div class="dialog-title">
          <div class="head-title">
            Offer<span class="yellow-dot">.</span> <span>#{{ form.OfferNo }}</span>
          </div>
          <el-button class="btn close" type="danger" @click="close"> <i class="icon-xmark-solid"></i></el-button>
        </div>
        <div class="form">
          <el-row :gutter="24">
            <el-col :sm="12" class="input-container">
              <div class="form">
                <div class="title">Customer<span class="yellow-dot">.</span></div>
                <el-row>
                  <el-col :sm="12" v-if="form.Customer" class="input-container">
                    <div class="text">Customer Name</div>
                    <div class="name" v-if="form.Customer">{{ form.Customer.CompanyName }}</div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :sm="12" class="form">
              <div class="right-card">
                <div class="inline-form">
                  <div class="input-group">
                    <div class="left">Date:</div>
                    <div class="right">{{ $moment(form.CreatedDateTime).format("DD-MM-YYYY") }}</div>
                  </div>
                  <div class="input-group">
                    <div class="left">Customer Name:</div>
                    <div class="right" v-if="form.Customer">{{ form.Customer.CompanyName}}</div>
                  </div>
                  <div class="input-group">
                    <div class="left">Product:</div>
                    <div class="right">
                      <div class="block-item" v-for="(item, index) in form.Products" :key="index">{{ item.Title }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="line"></div>
          <div class="title">Product Information<span class="yellow-dot">.</span></div>
          <div class="product-inf-component">
            <el-collapse accordion>
              <el-collapse-item v-for="(item, index) in form.Products" :key="index">
                <template slot="title" >
                  <div class="row-title">
                    <div @click.stop>
                      <el-checkbox class="row-title" :checked="item.checked == true" @change="toggleCheckbox(item)">
                        ITEM {{ index + 1 }} <span class="name"> {{ item.Title }} </span>
                        <span class="name" v-if="item.UnitPriceQuantityAlternatives.length > 1"> There are alternative quantities and unit prices </span>
                      </el-checkbox>
                    </div>
                  </div>
                </template>
                <div class="form">
                  <el-row :gutter="24">
                    <el-col class="input-container" :sm="8">
                      <div class="text">Products Name</div>
                      <el-input disabled v-model="item.Title" placeholder="..." class="name-input"></el-input>
                    </el-col>
                    <el-col :sm="16" class="input-container">
                      <div class="text">Description</div>
                      <el-input v-model="item.Description" placeholder="..." disabled></el-input>
                    </el-col>
                  </el-row>

                  <el-row :gutter="24" v-if="!item.Diameter">
                    <el-col class="input-container" :sm="8">
                      <div class="text">Width</div>
                      <el-input class="deactive-append" disabled v-model="item.Width" placeholder="...">
                        <div slot="append">CM</div>
                      </el-input>
                    </el-col>
                    <el-col class="input-container" :sm="8">
                      <div class="text">Length</div>
                      <el-input disabled class="deactive-append" v-model="item.Length" placeholder="...">
                        <div slot="append">CM</div>
                      </el-input>
                    </el-col>
                    <el-col class="input-container" :sm="8">
                      <div class="text">Height</div>
                      <el-input disabled class="deactive-append" v-model="item.Height" placeholder="...">
                        <div slot="append">CM</div>
                      </el-input>
                    </el-col>
                  </el-row>
                  <el-row :gutter="24" v-if="item.Diameter">
                    <el-col class="input-container" :sm="8">
                      <div class="text">Other</div>
                      <el-input disabled class="input-with-select deactive-append" v-model="item.DiameterValue">
                        <el-select v-model="item.Unit" slot="append" class="append-diameter" disabled clearable>
                          <el-option v-for="(item, index) in getEnums.find((x) => x.Title == 'Unit').Enums" :key="item.Key + index" :label="item.DisplayName" :value="item.Key"></el-option>
                        </el-select>
                      </el-input>
                    </el-col>
                  </el-row>

                  <el-row :gutter="24">
                    <el-col class="input-container" :sm="8">
                      <div class="text">Print</div>
                      <el-input v-model="item.Print" placeholder="..." class="select"> </el-input>
                      <span class="error-validation">{{ $errorMessage("Products[" + index + "].Print", validation) }}</span>
                    </el-col>
                    <el-col class="input-container" :sm="8">
                      <div class="text">Alternative Print Options</div>
                      <el-select v-model="selectedPrint" @change="updateAlternativePrint(index)" placeholder="Select" class="select">
                        <el-option v-for="(alternativePrints, alternativePrintIndex) in PrintAlternatives" :key="alternativePrintIndex + '-print'" :label="alternativePrints.AlternativePrint" :value="alternativePrints.AlternativePrint"> </el-option>
                      </el-select>
                      <span class="error-validation">{{ $errorMessage("Products[" + index + "].Print", validation) }}</span>
                    </el-col>
                  </el-row>

                  <el-row :gutter="24">
                    <el-col class="input-container" :sm="8">
                      <div class="text">Quality</div>
                      <el-input v-model="item.Quality" placeholder="..." class="select"> </el-input>
                      <span class="error-validation">{{ $errorMessage("Products[" + index + "].Quality", validation) }}</span>
                    </el-col>
                  </el-row>

                  <el-row :gutter="24">
                    <el-col class="input-container" :sm="8">
                      <div class="text">Type</div>
                      <el-input v-model="item.Type" placeholder="..." class="select"> </el-input>
                      <span class="error-validation">{{ $errorMessage("Products[" + index + "].Type", validation) }}</span>
                    </el-col>
                  </el-row>

                  <div class="line"></div>
                  <el-row :gutter="24">
                    <el-col class="input-container" :sm="8">
                      <div class="text">Alternative Unit Price Options</div>
                      <el-select v-model="item.Selected" @change="updateAlternative(index)" placeholder="Select" class="select">
                        <el-option v-for="(alternatives, alternativeIndex) in item.UnitPriceQuantityAlternatives" :key="alternativeIndex + '-unit-price'" :label="'Unit Price: ' + alternatives.AlternativeUnitPrice + ' Quantity: ' + alternatives.AlternativeQuantity" :value="alternativeIndex"> </el-option>
                      </el-select> 
                      <span class="error-validation">{{ $errorMessage("Products[" + index + "].Selected", validation) }}</span>
                    </el-col>
                    <el-col class="input-container" :sm="16">
                    <div class="right-card" v-if="item.UnitPrice!=null" style="margin-top: 30px;">
                      <div class="inline-form" style="flex-direction: row;padding: 10px;">
                        <div class="input-group">
                          <div class="left">Unit Price:</div>
                          <div class="right">
                            £ {{ item.UnitPrice.toLocaleString("en",{useGrouping: true}) }}
                          </div>
                        </div>
                        <div class="input-group">
                          <div class="left">Quantity</div>
                          <div class="right">£ {{ item.Quantity.toLocaleString("en",{useGrouping: true,minimumFractionDigits: 2, maximumFractionDigits:3}) }}</div>
                        </div>
                        <div class="input-group">
                          <div class="left">Total:</div>
                          <div class="right">£ {{ (item.UnitPrice * item.Quantity).toLocaleString("en",{useGrouping: true,minimumFractionDigits: 2, maximumFractionDigits:3})  }}</div>
                        </div>
                       </div>
                      </div>
                    </el-col>
                    <!-- <el-col class="input-container" :sm="5">
                      <div class="text">Unit Price</div>
                      <el-input v-model="item.UnitPrice" placeholder="..." class="select" disabled> </el-input>
                      <span class="error-validation">{{ $errorMessage("Products[" + index + "].UnitPrice", validation) }}</span>
                    </el-col>
                    <el-col class="input-container" :sm="5">
                      <div class="text">Quantity</div>
                      <el-input v-model="item.Quantity" placeholder="..." class="select" disabled> </el-input>
                      <span class="error-validation">{{ $errorMessage("Products[" + index + "].Quantity", validation) }}</span>
                    </el-col>
                    <el-col class="input-container" :sm="5">
                      <div class="text">Total</div>
                      <el-input v-model="item.Total" placeholder="..." class="select" disabled> </el-input>
                    </el-col> -->
                  </el-row> 
                  
                </div>
              </el-collapse-item>
            </el-collapse>
            <span class="error-validation">{{ $errorMessage("Products", validation) }}</span>
          </div>
          
          <!-- <div class="line"></div> -->

          <div class="title">Win Reason<span class="yellow-dot">.</span></div>
          <el-input v-model="reason" type="textarea" :rows="3"></el-input>
          <span class="error-validation">{{ $errorMessage("Reason", validation) }}</span>
        </div>
        <div class="dialog-footer footerbutton">
          <el-button class="btn" type="danger" @click="close"> <i class="icon-xmark-solid"></i> Cancel</el-button>
          <el-button class="btn" type="primary" @click="confirmQuotation" :loading="loading == 'saveLoading'" :icon="'el-icon-check'">Confirm Quatation</el-button>
        </div>
      </el-row>
    </transition>
  </div>
</template>
<script>
export default {
  props: ["id"],
  name: "confirm-quatations",

  data() {
    return {
      checkList: [],
      allCustomers: null,
      products:null,
      reason:null,
      selected: null, 
      selectedPrint: null,
      loading: false,
      timeout: null,
      PrintAlternatives: [
        {
          AlternativePrint: " Customer Design ",
        },
        {
          AlternativePrint: " 1 Colour ",
        },
        {
          AlternativePrint: " 2 Colour ",
        },
      ],
    };
  },
  async created() {
    this.products=null;
    this.checkList = [];
    await this.checkedId();
    await Promise.all([await this.getAllCustomers(),await this.getQuotations()]);
    // await this.uncheckedProducts();
  },
  computed: {
    form() {
      return this.$store.getters.getQuotationOne;
    },
    getEnums() {
      return this.$store.getters.getEnums;
    },
    validation() {
      return this.$store.getters.getQuotationsValidationErrors;
    },
    quotationList() {
      return this.$store.getters.getQuotationsList;
    },
  },
  methods: {
    // createDescription(index) {
    //   var x;

    //   if (this.form.Products[index].Unit == 0) {
    //     x = '"';
    //   } else if (this.form.Products[index].Unit == 1) {
    //     x = "oz";
    //   } else if (this.form.Products[index].Unit == 2) {
    //     x = "Ø";
    //   } else {
    //     x = "";
    //   }
    //   if (this.form.Products[index].Diameter) {
    //     this.form.Products[index].Description = "Bespoke " + this.form.Products[index].DiameterValue + " " + x + " " + this.form.Products[index].Title;
    //   } else {
    //     this.form.Products[index].Description = "Bespoke " + this.form.Products[index].Width + "x" + this.form.Products[index].Length + "x" + this.form.Products[index].Height + "cm " + this.form.Products[index].Title;
    //   }
    // },

    changeCheckbox(id) {
      if (this.checkList.some((x) => x == id)) {
        var cIndex = this.checkList.findIndex((x) => x == id);
        this.checkList.splice(cIndex, 1);
      } else {
        this.checkList.push(id);
      }
    },
    toggleCheckbox(item) {
      console.log(item)
      if (item.checked == true){
        item.checked = false;
      }
      else {
        item.checked = true;
      }
    },
    async confirmQuotation() {
     
      // var checkedItem = [];
      var checkedItem = this.form.Products.filter(p => p.checked==true);
      //var checkedItem = this.products.filter(p => p.Checked==true);
      //console.log(checkedItem)
      // this.checkList.map((x) => {
      //   var Item = this.form.Products.find((y) => y.ID == x);
      //   checkedItem.push(Item);
      // });
      this.loading = "saveLoading";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var res = await this.$store.dispatch("confirmQuotation", { ...this.form, Products: checkedItem,Reason:this.reason });
        if (!res.HasError) {
          await this.$store.dispatch("getOneQuotation", this.id);
          this.close();
        }
        this.loading = false;
      }, 500);
    },

    async getAllCustomers() {
      var res = await this.$store.dispatch("getAllCustomersList");
      this.allCustomers = res.Items;
    },
    async getQuotations() {
      await this.$store.dispatch("getOneQuotation", this.id);
    },
    close() {
      this.$emit("close");
    },
    updatePrice(){
      this.form.Products.forEach((item) => {
        item.Total=item.UnitPrice * item.Quantity
      });
    },
    updateAlternative(index) {
      var selected=this.form.Products[index].Selected;
      console.log(selected)
      this.form.Products[index].Quantity = this.form.Products[index].UnitPriceQuantityAlternatives[selected].AlternativeQuantity;
      this.form.Products[index].UnitPrice = this.form.Products[index].UnitPriceQuantityAlternatives[selected].AlternativeUnitPrice;
      console.log(this.form.Products[index])
      this.updatePrice()
    },
    
    updateAlternativePrint(index) {
      this.form.Products[index].Print = this.selectedPrint;
      this.selectedPrint = null;
    },
    // uncheckedProducts() {
    //   this.products=this.form.Products.filter((x)=>x.Checked==false);
    //   this.updatePrice()
    // },
    async checkedId() {
      this.loading = "addUpdateScreen"; 
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        if (this.id) {
          await this.$store.dispatch("getOneQuotation", this.id);
        } else {
          this.close();
        }
        this.loading = false;
      }, 500);
    },
  },
  // watch:{

  // }
};
</script>
