<template>
  <div id="proforma">
    <portal to="filter">
      <el-header class="filter-header">
        <div class="title">Proforma List</div>
        <div class="actions-buttons">
          <el-button type="secondary" class="btn" :class="{ 'active-filter': filterActive ? true : false }" @click="toggle"><i class="icon-filter-filter"></i> Filters</el-button>
          <el-button type="info" class="btn" v-if="user.Role==10" @click="exportFile()"><i class="icon-filter-export"></i>Export</el-button>
          <!-- <el-button type="info" class="btn" @click="pdfDownload()"><i class="icon-download-2"></i>PDF</el-button> -->
          <el-button type="primary" class="btn" @click="setItem(null)">
            <i class="el-icon-loading" style="font-size: 18px; color: white" v-if="loading == 'openAddDialogLoading'"></i>
            <i class="icon-filter-add" v-else></i> Create Proforma</el-button
          >
        </div>
      </el-header>
    </portal>
    <div class="filter-container" v-if="filter">
      <div class="header">
        <div class="title">Filters</div>
        <el-button type="danger" class="btn" @click="clearFilter">
          <i class="icon-filter-close"></i>
          Clear Filter
        </el-button>
      </div>
      <div class="all-inputs">
        <el-row :gutter="36">
          <el-col :sm="8">
            <el-input placeholder="Search" prefix-icon="el-icon-search" v-model="filter.SearchTerm" clearable></el-input>
          </el-col>
        </el-row>
        <el-row :gutter="36">
          <el-col class="input-container" :sm="8">
            <div class="text">Date</div>
            <el-date-picker format="dd-MM-yyyy" prefix-icon="icon-calendarplus" v-model="dateRange" type="daterange" range-separator="To" start-placeholder="Start date" end-placeholder="End date" clearable @clear="dateRange = []"> </el-date-picker>
          </el-col>
          <el-col class="input-container" :sm="8">
            <div class="text">Customer</div>
            <el-select v-model="filter.CustomerId" placeholder="Select" clearable filterable>
              <el-option v-for="item in getAllCustomers" :key="item.value" :label="item.CompanyName" :value="item.ID"> </el-option>
            </el-select>
          </el-col>
          <el-col class="input-container" :sm="8">
            <div class="text">Status</div>
            <el-select v-model="filter.Status" placeholder="Select" clearable @clear="filter.Status = null">
              <el-option v-for="(item, index) in getEnums.find((x) => x.Title == 'ProformaStatus').Enums" :key="item.Key + index" :label="item.DisplayName" :value="item.Key"> </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row :gutter="36" v-if="user.Role==10">
          <el-col class="input-container" :sm="8">
              <div class="text">Account Manager</div>
              <el-select v-model="filter.AccountManagerId" placeholder="Select">
                <el-option v-for="(item, index) in getUserList.filter((x) => x.Role==1 || x.Role==10)" :key="index" :value="item.ID" :label="item.FirstName + ' ' + item.LastName"> </el-option>
              </el-select>
            </el-col>
        </el-row>
      </div>
    </div>
    <div class="table-container">
      <div class="view-info">
        <p>You are viewing page {{ filter.Page }} out of a total of {{ totalCount.PageCount }} pages. (Total Records: {{ totalCount.TotalCount }})</p>
        <el-button class="refresh-btn" @click="arrowsRotate"> <i class="icon-arrows-rotate-solid"></i></el-button>
      </div>
      <el-table v-if="proformaList" :data="proformaList" style="width: 100%" v-on:sort-change="sortHandler">
        <el-table-column prop="ProformaNo" label="Proforma No" width="180" sortable="custom"></el-table-column>
        <el-table-column prop="Customer.CompanyName" label="Customer">
          <template slot-scope="scope">
            <div class="customer" @click="openPotensialCustomerDialog(scope.row.Customer.ID)">{{ scope.row.Customer != null ? scope.row.Customer.CompanyName : "" }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="Date" sortable="custom">
          <template slot-scope="scope">
            <span>Created Date : {{ $moment(scope.row.CreatedDateTime).format("DD-MM-YYYY") }}</span
            ><br />
            <span>Last Update : {{ $moment(scope.row.UpdatedDateTime).format("DD-MM-YYYY") }}</span>
            <br />
            <span>It's been {{ $moment().diff($moment(scope.row.CreatedDateTime), "days") + 1 }} {{ $moment().diff($moment(scope.row.CreatedDateTime), "days") + 1 == 1 ? "day" : "days" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Total" prop="Total" sortable="custom">
          <template slot-scope="scope"> £ {{ scope.row.Total.toLocaleString("en", { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 3 }) }} </template>
        </el-table-column>
        <el-table-column label="Account Manager">
          <template slot-scope="scope"> {{ scope.row.AccountManager.FirstName }} {{ scope.row.AccountManager.LastName }} </template>
          <!-- <template slot-scope="scope" v-if="getUserList.length > 0">
            <span v-if="scope.row.AccountManagerId != null"> {{ getUserList.find((x) => x.ID == scope.row.AccountManagerId).Title }}</span>
            <span v-else> No account manager yet </span>
          </template> -->
        </el-table-column>
        <el-table-column prop="status" label="Status">
          <template slot-scope="scope">
            <div class="status" :class="getClassStatus(scope.row.Status)">
              <span v-if="getEnums">{{ getEnumsDisplay("ProformaStatus", scope.row.Status) }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Preview" width="160">
          <template slot-scope="scope">
            <el-button class="popover-eye" @click="seePrewiev(scope.row.ID,proformaList.find((x) => x.ID == scope.row.ID))" slot="reference">
              <i class="el-icon-loading" style="font-size: 18px; color: blue" v-if="loading == 'exportLoading' + scope.row.ID"></i>
              <i class="icon-eye-popover" v-else></i>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="Download Pdf" width="160">
          <template slot-scope="scope">
            <el-button class="popover-eye" @click="exportPdf(proformaList.find((x) => x.ID == scope.row.ID))" slot="reference">
              <i class="el-icon-loading" style="font-size: 18px; color: blue" v-if="loading == 'downloadLoading' + scope.row.ID"></i>
              <i class="icon-download-2" v-else></i>
            </el-button>
            <!-- <i class="icon-download-2" style="cursor: pointer" @click="exportPdf(proformaList.find((x) => x.ID == scope.row.ID))"></i> -->
          </template>
        </el-table-column>
        <el-table-column label="Actions" width="160">
          <template slot-scope="scope">
            <el-dropdown>
              <el-button type="primary"> Actions<i class="el-icon-arrow-down el-icon--right"></i></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <span class="view-edit" @click="setItem(scope.row.ID)"><i class="icon-pen-to-square-solid"></i> View & Edit</span>
                </el-dropdown-item>
                <el-dropdown-item>
                  <span class="view-edit transit" v-if="[7].includes(proformaList.find((x) => x.ID == scope.row.ID).Status)" @click="changeStatusResponseAwaiting(scope.row.ID)"><i class="icon-response"></i>Response Awaiting</span>
                </el-dropdown-item>
                <el-dropdown-item :disabled="loading == 'confirmLoading'">
                  <span class="confirm-quatation" v-if="[4,9].includes(proformaList.find((x) => x.ID == scope.row.ID).Status) && user.Role==10" @click="confirm(scope.row.ID)">
                    <i class="icon-check-double-solid"></i>Confirm Proforma</span>
                </el-dropdown-item>
                <el-dropdown-item>
                  <span class="confirm-quatation" v-if="[4].includes(proformaList.find((x) => x.ID == scope.row.ID).Status) && user.Role==1" @click="changeStatusCustomerConfirmed(scope.row.ID)"><i class="icon-check-double-solid"></i>Customer Confirmed</span>
                </el-dropdown-item>
                <el-dropdown-item>
                  <span class="view-edit void" v-if="[1, 2, 4,7].includes(proformaList.find((x) => x.ID == scope.row.ID).Status)" @click="changeStatusVoid(scope.row.ID)"><i class="icon-lost"></i>Void</span>
                </el-dropdown-item>
                <el-dropdown-item>
                  <span class="view-edit stuck" v-if="[ 2, 4,7].includes(proformaList.find((x) => x.ID == scope.row.ID).Status)" @click="changeStatusLost(scope.row.ID)"><i class="icon-lost"></i>Lost</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div class="table-bottom">
        <div class="info">
          <span>Show</span>
          <el-select v-model="filter.PageSize">
            <el-option label="12" :value="12"></el-option>
            <el-option label="24" :value="24"></el-option>
            <el-option label="36" :value="36"></el-option>
            <el-option label="100" :value="100"></el-option>
            <el-option label="200" :value="200"></el-option>
          </el-select>
          <span>entries</span>
        </div>
        <el-pagination layout="prev, pager, next" :current-page.sync="filter.Page" :page-size="filter.PageSize" :total="totalCount.TotalCount"> </el-pagination>
      </div>
    </div>
    <el-dialog custom-class="proformas-details-dialog" top="5vh" :destroy-on-close="true" :close-on-click-modal="false" :visible.sync="dialogVisible" :show-close="false">
      <proformasDetailsDialog :id="setProformaId" @close="closeAddDialog()" v-if="dialogVisible"></proformasDetailsDialog>
    </el-dialog>
    <el-dialog custom-class="add-potential-customer" top="5vh" :close-on-click-modal="false" :destroy-on-close="true" :visible.sync="potentialDialogVisible" :show-close="false">
      <addOrUpdatePotentitalCustomers :id="setId" @close="closeAddUpdatePotentitalCustomers()" v-if="potentialDialogVisible" />
    </el-dialog>
  </div>
</template>

<script>
import addOrUpdatePotentitalCustomers from "../../components/addOrUpdatePotentitalCustomers.vue";
import proformasDetailsDialog from "../../components/proformasDetailsDialog.vue";
export default {
  components: {
    proformasDetailsDialog,
    addOrUpdatePotentitalCustomers
  },

  data() {
    return {
      dialogVisible: false,
      potentialDialogVisible: false,
      setProformaId: null,
      dateRange: null,
      loading: false,
      timeout: null,
      token: this.$cookies.get("token"),
      form: {
        ID: null,
        ProductNo: null,
        Products: null,
        Diameter: true,
        Width: 0,
        Length: 0,
        Height: 0,
        Print: 0,
        Quality: 0,
        UnitPrice: 0,
        Histories: [],
        IsProduced: false,
        Title: null,
        SelectType: true,
      },
      filterActive: false,
      requestQueue: [], // İstek sırasını tutan dizi
    };
  },
  beforeMount() {
    this.getProformas();
    this.$store.dispatch("getCustomersList", { Page: 1, PageSize: 999999, SearchTerm: null });
    this.$store.dispatch("getUserList", { Page: 1, PageSize: 999999, SearchTerm: null });
  },
  mounted() {
    this.jQuery(".filter-container").hide();
  },
  methods: {
    async sortHandler(params) {
      // params objesi: {columns, prop, order}
      console.log(params);
      this.filter.SortBy = params.prop;
      this.filter.SortDirection = params.order == "descending" ? 1 : 0;
    },
    exportFile() {
      var label = "Proformas.xlsx";
      this.$client
        .get("/Proforma/ExportReports", { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    exportPdf(proforma) {
      console.log(proforma)
      if(proforma.CurrentCompanyInformation.ID==null){
        this.validation.push({
          Key: "SeePreview",
          Value: "",
        });
        this.$message.error("You should save company detail");
      }
      
      if (this.validation.length > 0) {
        return;
      }
      this.loading = "downloadLoading" + proforma.ID;
      var id=proforma.ID;
      var fileName="PSP"+proforma.ProformaNo +" - "+proforma.Customer.CompanyName
      var token = this.$cookies.get("token");
      this.$client.get("/Proforma/ExportPdfWithSejda", { responseType: "blob" , params: { id: id,token:token } }).then((response) => {
        console.log(response)
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
        this.loading = false;
      });
    },
    arrowsRotate() {
      this.getProformas();
    },
    clearFilter() {
      this.filter.SearchTerm = null;
      this.dateRange = null;
      this.filter.CustomerId = null;
      this.filter.Status = null;
      this.filter.AccountManagerId=null;
    },
    async confirm(id) {
      this.$confirm("Are you sure you want to confirm proforma?", "Warning", {
        confirmButtonText: "Yes",
        confirmButtonClass: "danger-btn-confirm",
        cancelButtonText: "No",
        type: "warning",
      }).then(async ()=>{
        var res = await this.$store.dispatch("getOneProforma", id);
        this.proforma=res
        console.log(this.proforma)
        this.loading = "confirmLoading";
        if (this.timeout != null) clearTimeout(this.timeout);
        this.timeout = setTimeout(async () => {
          var res = await this.$store.dispatch("confirmProforma", this.proforma);
          if (!res.HasError) {
            await this.$store.dispatch("getProformasList", { Page: 1, PageSize: 999999, SearchTerms: null });
          }
          this.loading = false;
        }, 500);
      })
    },
    async changeStatusResponseAwaiting(id) {
      var payload = {
        ProformaId: id,
        Status: 4,
      };

      this.$confirm("Are you sure you want to change the status to response awaiting ?", "Warning", {
        confirmButtonText: "Yes",
        confirmButtonClass: "danger-btn-confirm",
        cancelButtonText: "No",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$client.post("/Proforma/ChangeStatus", payload);
          if (res.HasError) {
            this.$message.error(res.data.Message);
          } else {
            this.$message.success(res.data.Message);
            this.$store.dispatch("getProformasList");
          }
        })
        .catch(() => {});
    },
    async changeStatusWin(id) {
      var payload = {
        ProformaId: id,
        Status: 5,
      };

      this.$confirm("Are you sure you want to change the status to win ?", "Warning", {
        confirmButtonText: "Yes",
        confirmButtonClass: "danger-btn-confirm",
        cancelButtonText: "No",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$client.post("/Proforma/ChangeStatus", payload);
          if (res.HasError) {
            this.$message.error(res.data.Message);
          } else {
            this.$message.success(res.data.Message);
            this.$store.dispatch("getProformasList");
          }
        })
        .catch(() => {});
    },
    async changeStatusVoid(id) {
      var payload = {
        ProformaId: id,
        Status: 8,
      };

      this.$prompt("Are you sure you want to change the status to void ?", "Warning", {
        dangerouslyUseHTMLString: true,
        inputType:"textarea",
        inputPattern:/[^\s\\]/,
        inputErrorMessage:"Please fill this field.",
        confirmButtonText: "Yes",
        confirmButtonClass: "danger-btn-confirm",
        cancelButtonText: "No",
        type: "warning",
      })
        .then(async (reason) => {
          payload.Reason=reason.value;
          var res = await this.$client.post("/Proforma/ChangeStatus", payload);
          if (res.HasError) {
            this.$message.error(res.data.Message);
          } else {
            this.$message.success(res.data.Message);
            this.$store.dispatch("getProformasList");
          }
        })
        .catch(() => {});
    },
    async changeStatusCustomerConfirmed(id) {
      var payload = {
        ProformaId: id,
        Status: 9,
      };

      this.$prompt("Are you sure you want to change the status to Customer Confirmed ?", "Warning", {
        dangerouslyUseHTMLString: true,
        inputType:"textarea",
        inputPattern:/[^\s\\]/,
        inputErrorMessage:"Please fill this field.",
        confirmButtonText: "Yes",
        confirmButtonClass: "danger-btn-confirm",
        cancelButtonText: "No",
        type: "warning",
      })
        .then(async (reason) => {
          payload.Reason=reason.value;
          var res = await this.$client.post("/Proforma/ChangeStatus", payload);
          if (res.HasError) {
            this.$message.error(res.data.Message);
          } else {
            this.$message.success(res.data.Message);
            this.$store.dispatch("getProformasList");
          }
        })
        .catch(() => {});
    },
    async changeStatusLost(id) {
      var payload = {
        ProformaId: id,
        Status: 6,
      };

      this.$prompt("Are you sure you want to change the status to lost ?", "Warning", {
        dangerouslyUseHTMLString: true,
        inputType:"textarea",
        inputPattern:/[^\s\\]/,
        inputErrorMessage:"Please fill this field.",
        confirmButtonText: "Yes",
        confirmButtonClass: "danger-btn-confirm",
        cancelButtonText: "No",
        type: "warning",
      })
        .then(async (reason) => {
          payload.Reason=reason.value;
          var res = await this.$client.post("/Proforma/ChangeStatus", payload);
          if (res.HasError) {
            this.$message.error(res.data.Message);
          } else {
            this.$message.success(res.data.Message);
            this.$store.dispatch("getProformasList");
          }
        })
        .catch(() => {});
    },
    seePrewiev(id,proforma) {
      if(proforma.CurrentCompanyInformation.ID==null){
        this.validation.push({
          Key: "SeePreview",
          Value: "",
        });
        this.$message.error("You should save company detail");
      }
      
      if (this.validation.length > 0) {
        return;
      }
      var token = this.$cookies.get("token");
      this.loading = "exportLoading" + id;
      // if (this.timeout != null) clearTimeout(this.timeout);
      // this.timeout = setTimeout(async () => {
      //   var token = this.$cookies.get("token")
      //   window.open(`https://pdf.imageus.dev/?url=https://portal.packagingsource.co.uk/proforma.html?id=${id}=proforma-no==idtoken-seperator=${token}`);
      //   this.loading = false;
      // }, 500);

      this.$client.get("/Proforma/ExportPdfWithSejda", { responseType: "blob", params: { id: id,token:token } }).then((response) => {
          console.log(response)
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.target="_blank";
          link.href = URL.createObjectURL(blob);
          link.click();
          URL.revokeObjectURL(link.href);
          this.loading = false;
      });
    },
    async getProformas() {
      await this.$store.dispatch("getProformasList");
    },
    toggle() {
      this.filterActive = !this.filterActive;
      this.jQuery(".filter-container").slideToggle();
    },
    setItem(id) {
      if (id != null) {
        this.loading = "openAddDialogLoading" + id;
      } else {
        this.loading = "openAddDialogLoading";
      }
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        this.$store.commit("setProformaValidation", []);
        this.setProformaId = id;
        this.dialogVisible = true;
        this.loading = false;
      }, 500);
    },

    deleteFile(Id) {
      this.$store.dispatch("deleteOneProforma", { Id });
    },
    openPotensialCustomerDialog(id) {
      if (id != null) {
        this.loading = "openPotensialCustomerDialog" + id;
      } else {
        this.loading = "openPotensialCustomerDialog";
      }
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        this.$store.commit("setPotentialCustomerValidation", []);
        this.setId = id;
        this.potentialDialogVisible = true;
        this.loading = false;
      }, 500);
    },
    closeAddUpdatePotentitalCustomers(id) {
      this.setId = id;
      this.potentialDialogVisible = false;
    },
    closeAddDialog(id) {
      this.setProformaId = id;
      this.dialogVisible = false;
    },
    getClassStatus(status) {
      var res;
      switch (status) {
        case 0:
          res = "success";
          break;
        case 1:
          res = "warning";
          break;
        case 2:
          res = "info";
          break;
        case 3:
          res = "danger";
          break;
        case 4:
          res = "info";
          break;
        case 5:
          res = "success";
          break;
        case 6:
          res = "danger";
          break;
        case 7:
          res = "info";
          break;
        case 8:
          res = "warning";
          break;
        case 9:
          res = "success";
          break;
      }
      return res;
    },
    processRequest(newValue) {
      this.requestQueue.push(newValue); // Yeni isteği sıraya ekler

      // Henüz işlem yapılmıyorsa işlemi başlatır
      if (this.requestQueue.length === 1) {
        this.executeRequests();
      }
    },
    executeRequests() {
      // İşlem yapılacak asenkron işlevinizi burada tanımlayın
      // Örneğin, bir API çağrısı yapabilirsiniz
      this.filterSearch().then(()=>{
        this.processNextRequest();
      })
    },
    processNextRequest() {
      this.requestQueue.shift(); 

      if (this.requestQueue.length > 0) {
        this.executeRequests();
      }
    },
    async filterSearch(){
      console.log(this.requestQueue)
      await this.$store.dispatch("getProformasList");
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    getEnums() {
      return this.$store.getters.getEnums;
    },
    filter() {
      return this.$store.getters.getProformasFilter;
    },
    proformaList() {
      return this.$store.getters.getProformasList;
    },
    totalCount() {
      return this.$store.getters.getProformasTotal;
    },
    getUserList() {
      return this.$store.getters.getUserList;
    },
    getAllCustomers() {
      return this.$store.getters.getCustomersList;
    },
    validation() {
      return this.$store.getters.getPotentialCustomersValidationErrors;
    },
  },
  watch: {
    dateRange: {
      deep: true,
      handler() {
        if (this.dateRange != null && this.dateRange.length > 0) {
          this.filter.StartDate = this.dateRange[0];
          this.filter.EndDate = this.dateRange[1];
        } else {
          this.filter.StartDate = null;
          this.filter.EndDate = null;
          this.dateRange = null;
        }
      },
    },
    filter: {
      deep: true,
      immediate: true, // İlk değeri alırken tetiklenmesini sağlar
      handler: function(newValue) {
        console.log("handler çalıştı")
        this.processRequest(newValue);
      }
    }
    // filter: {
    //   deep: true,
    //   handler: async function () {
    //     await this.$store.dispatch("getProformasList");
    //   },
    // },
  },
};
</script>

<style>
.aa{font-weight:bold;}
</style>
