import { client } from "../../helpers/axiosHelper";
import { Message, MessageBox } from "element-ui";
export default {
  async getCustomersList(context, payload) {
    var res;
    console.log(payload);
    if (payload) {
      res = await client.post("/Customer/List", payload);
    } else {
      res = await client.post("/Customer/List", context.state.customerFilter);
    }
    // Filter Store'da setlenip sürekli buradan gönderilicek payload almıyoruz.
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setCustomersList", res.data.Data.Items);
      context.state.customersTotal.TotalCount = res.data.Data.TotalCount;
      context.state.customersTotal.PageCount = res.data.Data.PageCount;
      context.state.customersTotal.PageCount = res.data.Data.SearchTerm;
    }
  },
  async getCustomer(context, payload) {
    var res = await client.post("/Customer/Get", { Id: payload });
    if (res.data.HasError) {
      Message.error(res.data.Message);
    }
    return res;
  },
  async getAllCustomersList(context, payload) {
    var res;
    if (payload) {
      res = await client.post("/Customer/AllCustomers", payload);
    } else {
      res = await client.post("/Customer/AllCustomers", { Page: 1, PageSize: 9999999 });
    }
    return res.data.Data;
  },
  async addUpdateCustomers(context, payload) {
    var res = await client.post("/Customer/AddUpdate", payload);
    context.commit("setCustomerValidation", res.data.ValidationErrors);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      Message.success(res.data.Message);
      context.dispatch("getCustomersList");
    }
    return res.data;
  },
  async deleteOneCustomer(context, payload) {
    MessageBox.confirm("Are you sure for delete this?" , {
      confirmButtonText: "Delete",
      confirmButtonClass: "danger-btn-confirm",
      cancelButtonText: "Back",
      type: "warning",
      title: "Warning"
    })
      .then(async () => {
        var res = await client.post("/Customer/Delete", payload);
        if (res.data.HasError) {
          Message.error(res.data.Message);
        } else {
          Message.success(res.data.Message);
          await context.dispatch("getCustomersList");
        }
      })
      .catch(() => {});
  },
};
