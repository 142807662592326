export default {
    setPotentialCustomersList(state, data) {
        state.potentialCustomerList = data;
    },
    setPotentialCustomerOne(state, data) {
        state.potentialCustomer = data;
    },
    setPotentialCustomerAccountNumber(state, data) {
        state.accountNumber = data;
    },
    setPotentialCustomerValidation(state, data) {
        state.potentialCustomerValidationErrors = data;
    }
}