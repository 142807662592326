import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
    proformasList: [],
    proforma: {},
    proformaForm :{
        AccountManager: null,
        AccountManagerId: null,
        BeforeProductionBalans: ["",""],
        Customer: {
            FirstName:null,
            LastName:null,
        },
        CustomerId: null,
        ID: null,
        IsSent: false,
        Products: [],
        ProformaNo: null,
        Quotation: {},
        QuotationId: null,
        Status: 0,
        Terms: [
            {Text:"Customer acknowledges that the final production quantities may differ up to +15% -15% from the original order quantity and this will be reflected in the total amount of the commercial invoice.",IsDeliveryTime:false},
            {Text:"Production and first delivery process for this order may take up to 8 weeks.",IsDeliveryTime:true},
            {Text:"The above unit prices include 6 months free storage and deliveries to above customer address.",IsDeliveryTime:false},
            {Text:"Goods will be stored in Packaging Source warehouses and will be delivered to the above customer address.",IsDeliveryTime:false},
            {Text:"By accepting the proforma invoice, the customer is required by law to make the payment as stated in the instructions section above.",IsDeliveryTime:false},
        ],
        LastPaymentText: "",
        Note:null,
        Title: null,
        CurrentCompanyInformation:{
            ID:null,
            CompanyRegistrationNo:null,
            RegisteredAddress:null,
            RegisteredCompanyName:null
        },
        Discount:null,
        DeliveryTime: null
        // Originations:[]
    },
    proformaFilter: {
        SearchTerm: null,
        Page: 1,
        PageSize: 200,
        StartDate: null,
        EndDate: null,
        CustomerId: null,
        Status: null,
        SortBy: null,
        SortDirection: 1,
        AccountManagerId: null,
    },
    proformasTotal: {
        PageCount: null,
        TotalCount: null,
    },
    proformaValidation: [],
};

export default {
    state,
    getters,
    actions,
    mutations,
};