<template>
  <div v-if="this.user.Role==1" id="reports-stats">
    <portal to="filter">
      <el-header class="filter-header">
        <div class="title">Reports & Stats</div>
      </el-header>
    </portal>
    <div class="text">Lead of Customer</div>
    <el-row :gutter="24" >
      <el-col :md="6" :sm="12">
        <el-card>
          <div class="left">
            <div class="icon-back">
              <i class="icon-reportstat-people"></i>
            </div>
          </div>
          <div class="right">
            <div class="number">{{ getReportList.LeadCount.CountToday }}</div>
            <div class="day">Today</div>
          </div>
        </el-card>
      </el-col>
      <el-col :md="6" :sm="12" >
        <el-card>
          <div class="left">
            <div class="icon-back">
              <i class="icon-reportstat-people"></i>
            </div>
          </div>
          <div class="right">
            <div class="number">{{ getReportList.LeadCount.CountLast7 }}</div>
            <div class="day">LAST 7 DAYS</div>
          </div>
        </el-card>
      </el-col>
      <el-col :md="6" :sm="12">
        <el-card>
          <div class="left">
            <div class="icon-back">
              <i class="icon-reportstat-people"></i>
            </div>
          </div>
          <div class="right">
            <div class="number">{{ getReportList.LeadCount.CountLast30 }}</div>
            <div class="day">LAST 30 DAYS</div>
          </div>
        </el-card>
      </el-col>
      <el-col :md="6" :sm="12">
        <el-card>
          <div class="left total">
            <div class="icon-back">
              <i class="icon-reportstat-people"></i>
            </div>
          </div>
          <div class="right">
            <div class="number">{{ getReportList.LeadCount.CountTotal}}</div>
            <div class="day">TOTAL</div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <div class="text">Quantation of Customer</div>
    <el-row :gutter="24">
      <el-col :md="6" :sm="12">
        <el-card>
          <div class="left">
            <div class="icon-back">
              <i class="icon-reportstat-briefcase"></i>
            </div>
          </div>
          <div class="right">
            <div class="number">{{getReportList.QuantationCount.CountToday}}</div>
            <div class="day">TODAY</div>
          </div>
        </el-card>
      </el-col>
      <el-col :md="6" :sm="12">
        <el-card>
          <div class="left">
            <div class="icon-back">
              <i class="icon-reportstat-briefcase"></i>
            </div>
          </div>
          <div class="right">
            <div class="number">{{getReportList.QuantationCount.CountLast7}}</div>
            <div class="day">LAST 7 DAYS</div>
          </div>
        </el-card>
      </el-col>
      <el-col :md="6" :sm="12">
        <el-card>
          <div class="left">
            <div class="icon-back">
              <i class="icon-reportstat-briefcase"></i>
            </div>
          </div>
          <div class="right">
            <div class="number">{{getReportList.QuantationCount.CountLast30}}</div>
            <div class="day">LAST 30 DAYS</div>
          </div>
        </el-card>
      </el-col>
      <el-col :md="6" :sm="12">
        <el-card>
          <div class="left total">
            <div class="icon-back">
              <i class="icon-reportstat-briefcase"></i>
            </div>
          </div>
          <div class="right">
            <div class="number">{{ getReportList.QuantationCount.CountTotal}}</div>
            <div class="day">TOTAL</div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <div class="text">Proforma of Customer</div>
    <el-row :gutter="24">
      <el-col :md="6" :sm="12">
        <el-card>
          <div class="left">
            <div class="icon-back">
              <i class="icon-reportstat-briefcase"></i>
            </div>
          </div>
          <div class="right">
            <div class="number">{{getReportList.ProformaCount.CountToday}}</div>
            <div class="day">TODAY</div>
          </div>
        </el-card>
      </el-col>
      <el-col :md="6" :sm="12">
        <el-card>
          <div class="left">
            <div class="icon-back">
              <i class="icon-reportstat-briefcase"></i>
            </div>
          </div>
          <div class="right">
            <div class="number">{{getReportList.ProformaCount.CountLast7}}</div>
            <div class="day">LAST 7 DAYS</div>
          </div>
        </el-card>
      </el-col>
      <el-col :md="6" :sm="12">
        <el-card>
          <div class="left">
            <div class="icon-back">
              <i class="icon-reportstat-briefcase"></i>
            </div>
          </div>
          <div class="right">
            <div class="number">{{getReportList.ProformaCount.CountLast30}}</div>
            <div class="day">LAST 30 DAYS</div>
          </div>
        </el-card>
      </el-col>
      <el-col :md="6" :sm="12">
        <el-card>
          <div class="left total">
            <div class="icon-back">
              <i class="icon-reportstat-briefcase"></i>
            </div>
          </div>
          <div class="right">
            <div class="number">{{getReportList.ProformaCount.CountTotal}}</div>
            <div class="day">TOTAL</div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :md="6" :sm="12">
        <el-card>
          <div class="left total">
            <div class="icon-back">
              <i class="icon-reportstat-briefcase"></i>
            </div>
          </div>
          <div class="right">
            <div class="number">{{getReportList.ProformaCount.ProformaWonCount}}</div>
            <div class="day">Proforma Of Won</div>
          </div>
        </el-card>
      </el-col>
      <el-col :md="6" :sm="12">
        <el-card>
          <div class="left total">
            <div class="icon-back">
              <i class="icon-reportstat-briefcase"></i>
            </div>
          </div>
          <div class="right">
            <div class="number">{{getReportList.ProformaCount.ProformaLostCount}}</div>
            <div class="day">Proforma Of Lost</div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
    name: "ReportsStats",
  data() {
    return {
    };
  },
  async beforeMount() {
    await Promise.all([this.$store.dispatch("getReportList")]);
  },
  computed: {
    getReportList() {
      return this.$store.getters.getReportList;
    },
    user() {
      return this.$store.getters.getUser;
    }
  },
  methods: {
  }
};
</script>

<style></style>
