<template>
  <el-container>
    <el-aside :width="isCollapse ? '65px' : '241px'">
      <div class="logo-container">
        <div class="divider" v-if="isCollapse == false"></div>
        <img src="/static/img/logo.svg" width="100%" v-if="isCollapse == false" />
        <img src="/static/img/logo2.svg" class="mobile" v-else />
      </div>
      <el-menu :collapse="isCollapse" :router="true" :default-active="activeMenu">
        <el-menu-item index="/reports-stats">
          <div class="left">
            <i class="icon-chartpie"></i>
            <span>Reports & Stats</span>
          </div>
        </el-menu-item>

        <el-menu-item index="/search" v-if="user.Role == 10 || user.Role == 1" >
          <div class="left">
            <i class="icon-search"></i>
            <span>Search</span>
          </div>
        </el-menu-item>
        <!-- <el-submenu index="/potential-customers" v-if="user.Role == 10 || user.Role == 1 ">
          <template slot="title">
            <i class="icon-usertag"></i>
            <span>Potential Customers</span>
          </template>
          <el-menu-item index="/potential-customers/list" v-if="user.Role == 10 || user.Role == 1 ">
            <div class="left"><i class="icon-list"></i> <span> P. Customer List</span></div></el-menu-item
          >
          <el-menu-item index="/potential-customers/quatations" v-if="user.Role == 10 || user.Role == 1 "
            ><div class="left"><i class="icon-tags"></i><span> Quotations</span></div></el-menu-item
          >
          <el-menu-item index="/potential-customers/proformas" v-if="user.Role == 10 || user.Role == 1 "
            ><div class="left"><i class="icon-receipt"></i><span> Proformas</span></div></el-menu-item
          >
        </el-submenu> -->
        <el-submenu index="/potential-customers" v-if="user.Role == 10 || user.Role == 1 ">
          <template slot="title">
            <i class="icon-usertag"></i>
            <span>Sales</span>
          </template>
          <el-menu-item index="/potential-customers/list" v-if="user.Role == 10 || user.Role == 1 ">
            <div class="left"><i class="icon-list"></i> <span> P. Customer List</span></div></el-menu-item
          >
          <el-menu-item index="/potential-customers/quatations" v-if="user.Role == 10 || user.Role == 1 "
            ><div class="left"><i class="icon-tags"></i><span>Sale Quotations</span></div></el-menu-item
          >
          <el-menu-item index="/potential-customers/proformas" v-if="user.Role == 10 || user.Role == 1 "
            ><div class="left"><i class="icon-receipt"></i><span>Sale Proformas</span></div></el-menu-item
          >
          <el-menu-item index="" v-if="user.Role == 10"
            ><div class="left"><i class="potential-project"></i><span>P. Project List</span></div></el-menu-item
          >
        </el-submenu>

        <el-submenu index="" v-if="user.Role == 10">
          <template slot="title">
            <i class="icon-usertag"></i>
            <span>Purchase</span>
          </template>
          <el-menu-item index="" v-if="user.Role == 10 || user.Role == 1 ">
            <div class="left"><i class="icon-list"></i> <span>Purchase Quatations</span></div></el-menu-item
          >
          <el-menu-item index="" v-if="user.Role == 10 || user.Role == 1 "
            ><div class="left"><i class="icon-tags"></i><span>Purchase Proformas</span></div></el-menu-item
          >
        </el-submenu>

        <el-menu-item index="/customers" v-if="user.Role == 10 || user.Role == 1 ">
          <div class="left">
            <i class="icon-peoplegroup"></i>
            <span>Customers</span>
          </div>
        </el-menu-item>
        <el-submenu index="/Projects" v-if="user.Role == 10  || user.Role == 2 || user.Role == 3 || user.Role == 5 || user.Role == 6">
          <template slot="title">
            <i class="icon-briefcase"></i>
            <span>Projects</span>
          </template>
          <el-menu-item index="/projects/ongoing" v-if="user.Role == 10 || user.Role == 2 || user.Role == 3 || user.Role == 5 || user.Role == 6">
            <div class="left">
              <i class="project-list"></i>
              <span>Ongoing Projects</span>
            </div>
          </el-menu-item>
          <el-menu-item index="/projects/completed" v-if="user.Role == 10 || user.Role == 2 || user.Role == 3 || user.Role == 5 || user.Role == 6">
            <div class="left">
              <i class="completed-project"></i>
              <span>Completed Projects</span>
            </div>
          </el-menu-item>
        </el-submenu>
        <!-- <el-menu-item index="/projects" v-if="user.Role == 10 || user.Role == 2 || user.Role == 3 || user.Role == 5">
          <div class="left">
            <i class="icon-briefcase"></i>
            <span>Projects</span>
          </div>
        </el-menu-item> -->
        <el-submenu index="/operations" v-if="user.Role == 10 || user.Role == 4 || user.Role == 3 ">
          <template slot="title">
            <i class="icon-operations-solid"></i>
            <span>Operations</span>
          </template>
          <el-menu-item index="/transportation" v-if="user.Role == 10 || user.Role == 4 || user.Role == 3 || user.Role == 5">
            <div class="left">
              <i class="icon-truck-arrow-solid"></i>
              <span>Transportations</span>
            </div>
          </el-menu-item>

          <el-menu-item index="/agentlist" v-if="user.Role == 10 || user.Role == 4 || user.Role == 3 ">
            <div class="left">
              <i class="icon-truck-plane"></i>
              <span>Agent List</span>
            </div>
          </el-menu-item>
          <el-menu-item index="/suppliers" v-if="user.Role == 10 || user.Role == 3 ">
            <div class="left">
              <i class="icon-peoplearrows"></i>
              <span>Suppliers</span>
            </div>
          </el-menu-item>
        </el-submenu>

        <el-submenu index="/warehouse" v-if="user.Role == 10 || user.Role == 4 || user.Role == 3 ">
          <template slot="title">
            <i class="icon-warehouse"></i>
            <span>Warehouse</span>
            </template>
          <el-menu-item index="/warehouse/products" v-if="user.Role == 10 || user.Role == 4 || user.Role == 3 ">
            <div class="left">
              <i class="icon-boxes"></i>
              <span>Products</span>
            </div></el-menu-item
          >
          <el-menu-item index="/orders" v-if="user.Role == 10 || user.Role == 4 || user.Role == 3 ">
            <div class="left">
              <i class="icon-cartflatbed"></i>
              <span>Orders</span>
            </div>
          </el-menu-item>
          <el-menu-item index="/warehouse/list" v-if="user.Role == 10 || user.Role == 4 || user.Role == 3 ">
            <div class="left">
              <i class="icon-warehouse"></i>
              <span>Warehouse List</span>
            </div>
            </el-menu-item>
        </el-submenu>

        <el-menu-item>
          <div class="other" style="color: #909399">
            <span>Other</span>
          </div>
        </el-menu-item>

        <el-menu-item index="/users" v-if="user.Role == 10">
          <div class="left">
            <i class="icon-usersgear"></i>
            <span>Users</span>
          </div>
        </el-menu-item>

        <el-submenu index="/settings">
          <template slot="title">
            <i class="icon-gearsolid"></i>
            <span>Settings</span>
          </template>
          <el-menu-item index="/my-account">
            <div class="left">
              <i class="icon-usergear"></i>
              <span>My Account</span>
            </div>
          </el-menu-item>
          <el-menu-item index="/change-password">
            <div class="left">
              <i class="icon-key"></i>
              <span>Change Password</span>
            </div></el-menu-item
          >
        </el-submenu>
      </el-menu>
    </el-aside>
    <el-container>
      <el-header>
        <div class="left">
          <i class="icon-menu" @click="collapseMenu()"></i>
        </div>
        <div class="right">
          <el-popover placement="bottom" width="368" trigger="click" popper-class="notif-pop">
            <el-button slot="reference">
              <i class="icon-bell"></i>
              <i class="icon-bell-reddot" v-if="notifyList.filter((x) => x.IsRead == false).length > 0"></i>
            </el-button>
            <div class="title">
              <div class="notif-group">
                <div>Notifications</div>
                <span class="notifications"> {{ notifyList.length }} </span>
              </div>
              <el-dropdown>
                <el-button style="background-color: transparent; border: none; padding-right: 0"><i class="icon-three-dot"></i></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <span class="noti-green" @click="markAllAsReadNotification">
                      <i class="icon-eye-popover"></i>
                      Mark all as a read</span
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <span class="noti-grey" @click="removeAllNotification">
                      <i class="icon-trash-can-solid"></i>
                      Delete all</span
                    >
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="container-notify">
              <ul>
                <li :class="item.IsRead ? '' : 'unread'" v-for="(item, index) in notifyList" :key="index + 'notification'">
                  <div class="divider"></div>
                  <div class="left">
                    <div class="logo" v-if="item.User && item.User">
                      {{ item.User.FirstName[0] + item.User.LastName[0] }}
                    </div>
                    <div class="logo" v-else></div>
                  </div>
                  <div class="right">
                    <div class="title">{{ item.Details }}</div>
                    <div class="right-bottom">
                      <span> {{ $moment(item.CreatedDateTime).startOf("hour").fromNow() }} </span>
                      <div class="buttons">
                        <el-button class="delete" @click="removeNotification(item.ID)"><i class="icon-trash-can-solid" style="background-color: #fff"></i></el-button>
                        <el-button v-if="item.IsRead != true" class="mark-as-read" @click="markAsReadNotification(item.ID)"><i class="icon-check-double-solid"></i></el-button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </el-popover>
          <div class="job-title" v-if="user && getEnums">{{ getEnumsDisplay("TypeOfAuthority", user.Role) }}</div>
          <div class="user-info">
            <el-popover placement="bottom" width="172" trigger="click">
              <el-button slot="reference">
                <div class="circle">{{ this.user.FirstName[0] + this.user.LastName[0] }}</div>
                <div class="user-name">{{ this.user.FullName }}</div>
                <i class="icon-arrow"></i>
              </el-button>
              <div class="logout" @click="logout">
                <div class="left">
                  <i class="icon-rightbracket"></i>
                  <span>Log Out</span>
                </div>
              </div>
            </el-popover>
          </div>
        </div>
      </el-header>
      <portal-target name="filter"></portal-target>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "Layout",
  data() {
    return {
      isCollapse: false,
      activeMenu: this.$route.path,
    };
  },
  computed: {
    getEnums() {
      return this.$store.getters.getEnums;
    },
    user() {
      return this.$store.getters.getUser;
    },
    notifyList() {
      return this.$store.getters.getNotification;
    },
    notifyFilter() {
      return this.$store.getters.getNotifyFilter;
    },
  },
  async beforeMount() {
    // await this.notifySet();
    var userId = await this.$OneSignal.getUserId();
    if (userId != null && userId != undefined) {
      await this.$store.dispatch("updatePlayerId", userId);
    }
  },
  methods: {
    collapseMenu() {
      this.isCollapse = !this.isCollapse;
    },
    logout() {
      this.$store.commit("setUser", null);
      //this.$store.commit("setToken", null);
      //localStorage.clear();
      this.$cookies.remove("token")
      this.$router.push("/");
    },
    async notifySet() {
      await this.$store.dispatch("getNotification", { Page: 1, PageSize: 999999, SearchTerm: null });
    },

    async removeNotification(id) {
      this.$store.dispatch("removeNotification", id);
    },
    async removeAllNotification() {
      this.$store.dispatch("removeAllNotification");
    },
    async markAsReadNotification(id) {
      await this.$store.dispatch("markAsReadNotification", id);
    },
    async markAllAsReadNotification() {
      this.$store.dispatch("markAllAsReadNotification");
    },
  },

  watch: {
    "$route.params": function () {
      this.activeMenu = this.$route.path;
    },
  },
};
</script>

<style></style>
