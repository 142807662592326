export default {
    setTransferList(state, data) {
        state.transferList = data;
    },
    setOneTransfer(state, data) {
        state.transferOne = data;
    },
    setTransferValidation(state, data) {
        state.transferValidation = data;
    }
}