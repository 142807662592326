import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  filter: {
    Role: null,
    Page: 1,
    PageSize: 200,
    SearchTerm: null,
    // StartDate: null,
    // EndDate: null,
    Customer: null,
    Supplier: null,
    Status: null,
    Date: null,
    WarehouseId: null,
    AgentId: null,
  },
  transferList: [],
  transferValidation: [],
  transferTotalCount: {
    PageCount: null,
    TotalCount: null,
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
