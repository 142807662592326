import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  filter: {
    Role: null,
    Page: 1,
    PageSize: 200,
    SearchTerm: null,
    GetDeleted: null,
    SortBy: null,
    SortDirection: 1,
  },
  userList: [],
  userOne: [],
  userValidation: [],
  userTotalCount: {
    PageCount: null,
    TotalCount: null,
  },
  form: {
    Email: null,
    Password: null,
    JobTitle: null,
    FirstName: null,
    PhoneNumber: null,
    LastName: null,
    Role: null,
    WarehouseId: null,
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
