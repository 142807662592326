import { client } from "../../helpers/axiosHelper";
import { Message, MessageBox } from "element-ui";
export default {
  async getProformasList(context, payload) {
    var res;
    if (payload) {
      res = await client.post("/Proforma/List", payload);
    } else {
      res = await client.post("/Proforma/List", context.state.proformaFilter);
    }
    // Filter Store'da setlenip sürekli buradan gönderilicek payload almıyoruz.
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      console.log(res.data)
      context.commit("setProformasList", res.data.Data.Items);
      context.state.proformasTotal.TotalCount = res.data.Data.TotalCount;
      context.state.proformasTotal.PageCount = res.data.Data.PageCount;
    }
  },
  async getOneProforma(context, payload) {
    var res = await client.post("/Proforma/Get", { Id: payload });
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setProformatOne", res.data.Data);
      return res.data.Data;
    }
  },
  async deleteOneProforma(context, payload) {
    MessageBox.confirm("Are you sure for delete this?", "Warning", {
      confirmButtonText: "Delete",
      confirmButtonClass: "danger-btn-confirm",
      cancelButtonText: "Back",
      type: "warning",
    })
      .then(async () => {
        var res = await client.post("/Proforma/Delete", payload);
        if (res.data.HasError) {
          Message.error(res.data.Message);
        } else {
          Message.success(res.data.Message);
          await context.dispatch("getProformasList");
        }
      })
      .catch(() => {});
  },
  async confirmProforma(context, payload) {
    context.commit("setProformaValidation", []);
    var res = await client.post("/Proforma/Confirm", payload);
    context.commit("setProformaValidation", res.data.ValidationErrors);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      Message.success(res.data.Message);
    }

    return res.data;
  },
  async addProformas(context, payload) {
    var res = await client.post("/Proforma/Add", payload);
    context.commit("setProformaValidation", res.data.ValidationErrors);

    // if (!res.data.HasError) {
    //   context.dispatch("getProformasList");
    // }
    return res.data;
  },
  async reviseProformas(context, payload) {
    var res = await client.post("/Proforma/Revise", payload);
    context.commit("setProformaValidation", res.data.ValidationErrors);

    // if (!res.data.HasError) {
    //   context.dispatch("getProformasList");
    // }
    return res.data;
  },
  async saveProformas(context, payload) {
    var res = await client.post("/Proforma/Save", payload);
    context.commit("setProformaValidation", res.data.ValidationErrors);

    // if (!res.data.HasError) {
    //   context.dispatch("getProformasList");
    // }
    return res.data;
  },
  async readyProformas(context, payload) {
    var res = await client.post("/Proforma/Ready", payload);
    context.commit("setProformaValidation", res.data.ValidationErrors);

    // if (!res.data.HasError) {
    //   context.dispatch("getProformasList");
    // }
    return res.data;
  },
};
