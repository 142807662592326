export default {
    setQuotationsList(state, data) {
        state.quotationsList = data;
    },
    setQuotationtOne(state, data) {
        state.quotation = data;
    },
    setQuotationValidation(state, data) {
        state.quotationValidation = data;
    }
}