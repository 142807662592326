import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  // loginForm: {
  //   Email: "admin@techsin.com.tr",
  //   Password: "Techsin2023!",
  // },
  loginForm: {
    Email: null,
    Password:null,
  },
  validationErrors: [],
  token: null,
  user: null,
  enums: [],
  industries: [],
  agents: [],
  interestProducts: [],
  allCountries: [],
  // countries: [],
  // cities: [],
  notification: [],
  notifyCount: 0,
  notifyFilter: {
    PageSize: 9999999,
    Page: 1,
    SearchTerm: null,
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
