export default {
    setProductSamplesList(state, data) {
        state.productSamplesList = data;
    },
    setProductSampleOne(state, data) {
        state.productSample = data;
    },
    setProductSampleValidation(state, data) {
        state.productSampleValidation = data;
    }
}