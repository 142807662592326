import Vue from "vue";
import router from "../router"
import axios from "axios";
import cookie from 'vue-cookies'
const client = axios.create()

//let token = localStorage.getItem("token");
let token = cookie.get("token");

// burası normal site live server
client.defaults.baseURL = "https://api.packagingsource.co.uk/";

// burası TEST server
//client.defaults.baseURL = "http://139.59.176.11:25009/";

// mikdat
// client.defaults.baseURL = "http://192.168.1.217:25008/";

//yalım bey
//client.defaults.baseURL = "http://192.168.1.162:25008/";

//bilge
// client.defaults.baseURL = "http://192.168.1.127:25008/";

//Gökçe
// client.defaults.baseURL = "http://192.168.1.199:25008/";

//Soner
//client.defaults.baseURL = "http://localhost:25008/";

if (token) {
    client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

client.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        cookie.remove("token")
        router.push("/login");
    }
    console.log(error)
    return error;
});


Vue.prototype.$client = client
export { client }