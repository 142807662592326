import { client } from "../../helpers/axiosHelper";
import { Message } from "element-ui";
export default {
  async getProductSamplesList(context, payload) {
    var res;
    if (payload) {
      res = await client.post("/ProductSample/List", payload);
    } else {
      res = await client.post("/ProductSample/List", context.state.filter);
    }
    // Filter Store'da setlenip sürekli buradan gönderilicek payload almıyoruz.
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      if (res.data) {
        context.commit("setProductSamplesList", res.data.Data.Items);
      
      context.state.productSamplesTotal.TotalCount = res.data.Data.TotalCount;
      context.state.productSamplesTotal.PageCount = res.data.Data.PageCount;
    }}
  },
  async addUpdateProductSamples(context, payload) {
    var res = await client.post("/ProductSample/AddUpdateProductSample", payload);
    context.commit("setProductSampleValidation", res.data.ValidationErrors);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      Message.success(res.data.Message);
      context.dispatch("getProductSamplesList");
    }
  },
  async deleteOneProductSample(context, payload) {
    // silinecek customer idsi gönderilicek string olarak
    var res = client.post("/ProductSample/Delete", { Id: payload });
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      Message.success(res.data.Message);
      // TODO - Silinecek Account Listeden Kaldırılıcak mutations ile yapılacak. dispatch atılmayacak tekrar.
      context.dispatch("getCustomersList");
    }
  },
  
};

