import { client } from "../../helpers/axiosHelper";
import { Message, MessageBox } from "element-ui";
export default {
  async getWarehouseList(context, payload) {
    var res;
    if (payload) {
      res = await client.post("/Warehouse/List", payload);
    } else {
      res = await client.post("/Warehouse/List", context.state.filter);
    }
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setWarehouseList", res.data.Data.Items);
      context.state.warehouseTotalCount.TotalCount = res.data.Data.TotalCount;
      context.state.warehouseTotalCount.PageCount = res.data.Data.PageCount;
    }
  },

  async getStockList(context, payload) {
    var res;
    if (payload) {
      res = await client.post("/Warehouse/GetStocks", payload);
    } else {
      res = await client.post("/Warehouse/GetStocks", context.state.stockFilter);
    }
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setStockList", res.data.Data.Items);
      context.state.stockTotalCount.TotalCount = res.data.Data.TotalCount;
      context.state.stockTotalCount.PageCount = res.data.Data.PageCount;
    }
  },
  async getOneWarehouse(context, payload) {
    var res = await client.post("/Warehouse/Get", { Id: payload });
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      context.commit("setWarehouseOne", res.data.Data);
      return res.data.Data;
    }
  },
  async AddUpdateWarehouse(context, payload) {
    var res = await client.post("/Warehouse/AddUpdate", payload);
    context.commit("setWarehouseValidation", res.data.ValidationErrors);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      Message.success(res.data.Message);
      await context.dispatch("getWarehouseList");
    }
    return res.data;
  },
  async generateWarehouseNumber() {
    var res = await client.post("/Warehouse/GenerateWarehouseNumber");

    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      return res.data.Data;
    }
  },
  async deleteWarehouse(context, payload) {
    MessageBox.confirm("Are you sure for delete this?", "Warning", {
      confirmButtonText: "Delete",
      confirmButtonClass: "danger-btn-confirm",
      cancelButtonText: "Back",
      type: "warning",
    })
      .then(async () => {
        var res = await client.post("/Warehouse/Delete", payload);
        if (res.data.HasError) {
          Message.error(res.data.Message);
        } else {
          Message.success(res.data.Message);
          await context.dispatch("getWarehouseList");
        }
      })
      .catch(() => {});
  },
  async AddUpdateWarehouseItem(context, payload) {
    var res = await client.post("/Warehouse/AddUpdateWarehouseItem", payload);
    context.commit("setWarehouseValidation", res.data.ValidationErrors);
    if (res.data.HasError) {
      Message.error(res.data.Message);
    } else {
      Message.success(res.data.Message);
      await context.dispatch("getProductsList");
    }
    return res.data;
  },
};
